<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-ProductInfoDetail" data-bs-toggle="tab" href="#ProductInfoDetail"
                role="tab" aria-controls="ProductInfoDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Tự công bố sản phẩm</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="ProductInfoDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <a *ngIf="DisableForm === true" style="width: 100%;" title="Lưu thay đổi" class="btn btn-secondary"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                    <a *ngIf="DisableForm !== true" style="width: 100%;" title="Lưu thay đổi" (click)="ProductInfoSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <h4>Bước 01: Nhập Thông tin</h4>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Mã hồ sơ</label>
                                <input name="Code" [(ngModel)]="ProductInfoService.FormData.Code" placeholder="Mã hồ sơ" [disabled]="DisableForm"
                                    type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12" *ngIf="ProductInfoService.FormData.ID>0">
                                <label class="form-label">Đơn vị Tổ chức</label>
                              
                                <input name="CompanyInfoName"
                                    [(ngModel)]="ProductInfoService.FormData.CompanyInfoName" disabled
                                    placeholder="Đơn vị Tổ chức" type="text" class="form-control" />
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Sản phẩm</label>
                                <input name="Name" [(ngModel)]="ProductInfoService.FormData.Name" placeholder="Sản phẩm"
                                [disabled]="DisableForm" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Số CBCL</label>
                                <input name="Display" [(ngModel)]="ProductInfoService.FormData.Display"
                                [disabled]="DisableForm" placeholder="Số CBCL" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ghi chú</label>
                                <input name="Note" [(ngModel)]="ProductInfoService.FormData.Note" placeholder="Ghi chú"
                                [disabled]="DisableForm" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ngày công bố</label>
                                <input [ngModel]="ProductInfoService.FormData.NgayGhiNhan | date:'yyyy-MM-dd'"
                                [disabled]="DisableForm" (ngModelChange)="DateNgayGhiNhan($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                                    type="date" class="form-control" autocomplete="off">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Tải lên Tập tin công bố</label>
                                <br />
                                <div style="display: flex;" class="col-lg-4 col-sm-12 col-12">
                                    <a *ngIf="DisableForm === true" class="btn btn-secondary me-1" title="Đẩy lên tập tin đã ký">
                                        <i class="bi bi-cloud-upload"></i>
                                    </a>
                                    <a *ngIf="DisableForm !== true" class="btn btn-success me-1" title="Đẩy lên tập tin đã ký" (click)="openFileInput()">
                                        <i class="bi bi-cloud-upload"></i>
                                    </a>
                                    
                                    <input #fileInput type="file" (change)="ChangeFileName($event.target.files)" style="display: none">
                                    <div *ngIf="publicDoc?.FileName === null">
                                        <a class="btn btn-secondary me-1" target="_blank" title="Tải về tập tin đã ký">
                                            <i class="bi bi-cloud-download"></i>
                                        </a>
                                        <a class="btn btn-secondary me-1" target="_blank" title="Xem tập tin đã ký">
                                            <i class="bi bi-eye"></i>
                                        </a>
                                    </div>
                                    
                                    <div  *ngIf="publicDoc?.FileName !== null">
                                        <a class="btn btn-success me-1" 
                                            title="Tải về tập tin đã ký"
                                            target="_blank"
                                            [href]="publicDoc.FileName">
                                            <i class="bi bi-cloud-download"></i>
                                        </a>
                
                                        <a *ngIf="isPDFFile(publicDoc.FileName) || isWordOrExcelFile(publicDoc.FileName) || isHtmlFile(publicDoc.FileName)"
                                            class="btn btn-success me-1"
                                            title="Xem tập tin đã ký"
                                            target="_blank"
                                            [href]="getViewerUrl(publicDoc.FileName)">
                                            <i class="bi bi-eye"></i>
                                        </a>
                                    </div>
                                </div>
                                <span>Tải lên tập tin tự công bố của Đơn vị tổ chức (có đóng dấu ký tên) </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ProductInfoService.IsShowLoading"></app-loading>