<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-CompanyInfoVungTrong" data-bs-toggle="tab" href="#CompanyInfoVungTrong"
                                    role="tab" aria-controls="CompanyInfoVungTrong" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Đăng ký Mã số đóng gói</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="CompanyInfoVungTrong" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="CompanyInfoVungTrongService.BaseParameter.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="CompanyInfoVungTrongSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-6 col-12">
                                        <div class="row gx-4">
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                                    (click)="CompanyInfoVungTrongSearch()"><i class="bi bi-search"></i></a>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 col-6">
                                                <a style="width: 100%;" class="btn btn-success" title="Thêm mới"
                                                    (click)="CompanyInfoVungTrongAdd(0)"><i class="bi bi-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span *ngIf="CompanyInfoVungTrongService.List">({{CompanyInfoVungTrongService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #CompanyInfoVungTrongSort="matSort" [dataSource]="CompanyInfoVungTrongService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="CompanyInfoVungTrongPaginator">
                                                                {{CompanyInfoVungTrongPaginator.pageSize *
                                                                    CompanyInfoVungTrongPaginator.pageIndex + i + 1}}
                                                            </div>                                                            
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{ element.ID }}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận                                                            
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer" title="{{element.MaHoSo}}"
                                                                class="link-primary"
                                                                (click)="CompanyInfoVungTrongAdd(element.ID)"><b>{{element.NgayGhiNhan
                                                                    | date:'dd/MM/yyyy HH:mm:ss'}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CompanyInfoName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                           <b>{{element.CompanyInfoName}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="MaHoSo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã hồ sơ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer" title="{{element.MaHoSo}}"
                                                                class="link-primary"
                                                                (click)="CompanyInfoVungTrongAdd(element.ID)"><b>{{element.MaHoSo}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucATTPLoaiHoSoName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại hồ sơ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucATTPLoaiHoSoName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucATTPTinhTrangName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tình trạng hồ sơ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucATTPTinhTrangName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucATTPXepLoaiName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tình trạng cơ sở đóng gói
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucATTPXepLoaiName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-danger" (click)="CompanyInfoVungTrongDelete(element)"
                                                                *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="CompanyInfoVungTrongService.DisplayColumns002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: CompanyInfoVungTrongService.DisplayColumns002;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #CompanyInfoVungTrongPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                                    [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #CompanyInfoVungTrongSort="matSort" [dataSource]="CompanyInfoVungTrongService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Ghi nhận: </label>
                                                                    <br />
                                                                    <a style="cursor: pointer"
                                                                        title="{{element.cer_code}}"
                                                                        class="link-primary form-label"
                                                                        (click)="CompanyInfoVungTrongAdd(element.ID)"><b>{{element.NgayGhiNhan
                                                                            |
                                                                            date:'dd/MM/yyyy HH:mm:ss'}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Mã số: </label>
                                                                    <br />
                                                                    <a style="cursor: pointer"
                                                                        title="{{element.MaHoSo}}"
                                                                        class="link-primary"
                                                                        (click)="CompanyInfoVungTrongAdd(element.ID)"><b>{{element.MaHoSo}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Loại hồ sơ: </label>
                                                                    <label class="form-label">
                                                                        {{element.DanhMucATTPLoaiHoSoName}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Tình trạng: </label>
                                                                    <label class="form-label">
                                                                        {{element.DanhMucATTPTinhTrangName}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Xếp loại: </label>
                                                                    <label class="form-label">
                                                                        {{element.DanhMucATTPXepLoaiName}}</label>
                                                                </div>
                                                                <div class="row gx-4">
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <a class="btn btn-danger" style="width: 100%"
                                                                            (click)="CompanyInfoVungTrongDelete(element)"
                                                                            *ngIf="element.ID > 0"><i
                                                                                class="bi bi-trash"></i>
                                                                        </a>
                                                                    </div>
                                                                    <br />
                                                                    <br />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="CompanyInfoVungTrongService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: CompanyInfoVungTrongService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #CompanyInfoVungTrongPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                                    [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="CompanyInfoVungTrongService.IsShowLoading"></app-loading>