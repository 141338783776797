<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-PlanThamDinhDetailGiamSatDuLuong" data-bs-toggle="tab"
                href="#PlanThamDinhDetailGiamSatDuLuong" role="tab" aria-controls="PlanThamDinhDetailGiamSatDuLuong"
                aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Giám sát dư lượng</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="PlanThamDinhDetailGiamSatDuLuong" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="PlanThamDinhSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay
                        đổi</a>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Tải về Quyết định" (click)="PlanThamDinhCompanyDocumentAdd(43)"
                        class="btn btn-success"><i class="bi bi-cloud-download"></i> Tải về Quyết định</a>
                    
                    <div style="display: flex; align-items: center;" class="pt-3">
                        <a class="btn btn-success me-1" title="Đẩy lên file quyết định đã ký" (click)="openFileInputQuyetDinh()">
                            <i class="bi bi-cloud-upload"></i>
                        </a>
                        <input #fileInput type="file" (change)="ChangeFileName1(43, $event.target.files)" style="display: none">
                        <div *ngIf="QuyetDinhDoc?.FileName === null">
                            <a class="btn btn-secondary me-1" target="_blank" title="Tải về quyết định đã ký">
                                <i class="bi bi-cloud-download"></i>
                            </a>
                            <a class="btn btn-secondary me-1" target="_blank" title="Xem file quyết định đã ký">
                                <i class="bi bi-eye"></i>
                            </a>
                        </div>
                        
                        <div  *ngIf="QuyetDinhDoc?.FileName !== null">
                            <a class="btn btn-success me-1" 
                                title="Tải về"
                                target="_blank"
                                [href]="QuyetDinhDoc.FileName">
                                <i class="bi bi-cloud-download"></i>
                            </a>

                            <a *ngIf="isPDFFile(QuyetDinhDoc.FileName) || isWordOrExcelFile(QuyetDinhDoc.FileName) || isHtmlFile(QuyetDinhDoc.FileName)"
                                class="btn btn-success me-1"
                                title="Xem"
                                target="_blank"
                                [href]="getViewerUrl(QuyetDinhDoc.FileName)">
                                <i class="bi bi-eye"></i>
                            </a>
                        </div>
                        <span> File quyết định đã ký</span>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Tải về Mẫu giao nhận" (click)="PlanThamDinhCompanyDocumentAdd(56)"
                        class="btn btn-success"><i class="bi bi-cloud-download"></i> Tải về Mẫu giao nhận</a>
                    
                    <div style="display: flex; align-items: center;" class="pt-3">
                        <a class="btn btn-success me-1" title="Đẩy lên file quyết định đã ký" (click)="openFileInputMauGiaoNhan()">
                            <i class="bi bi-cloud-upload"></i>
                        </a>
                        
                        <input #fileInput1 type="file" (change)="ChangeFileName1(56, $event.target.files)" style="display: none">

                        <div *ngIf="GiaoNhanDoc?.FileName === null">
                            <a class="btn btn-secondary me-1" target="_blank" title="Tải về quyết định đã ký">
                                <i class="bi bi-cloud-download"></i>
                            </a>
                            <a class="btn btn-secondary me-1" target="_blank" title="Xem file quyết định đã ký">
                                <i class="bi bi-eye"></i>
                            </a>
                        </div>
                        
                        <div  *ngIf="GiaoNhanDoc?.FileName !== null">
                            <a class="btn btn-success me-1" 
                                title="Tải về"
                                target="_blank"
                                [href]="GiaoNhanDoc.FileName">
                                <i class="bi bi-cloud-download"></i>
                            </a>

                            <a *ngIf="isPDFFile(GiaoNhanDoc.FileName) || isWordOrExcelFile(GiaoNhanDoc.FileName) || isHtmlFile(GiaoNhanDoc.FileName)"
                                class="btn btn-success me-1"
                                title="Xem"
                                target="_blank"
                                [href]="getViewerUrl(GiaoNhanDoc.FileName)">
                                <i class="bi bi-eye"></i>
                            </a>
                        </div>

                        <span> File giao nhận đã ký</span>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <h4>Bước 01: Nhập Thông tin</h4>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Nội dung</label>
                                <input name="Name" [(ngModel)]="PlanThamDinhService.FormData.Name"
                                    placeholder="Nội dung" type="text" class="form-control" />
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ngày bắt đầu</label>
                                <input [ngModel]="PlanThamDinhService.FormData.NgayBatDau | date:'yyyy-MM-dd'"
                                    (ngModelChange)="DateNgayBatDau($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                                    type="date" class="form-control" autocomplete="off">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ngày kết thúc</label>
                                <input [ngModel]="PlanThamDinhService.FormData.NgayKetThuc | date:'yyyy-MM-dd'"
                                    (ngModelChange)="DateNgayKetThuc($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                                    type="date" class="form-control" autocomplete="off">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Thời gian lấy mẫu</label>
                                <select class="form-select" name="DanhMucThoiGianLayMauID"
                                    [(ngModel)]="PlanThamDinhService.FormData.DanhMucThoiGianLayMauID">
                                    <option *ngFor="let item of DanhMucThoiGianLayMauService.List;" [value]="item.ID">
                                        {{item.Name}}</option>
                                </select>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Năm</label>
                                <input name="Nam" [(ngModel)]="PlanThamDinhService.FormData.Nam" placeholder="0"
                                    type="number" class="form-control" style="text-align: right;" />
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Tháng</label>
                                <input name="Thang" [(ngModel)]="PlanThamDinhService.FormData.Thang" placeholder="0"
                                    type="number" class="form-control" style="text-align: right;" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="row gx-4">
                        <div class="col-lg-4 col-sm-12 col-12">
                            <a style="width: 100%;" title="Lưu thay đổi" (click)="DanhMucLayMauAdd(0)"
                                class="btn btn-success"><i class="bi bi-plus"></i> Thêm Mẫu xét nghiệm (Nếu
                                thiếu)</a>
                        </div>
                        <div class="col-lg-4 col-sm-12 col-12">
                            <a style="width: 100%;" title="Lưu thay đổi" (click)="DanhMucLayMauChiTieuAdd(0)"
                                class="btn btn-success"><i class="bi bi-plus"></i> Thêm Chỉ tiêu (Nếu thiếu)</a>
                        </div>
                    </div>
                    <div class="card mb-4 card-success">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bước 02: Tạo kế hoạch
                                ({{PlanThamDinhDanhMucLayMauService.List.length-1}} items)</h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhDanhMucLayMauSort="matSort"
                                        [dataSource]="PlanThamDinhDanhMucLayMauService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="DistrictDataID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Khu vực
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <select *ngIf="element.ID==0" class="form-select"
                                                    name="DistrictDataID{{element.ID}}"
                                                    [(ngModel)]="element.DistrictDataID">
                                                    <option *ngFor="let item of DistrictDataService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.DistrictDataName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DanhMucLayMauID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Mẫu xét nghiệm
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <select *ngIf="element.ID==0" class="form-select"
                                                    name="DanhMucLayMauID{{element.ID}}"
                                                    [(ngModel)]="element.DanhMucLayMauID">
                                                    <option *ngFor="let item of DanhMucLayMauService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.DanhMucLayMauName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DanhMucLayMauChiTieuID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ tiêu
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <select *ngIf="element.ID==0" class="form-select"
                                                    name="DanhMucLayMauChiTieuID{{element.ID}}"
                                                    [(ngModel)]="element.DanhMucLayMauChiTieuID">
                                                    <option *ngFor="let item of DanhMucLayMauChiTieuService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.DanhMucLayMauChiTieuName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info" (click)="PlanThamDinhDanhMucLayMauSave(element)"
                                                    *ngIf="element.ID==0"><i class="bi bi-sd-card"></i>
                                                </a>
                                                <a class="btn btn-danger"
                                                    (click)="PlanThamDinhDanhMucLayMauDelete(element)"
                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhDanhMucLayMauService.DisplayColumns003">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhDanhMucLayMauService.DisplayColumns003;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #PlanThamDinhDanhMucLayMauPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhDanhMucLayMauSort="matSort"
                                        [dataSource]="PlanThamDinhDanhMucLayMauService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhDanhMucLayMauService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhDanhMucLayMauService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #PlanThamDinhDanhMucLayMauPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-danger">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bước 03: Phân công cán bộ tham gia
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhDanhMucLayMauSort001="matSort"
                                        [dataSource]="PlanThamDinhDanhMucLayMauService.DataSource001"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="DistrictDataID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Khu vực
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.DistrictDataName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="ThanhVienID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cán bộ
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <select class="form-select" name="ThanhVienID{{element.ID}}"
                                                    [(ngModel)]="element.ThanhVienID">
                                                    <option *ngFor="let item of ThanhVienService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info" (click)="PlanThamDinhDanhMucLayMauSave(element)"
                                                    *ngIf="element.ID>0"><i class="bi bi-sd-card"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhDanhMucLayMauService.DisplayColumns004">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhDanhMucLayMauService.DisplayColumns004;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #PlanThamDinhDanhMucLayMauPaginator001="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhDanhMucLayMauSort001="matSort"
                                        [dataSource]="PlanThamDinhDanhMucLayMauService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhDanhMucLayMauService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhDanhMucLayMauService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #PlanThamDinhDanhMucLayMauPaginator001="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-warning">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bước 04: Chọn Đơn vị Tổ chức
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhDanhMucLayMauSort002="matSort"
                                        [dataSource]="PlanThamDinhDanhMucLayMauService.DataSource002"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a style="width: 100%;" class="btn btn-success" title="Thêm mới"
                                                    (click)="PlanThamDinhDanhMucLayMauAdd(element.ID)"><i
                                                        class="bi bi-plus"></i></a>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DistrictDataID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Khu vực
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.DistrictDataName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DanhMucLayMauID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Mẫu xét nghiệm
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.DanhMucLayMauName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DanhMucLayMauChiTieuID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ tiêu
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.DanhMucLayMauChiTieuName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="TypeName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã mẫu
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.TypeName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="CompanyInfoID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị Tổ chức
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.CompanyInfoName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="CompanyLakeID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ao hồ
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.CompanyLakeName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>                                        
                                        <ng-container matColumnDef="SoLuongLayMau">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Số lượng (kg)
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="text-align: right;">
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.SoLuongLayMau}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="NgayGhiNhan">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="text-align: right;">
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.NgayGhiNhan
                                                        | date:'dd/MM/yyyy'}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhDanhMucLayMauService.DisplayColumns011">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhDanhMucLayMauService.DisplayColumns011;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #PlanThamDinhDanhMucLayMauPaginator002="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhDanhMucLayMauSort002="matSort"
                                        [dataSource]="PlanThamDinhDanhMucLayMauService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhDanhMucLayMauService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhDanhMucLayMauService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #PlanThamDinhDanhMucLayMauPaginator002="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-12 col-sm-12 col-12" *ngIf="PlanThamDinhService.FormData.ID>0">
                    <div class="card mb-4 card-primary">
                        <div class="card-header">
                            <h5 class="card-title text-white bg-primary">Bước 05: Tải về
                                biểu mẫu và Tải lên biểu mẫu đã ký
                            </h5>
                        </div>
                        <div class="card-body bg-primary">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhCompanyDocumentSort="matSort"
                                        [dataSource]="PlanThamDinhCompanyDocumentService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="LastUpdatedDate">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Ngày Ghi nhận
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <b>{{element.LastUpdatedDate | date:'dd/MM/yyyy HH:mm:ss'}}</b>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="width: 600px;">
                                                <input class="form-control" type="text" placeholder="Tiêu đề"
                                                    name="Name{{element.ID}}" [(ngModel)]="element.Name" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="FileName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải về hoặc
                                                tải lên
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a *ngIf="element.ID > 0 && element.TypeName" class="link-primary"
                                                    href="{{element.TypeName}}"><b>Tải về biểu mẫu</b></a>
                                                <input *ngIf="element.ID == 0" type="file"
                                                    (change)="ChangeFileName($event.target.files)">
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info"
                                                    (click)="PlanThamDinhCompanyDocumentSave(element)"><i
                                                        class="bi bi-sd-card"></i> </a>&nbsp;&nbsp;&nbsp;
                                                <a class="btn btn-danger"
                                                    (click)="PlanThamDinhCompanyDocumentDelete(element)"
                                                    *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                </a>&nbsp;&nbsp;&nbsp;
                                                <a *ngIf="element.ID > 0 && element.FileName" class="btn btn-success"
                                                    title="Tải về" href="{{element.FileName}}" target="_blank"><i
                                                        class="bi bi-cloud-download"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhCompanyDocumentService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhCompanyDocumentService.DisplayColumns001;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #PlanThamDinhCompanyDocumentPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhCompanyDocumentSort="matSort"
                                        [dataSource]="PlanThamDinhCompanyDocumentService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhCompanyDocumentService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhCompanyDocumentService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #PlanThamDinhCompanyDocumentPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="PlanThamDinhService.IsShowLoading"></app-loading>