import { Component, OnInit, Inject, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { Subject } from 'rxjs';

import { ProductGroup } from 'src/app/shared/ProductGroup.model';
import { ProductGroupService } from 'src/app/shared/ProductGroup.service';

import { DanhMucATTPXepLoai } from 'src/app/shared/DanhMucATTPXepLoai.model';
import { DanhMucATTPXepLoaiService } from 'src/app/shared/DanhMucATTPXepLoai.service';

import { CompanyInfo } from 'src/app/shared/CompanyInfo.model';
import { CompanyInfoService } from 'src/app/shared/CompanyInfo.service';

import { PlanThamDinh } from 'src/app/shared/PlanThamDinh.model';
import { PlanThamDinhService } from 'src/app/shared/PlanThamDinh.service';
import { PlanThamDinhCompanies } from 'src/app/shared/PlanThamDinhCompanies.model';
import { PlanThamDinhCompaniesService } from 'src/app/shared/PlanThamDinhCompanies.service';
import { PlanThamDinhCompanyProductGroup } from 'src/app/shared/PlanThamDinhCompanyProductGroup.model';
import { PlanThamDinhCompanyProductGroupService } from 'src/app/shared/PlanThamDinhCompanyProductGroup.service';
import { PlanThamDinhCompanyDocument } from 'src/app/shared/PlanThamDinhCompanyDocument.model';
import { PlanThamDinhCompanyDocumentService } from 'src/app/shared/PlanThamDinhCompanyDocument.service';

import { CompanyInfoDetailComponent } from '../company-info-detail/company-info-detail.component';
import { ProductGroupDetailComponent } from '../product-group-detail/product-group-detail.component';
import { PlanThamDinhCompanyDocumentDetailComponent } from '../plan-tham-dinh-company-document-detail/plan-tham-dinh-company-document-detail.component';

@Component({
  selector: 'app-plan-tham-dinh-detail-chuoi-cung-ung-an-toan-thuc-pham',
  templateUrl: './plan-tham-dinh-detail-chuoi-cung-ung-an-toan-thuc-pham.component.html',
  styleUrls: ['./plan-tham-dinh-detail-chuoi-cung-ung-an-toan-thuc-pham.component.css']
})
export class PlanThamDinhDetailChuoiCungUngAnToanThucPhamComponent implements OnInit {

  @ViewChild('fileInput1', { static: false }) fileInput1!: ElementRef;
  @ViewChild('fileInput2', { static: false }) fileInput2!: ElementRef;
  @ViewChild('fileInput3', { static: false }) fileInput3!: ElementRef;
  @ViewChildren('fileInput') fileInputs!: QueryList<ElementRef>;
  @ViewChildren('fileInput4') fileInputs1!: QueryList<ElementRef>;
  

  @ViewChild('PlanThamDinhCompaniesSort') PlanThamDinhCompaniesSort: MatSort;
  @ViewChild('PlanThamDinhCompaniesPaginator') PlanThamDinhCompaniesPaginator: MatPaginator;

  @ViewChild('PlanThamDinhCompanyProductGroupSort') PlanThamDinhCompanyProductGroupSort: MatSort;
  @ViewChild('PlanThamDinhCompanyProductGroupPaginator') PlanThamDinhCompanyProductGroupPaginator: MatPaginator;

  @ViewChild('PlanThamDinhCompanyDocumentSort') PlanThamDinhCompanyDocumentSort: MatSort;
  @ViewChild('PlanThamDinhCompanyDocumentPaginator') PlanThamDinhCompanyDocumentPaginator: MatPaginator;
  searchSubject: Subject<string> = new Subject();
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<PlanThamDinhDetailChuoiCungUngAnToanThucPhamComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,

    public DanhMucATTPXepLoaiService: DanhMucATTPXepLoaiService,

    public CompanyInfoService: CompanyInfoService,
    public ProductGroupService: ProductGroupService,

    public PlanThamDinhService: PlanThamDinhService,
    public PlanThamDinhCompaniesService: PlanThamDinhCompaniesService,
    public PlanThamDinhCompanyProductGroupService: PlanThamDinhCompanyProductGroupService,
    public PlanThamDinhCompanyDocumentService: PlanThamDinhCompanyDocumentService,

  ) { }

  ngOnInit(): void {
    this.DanhMucATTPXepLoaiSearch();
    this.CompanyInfoSearch();
    this.ProductGroupSearch();

    this.PlanThamDinhCompaniesSearch();
    this.PlanThamDinhCompanyProductGroupSearch();
    this.PlanThamDinhCompanyDocumentSearch();
    this.searchSubject.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(async (searchString) => this.CompanyInfoFilter(searchString))
    ).subscribe(
      (data: any) => {
        this.CompanyInfoService.List = data;
      },
      (error) => {
        console.error('Error fetching company info:', error);
      }
    );
  }

  isPDFFile(fileName: string): boolean {
    return /\.pdf$/i.test(fileName);
  }
  isWordOrExcelFile(fileName: string): boolean {
    return /\.(doc|docx|xlsx)$/i.test(fileName);
  }
  isHtmlFile(fileName: string): boolean {
    return /\.(html)$/i.test(fileName);
  }
  onSearchChange(searchString: string) {
    this.searchSubject.next(searchString);
  }
  getViewerUrl(fileName: string): string {
    if (this.isPDFFile(fileName) || this.isHtmlFile(fileName)) {
      return fileName;
    } else if (this.isWordOrExcelFile(fileName)) {
      return `https://docs.google.com/viewer?url=${encodeURIComponent(fileName)}&embedded=true`;
    }
    return fileName;
  }
  ChangeFileName1(documentTemplateID: number, files: FileList | null) {
    if (files && files.length > 0) {
      this.PlanThamDinhCompanyDocumentService.FileToUpload = files;
      this.PlanThamDinhCompanyDocumentsSave(documentTemplateID)
    }
  }
  ChangeFileName2(element: PlanThamDinhCompanies, files: FileList | null, documentTemplateID) {
    if (files && files.length > 0) {
      this.PlanThamDinhCompanyDocumentService.FileToUpload = files;
      this.PlanThamDinhCompanyDocumentsSave1(element,documentTemplateID)
    }
  }
  openFileInput(element: any) {
    const index = this.PlanThamDinhCompaniesService.List.indexOf(element); // Tìm index của element
    if (index !== -1) {
      const inputElement = this.fileInputs.toArray()[index];
      inputElement.nativeElement.click();
    }
  }
  openFileInput1(element: any) {
    const index = this.PlanThamDinhCompaniesService.List.indexOf(element); // Tìm index của element
    if (index !== -1) {
      const inputElement = this.fileInputs1.toArray()[index];
      inputElement.nativeElement.click();
    }
  }
  
  openFileInputQuyetDinh(buttonNumber: number){
    if (buttonNumber === 1){
      this.fileInput1.nativeElement.click();
    } else if (buttonNumber === 2){
      this.fileInput2.nativeElement.click();
    } else if (buttonNumber === 3){
      this.fileInput3.nativeElement.click();
    }

  }
  RegisDoc:PlanThamDinhCompanyDocument
  DecisionDoc:PlanThamDinhCompanyDocument
  Certificate:PlanThamDinhCompanyDocument
  
  SHOWRESULT :boolean = environment.ShowResult

  DanhMucATTPXepLoaiSearch() {
    this.DanhMucATTPXepLoaiService.ComponentGetAllToListAsync();
  }
  DateNgayBatDau(value) {
    this.PlanThamDinhService.FormData.NgayBatDau = new Date(value);
  }
  DateNgayKetThuc(value) {
    this.PlanThamDinhService.FormData.NgayKetThuc = new Date(value);
  }

  CompanyInfoSearch() {
    this.CompanyInfoService.ComponentGet000ToListAsync();
  }
  CompanyInfoFilter(searchString: string) {
    this.CompanyInfoService.SearchOrganizationAbsolute(searchString);
  }
  onCompanyInfoSelected(event: any, element: any): void {
    const selectedItem = this.CompanyInfoService.ListFilter.find(item => item.ID === event.value);
    
    if (selectedItem) {
        element.CompanyInfoID = selectedItem.ID;
        element.CompanyInfoName = selectedItem.Name;
        element.Note = selectedItem.address;
    }
  }
  ProductGroupSearch() {
    this.PlanThamDinhService.IsShowLoading = true;
    this.ProductGroupService.GetAllToListAsync().subscribe(
      res => {
        this.ProductGroupService.List = (res as any[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.ProductGroupService.ListFilter = this.ProductGroupService.List;
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }

  PlanThamDinhCompanyProductGroupSearch() {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhCompanyProductGroupService.BaseParameter.SearchString = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhCompanyProductGroupService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyProductGroupService.List = (res as PlanThamDinhCompanyProductGroup[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.PlanThamDinhCompanyProductGroupService.DataSource = new MatTableDataSource(this.PlanThamDinhCompanyProductGroupService.List);
        this.PlanThamDinhCompanyProductGroupService.DataSource.sort = this.PlanThamDinhCompanyProductGroupSort;
        this.PlanThamDinhCompanyProductGroupService.DataSource.paginator = this.PlanThamDinhCompanyProductGroupPaginator;
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompanyProductGroupSave(element: PlanThamDinhCompanyProductGroup) {
    this.PlanThamDinhService.IsShowLoading = true;
    element.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;
    element.Code = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhCompanyProductGroupService.FormData = element;
    this.PlanThamDinhCompanyProductGroupService.SaveAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyProductGroupSearch();
        this.ProductGroupSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompanyProductGroupDelete(element: PlanThamDinhCompanyProductGroup) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhCompanyProductGroupService.BaseParameter.ID = element.ID;
    this.PlanThamDinhCompanyProductGroupService.RemoveAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyProductGroupSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  CheckInputIsEmpty(inputValue: string){
    if(inputValue === null || inputValue.trim() === ""){
      return true;
    }
    return false;
  }
  PlanThamDinhCompaniesSearch() {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhCompaniesService.BaseParameter.SearchString = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhCompaniesService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.PlanThamDinhCompaniesService.List = (res as PlanThamDinhCompanies[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.PlanThamDinhCompaniesService.List.forEach(item => {
          if (item.CompanyInfoID !== null) {
            item.IsNoiThanh = true;
          }
        });
        this.PlanThamDinhCompaniesService.DataSource = new MatTableDataSource(this.PlanThamDinhCompaniesService.List);
        this.PlanThamDinhCompaniesService.DataSource.sort = this.PlanThamDinhCompaniesSort;
        this.PlanThamDinhCompaniesService.DataSource.paginator = this.PlanThamDinhCompaniesPaginator;
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompaniesSave(element: PlanThamDinhCompanies) {
    if(element.IsNoiThanh !==true){
      element.CompanyInfoID = null;
    }
    this.PlanThamDinhService.IsShowLoading = true;
    element.ParentID = this.PlanThamDinhService.FormData.ID;
    element.Code = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhCompaniesService.FormData = element;
    this.PlanThamDinhCompaniesService.SaveAsync().subscribe(
      res => {
        this.PlanThamDinhCompaniesSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompaniesDelete(element: PlanThamDinhCompanies) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhCompaniesService.BaseParameter.ID = element.ID;
    this.PlanThamDinhCompaniesService.RemoveAsync().subscribe(
      res => {
        this.PlanThamDinhCompaniesSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }

  PlanThamDinhCompanyDocumentSearch() {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhCompanyDocumentService.BaseParameter.SearchString = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhCompanyDocumentService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyDocumentService.List = (res as PlanThamDinhCompanyDocument[]).sort((a, b) => (a.SortOrder < b.SortOrder ? 1 : -1));
        this.RegisDoc = this.PlanThamDinhCompanyDocumentService.List.find(i=>i.IsUploadConfirm === true && i.DocumentTemplateID === 63);
        this.DecisionDoc = this.PlanThamDinhCompanyDocumentService.List.find(i=>i.IsUploadConfirm === true && i.DocumentTemplateID === 64);
        this.Certificate = this.PlanThamDinhCompanyDocumentService.List.find(i=>i.IsUploadConfirm === true && i.DocumentTemplateID === 42);
        this.PlanThamDinhCompanyDocumentService.List = this.PlanThamDinhCompanyDocumentService.List.filter(i => i.IsUploadConfirm !== true);
        this.PlanThamDinhCompanyDocumentService.DataSource = new MatTableDataSource(this.PlanThamDinhCompanyDocumentService.List);
        this.PlanThamDinhCompanyDocumentService.DataSource.sort = this.PlanThamDinhCompanyDocumentSort;
        this.PlanThamDinhCompanyDocumentService.DataSource.paginator = this.PlanThamDinhCompanyDocumentPaginator;
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompanyDocumentsSave1(planThamDinhCompanies: PlanThamDinhCompanies, documentTemplateID: number) {
    this.PlanThamDinhCompaniesService.IsShowLoading = true;
    let element: PlanThamDinhCompanyDocument = new PlanThamDinhCompanyDocument()
    element.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;
    element.DocumentTemplateID = documentTemplateID;
    element.Code = this.PlanThamDinhCompanyDocumentService.FormData.Code;
    element.IsUploadConfirm = this.SHOWRESULT;
    this.PlanThamDinhCompanyDocumentService.FormData = element;
    this.PlanThamDinhCompanyDocumentService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyDocumentService.FormData = (res as PlanThamDinhCompanyDocument);
        if(documentTemplateID === 111111){
          planThamDinhCompanies.CompanyLakeName = this.PlanThamDinhCompanyDocumentService.FormData.FileName;
        }else if(documentTemplateID === 222222){
          planThamDinhCompanies.DanhMucProductGroupName = this.PlanThamDinhCompanyDocumentService.FormData.FileName;
        }
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
      }
    );
  }

  PlanThamDinhCompanyDocumentsSave(documentTemplateID) {
    this.PlanThamDinhCompaniesService.IsShowLoading = true;
    let element: PlanThamDinhCompanyDocument = new PlanThamDinhCompanyDocument()
    element.DocumentTemplateID = documentTemplateID;
    element.IsUploadConfirm = this.SHOWRESULT;
    element.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;
    element.Code = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhCompanyDocumentService.FormData = element;
    this.PlanThamDinhCompanyDocumentService.SaveAndUploadFileAsync().subscribe(
      res => {
        const doc = (res as PlanThamDinhCompanyDocument);
        if(doc.DocumentTemplateID === 63){
          this.RegisDoc = doc;
        } else if (doc.DocumentTemplateID === 64) {
          this.DecisionDoc = doc;
        } else if (doc.DocumentTemplateID === 42) {
          this.Certificate = doc;
        }
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompanyDocumentSave(element: PlanThamDinhCompanyDocument) {
    this.PlanThamDinhService.IsShowLoading = true;
    element.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;
    element.Code = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhCompanyDocumentService.FormData = element;
    this.PlanThamDinhCompanyDocumentService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyDocumentSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompanyDocumentDelete(element: PlanThamDinhCompanyDocument) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhCompanyDocumentService.BaseParameter.ID = element.ID;
    this.PlanThamDinhCompanyDocumentService.RemoveAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyDocumentSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompanyDocumentAdd(DocumentTemplateID: number) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhCompanyDocumentService.BaseParameter.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;
    this.PlanThamDinhCompanyDocumentService.BaseParameter.DocumentTemplateID = DocumentTemplateID;
    this.PlanThamDinhCompanyDocumentService.GetByPlanThamDinhID_DocumentTemplateIDAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyDocumentService.FormData = res as PlanThamDinhCompanyDocument;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: this.PlanThamDinhCompaniesService.FormData.ID };
        const dialog = this.dialog.open(PlanThamDinhCompanyDocumentDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  ChangeFileName(files: FileList) {
    if (files) {
      this.PlanThamDinhCompanyDocumentService.FileToUpload = files;
    }
  }
  PlanThamDinhSave() {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhService.FormData.ParentID = environment.PlanTypeIDChuoiCungUng;
    this.PlanThamDinhService.SaveAsync().subscribe(
      res => {
        this.PlanThamDinhService.FormData = res as PlanThamDinh;
        this.PlanThamDinhService.FormData.Code = this.PlanThamDinhCompaniesService.BaseParameter.SearchString;
        this.PlanThamDinhCompaniesSearch();
        this.PlanThamDinhCompanyProductGroupSearch();
        this.PlanThamDinhCompanyDocumentSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  CompanyInfoAdd(ID: number) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.CompanyInfoService.BaseParameter.ID = ID;
    this.CompanyInfoService.GetByIDAsync().subscribe(
      res => {
        this.CompanyInfoService.FormData = res as CompanyInfo;
        this.CompanyInfoService.FormData.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;
        this.CompanyInfoService.FormData.PlanThamDinhCode = this.PlanThamDinhService.FormData.Code;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(CompanyInfoDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.CompanyInfoSearch();
        });
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }

  Close() {
    this.dialogRef.close();
  }

}