import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { NamThang } from 'src/app/shared/NamThang.model';
import { DownloadService } from 'src/app/shared/Download.service';

import { Report } from 'src/app/shared/Report.model';
import { ReportService } from 'src/app/shared/Report.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-report0003',
  templateUrl: './report0003.component.html',
  styleUrls: ['./report0003.component.css']
})
export class Report0003Component implements OnInit {

  @ViewChild('ReportSort') ReportSort: MatSort;
  @ViewChild('ReportPaginator') ReportPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,

    public DownloadService: DownloadService,

    public ReportService: ReportService,
  ) { }

  ngOnInit(): void {
    this.ComponentGetListNam();
    this.ComponentGetListThang();
    this.Report0003Search();
  }

  exportToExcel(): void {
    let i = 0
    const formattedData = this.ReportService.List.map((item, index) => ({
      'STT': index + 1,
      'Tháng': item.Nam + '-' + item.Thang,
      'Số cơ sở lấy': item.ThongKe001,
      'Số mẫu lấy': item.ThongKe002,
      'Số mẫu nhiễm': item.ThongKe003,
      'Tháng cùng kỳ': item.Nam - 1 + '-' + item.Thang,
      'Số cơ sở lấy(CK)': item.ThongKe004,
      'Số mẫu lấy(CK)': item.ThongKe005,
      'Số mẫu nhiễm(CK)': item.ThongKe006,
    }));

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(formattedData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');

    XLSX.writeFile(wb, 'báo cáo ATTP sau thu hoạch theo tháng.xlsx');
  }

  ComponentGetListNam() {
    this.DownloadService.ComponentGetListNam();
  }

  ComponentGetListThang() {
    this.DownloadService.ComponentGetListThang();
  }
  Report0003Search() {
    this.ReportService.IsShowLoading = true;
    this.ReportService.BaseParameter.ParentID = environment.PlanTypeIDAnToanThucPhamSauThuHoach;
    this.ReportService.BaseParameter.Active = true;
    this.ReportService.Report0003ToListAsync().subscribe(
      res => {
        this.ReportService.List = (res as Report[]);
        this.ReportService.DataSource = new MatTableDataSource(this.ReportService.List);
        this.ReportService.DataSource.sort = this.ReportSort;
        this.ReportService.DataSource.paginator = this.ReportPaginator;
        this.ReportService.IsShowLoading = false;
      },
      err => {
        this.ReportService.IsShowLoading = false;
      }
    );
  }
}