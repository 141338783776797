<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-PlanThamDinhDetailCoSoNuoi" data-bs-toggle="tab"
                href="#PlanThamDinhDetailCoSoNuoi" role="tab" aria-controls="PlanThamDinhDetailCoSoNuoi"
                aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Thẩm định cơ sơ nuôi</span>
            </a>
        </li>
        <!-- <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-PlanThamDinhCompanyBienBan" data-bs-toggle="tab"
                href="#PlanThamDinhCompanyBienBan" role="tab" aria-controls="PlanThamDinhCompanyBienBan"
                aria-selected="false">
                <span class="badge bg-warning" style="font-size: 16px;">Biên bản</span>
            </a>
        </li> -->
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="PlanThamDinhDetailCoSoNuoi" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <h4>Bước 01: Thông tin chung</h4>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Nội dung</label>
                                <input name="Name" [(ngModel)]="PlanThamDinhService.FormData.Name"
                                    placeholder="Nội dung" type="text" class="form-control" />
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Sản phẩm</label>
                                <input name="Display" [(ngModel)]="PlanThamDinhService.FormData.Display"
                                    placeholder="Sản phẩm" type="text" class="form-control" />
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ghi chú</label>
                                <input name="Note" [(ngModel)]="PlanThamDinhService.FormData.Note" placeholder="Ghi chú"
                                    type="text" class="form-control" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ngày thẩm định</label>
                                <input [ngModel]="PlanThamDinhService.FormData.NgayBatDau | date:'yyyy-MM-dd'"
                                    (ngModelChange)="DateNgayBatDau($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                                    type="date" class="form-control" autocomplete="off">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ngày hết hạn</label>
                                <input [ngModel]="PlanThamDinhService.FormData.NgayKetThuc | date:'yyyy-MM-dd'"
                                    (ngModelChange)="DateNgayKetThuc($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                                    type="date" class="form-control" autocomplete="off">
                            </div>
                            <!-- <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Xếp loại</label>
                                <select class="form-select" name="DanhMucATTPXepLoaiID"
                                    [disabled]="IsCompany"
                                    [(ngModel)]="PlanThamDinhService.FormData.DanhMucATTPXepLoaiID">
                                    <option *ngFor="let item of DanhMucATTPXepLoaiService.List;" [value]="item.ID">
                                        {{item.Name}}</option>
                                </select>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <h4>Bước 02: Thông tin cơ sở nuôi</h4>
                    </div>
                    <div class="row gx-4">
                        <div *ngIf="!IsCompany" class="col-lg-6 col-sm-12 col-12">
                            <mat-select *ngIf="PlanThamDinhService.FormData.ID==0" class="form-control"
                                name="PlanThamDinhService.FormData.CompanyInfoID"
                                [(ngModel)]="PlanThamDinhService.FormData.CompanyInfoID"
                                (selectionChange)="CompanyInfoGetByID()">
                                <input placeholder="Tìm..." class="form-control"
                                    (keyup)="CompanyInfoFilter($event.target.value)">
                                <mat-option *ngFor="let item of CompanyInfoService.ListFilter" [value]="item.ID">
                                    <span *ngIf="item.Name">{{item.Name}}</span><span *ngIf="item.address"> - {{item.address}}</span><span *ngIf="item.phone">  - {{item.phone}}</span><span *ngIf="item.DKKD"> - {{item.DKKD}}</span>
                                </mat-option>
                            </mat-select>
                        </div>
                        <div *ngIf="!IsCompany" class="col-lg-3 col-sm-12 col-12">
                            <a style="width: 100%;" class="btn btn-success" title="Thêm mới"
                                (click)="CompanyInfoAdd(0)">Thêm (nếu
                                thiếu)</a>
                        </div>
                        <div class="col-lg-3 col-sm-12 col-12" *ngIf="CompanyInfoService.FormData.ID>0">
                            <a style="width: 100%;" class="btn btn-success" title="Cập nhật thông tin"
                                (click)="CompanyInfoAdd(PlanThamDinhService.FormData.CompanyInfoID)">Cập nhật</a>
                        </div>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Tên cơ sở</label>
                                <input name="Name" [(ngModel)]="CompanyInfoService.FormData.Name" placeholder="Tiêu đề"
                                    type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Mã số cơ sở nuôi</label>
                                <input name="CoSoNuoiMa" [(ngModel)]="CompanyInfoService.FormData.CoSoNuoiMa"
                                    placeholder="Để trống nếu chưa có Mã số cơ sở nuôi" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Kinh độ</label>
                                <input name="longitude" [(ngModel)]="CompanyInfoService.FormData.longitude"
                                    placeholder="Kinh độ" type="number" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <!-- <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Đối tượng thủy sản ( mang xuống ao hồ)</label>
                                <select class="form-select" name="CompanyInfoService.FormData.DanhMucHinhThucNuoiID"
                                    [(ngModel)]="CompanyInfoService.FormData.DanhMucHinhThucNuoiID">
                                    <option *ngFor="let item of DanhMucHinhThucNuoiService.List;" [value]="item.ID">
                                        {{item.Name}}</option>
                                </select>
                            </div> -->
                            <!-- <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Tổng diện tích (ha)</label>
                                <input name="DienTich" [(ngModel)]="CompanyInfoService.FormData.DienTich"
                                    placeholder="0" type="number" class="form-control">
                            </div> -->
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Tổng diện tích nuôi trồng thủy sản (ha)</label>
                                <input name="CoSoNuoiDienTichNuoiTrong"
                                    [(ngModel)]="CompanyInfoService.FormData.CoSoNuoiDienTichNuoiTrong" placeholder="0"
                                    type="number" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Tổng số ao</label>
                                <input name="CoSoNuoiSoLuongAo"
                                    [(ngModel)]="CompanyInfoService.FormData.CoSoNuoiSoLuongAo" placeholder="0"
                                    type="number" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Vĩ độ</label>
                                <input name="latitude" [(ngModel)]="CompanyInfoService.FormData.latitude"
                                    placeholder="Vĩ độ" type="number" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="row gx-4" style="display: flex;">
                        <div class="col-lg-3 col-sm-12 col-12">
                            <a style="width: 100%;" title="Lưu thay đổi" (click)="PlanThamDinhSave()" class="btn btn-info"><i
                                    class="bi bi-sd-card"></i> Lưu thay
                                đổi</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <h4>Bước 03: Ao hồ</h4>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-5 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <select class="form-select" name="lakeSelectionId"
                                    [(ngModel)]="lakeSelectionId">
                                    <option [value]="0"></option>
                                    <option *ngFor="let item of CompanyLakeList;" [value]="item.ID">
                                        {{item.Name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-1 col-sm-12 col-12">
                            <a style="width: 100%;" class="btn btn-success" title="Thêm" (click)="AddLake()"><i class="bi bi-plus-circle"></i></a>
                        </div>
                        <div class="col-lg-3 col-sm-12 col-12">
                            <a style="width: 100%;" class="btn btn-success" title="Thêm mới"
                                (click)="CompanyLakeAdd(0)">Thêm Ao(nếu thiếu)</a>
                        </div>
                        <div class="col-lg-3 col-sm-12 col-12">
                            <a style="width: 100%;" class="btn btn-success" title="Bản đồ"
                                (click)="CompanyLakeMap()">Bản đồ</a>
                        </div>
                        <!-- <input [(ngModel)]="CompanyLakeService.BaseParameter.SearchString" placeholder="Tìm ..."
                            class="form-control" (keyup.enter)="CompanyLakeSearch()">
                        </div> -->
                    </div>
                    <div class="card mb-4 card-warning">
                        <div class="card-header">
                            <h5 class="card-title text-white">Ao hồ
                                <span *ngIf="CompanyLakeService.List">({{CompanyLakeService.List.length}}
                                    items)</span>
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table mat-table matSort #CompanyLakeSort="matSort"
                                        [dataSource]="CompanyLakeService.DataSource" class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                STT
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;"
                                                style="text-align: center;">
                                                <div *ngIf="CompanyLakePaginator">
                                                    {{CompanyLakePaginator.pageSize *
                                                    CompanyLakePaginator.pageIndex + i + 1}}
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="ID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.ID}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="species_name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vật nuôi
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.species_name}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Code">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã ao</th>
                                            <td mat-cell *matCellDef="let element">
                                                <a style="cursor: pointer;" title="{{element.Code}}"
                                                    class="link-primary"
                                                    (click)="CompanyLakeAdd(element.ID)"><b>{{element.Code}}</b></a>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a style="cursor: pointer;" title="{{element.Name}}"
                                                    class="link-primary"
                                                    (click)="CompanyLakeAdd(element.ID)"><b>{{element.Name}}</b></a>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="acreage">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện tích (ha)
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="text-align: right;">
                                                {{element.acreage | number: "1.0-0"}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-danger" (click)="CompanyLakeDelete(element)"
                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="CompanyLakeService.DisplayColumns002">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyLakeService.DisplayColumns002;">
                                        </tr>
                                    </table>
                                    <mat-paginator #CompanyLakePaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row gx-4">
                    <div class="col-lg-3 col-sm-12 col-12">
                        <a *ngIf="isDataLoaded" style="width: 100%;" title="Tải mẫu Đơn đăng ký nuôi trồng thủy sản" [href]="DownloadForm(34)"
                            class="btn btn-success"><i class="bi bi-cloud-download"></i>Tải mẫu Đơn đăng ký nuôi trồng thủy sản</a>

                        <div class="col-12" style="display: flex;">
                            <div style="display: flex;" class="col-lg-12 col-sm-12 col-12">
                                <a class="btn btn-success me-1" title="Đẩy lên Đơn đăng ký nuôi trồng thủy sản đã ký" (click)="openFileInputQuyetDinh(34)">
                                    <i class="bi bi-cloud-upload"></i>
                                </a>
                                
                                <input #fileInputRegisDoc type="file" (change)="ChangeFileName1(34, $event.target.files)" style="display: none">
                                <div *ngIf="RegisDoc?.FileName === null">
                                    <a class="btn btn-secondary me-1" target="_blank" title="Tải về Đơn đăng ký nuôi trồng thủy sản đã ký">
                                        <i class="bi bi-cloud-download"></i>
                                    </a>
                                    <a class="btn btn-secondary me-1" target="_blank" title="Xem Đơn đăng ký nuôi trồng thủy sản đã ký">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </div>
                                
                                <div  *ngIf="RegisDoc?.FileName !== null">
                                    <a class="btn btn-success me-1" 
                                        title="Tải về Đơn đăng ký nuôi trồng thủy sản đã ký"
                                        target="_blank"
                                        [href]="RegisDoc.FileName">
                                        <i class="bi bi-cloud-download"></i>
                                    </a>
            
                                    <a *ngIf="isPDFFile(RegisDoc.FileName) || isWordOrExcelFile(RegisDoc.FileName) || isHtmlFile(RegisDoc.FileName)"
                                        class="btn btn-success me-1"
                                        title="Xem Đơn đăng ký nuôi trồng thủy sản đã ký"
                                        target="_blank"
                                        [href]="getViewerUrl(RegisDoc.FileName)">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-sm-12 col-12">
                        <span style="color: #548b0f;">Đẩy lên Giấy tờ đất có công chứng</span>

                        <div class="col-12" style="display: flex;">
                            <div style="display: flex;" class="col-lg-12 col-sm-12 col-12">
                                <a class="btn btn-success me-1" title="Đẩy lên Giấy tờ đất có công chứng đã ký" (click)="openFileInputQuyetDinh(85)">
                                    <i class="bi bi-cloud-upload"></i>
                                </a>
                                
                                <input #fileInput1 type="file" (change)="ChangeFileName1(85, $event.target.files)" style="display: none">
                                <div *ngIf="Doc1?.FileName === null">
                                    <a class="btn btn-secondary me-1" target="_blank" title="Tải về Giấy tờ đất có công chứng đã ký">
                                        <i class="bi bi-cloud-download"></i>
                                    </a>
                                    <a class="btn btn-secondary me-1" target="_blank" title="Xem Giấy tờ đất có công chứng đã ký">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </div>
                                
                                <div  *ngIf="Doc1?.FileName !== null">
                                    <a class="btn btn-success me-1" 
                                        title="Tải về Giấy tờ đất có công chứng đã ký"
                                        target="_blank"
                                        [href]="Doc1.FileName">
                                        <i class="bi bi-cloud-download"></i>
                                    </a>
            
                                    <a *ngIf="isPDFFile(Doc1.FileName) || isWordOrExcelFile(Doc1.FileName) || isHtmlFile(Doc1.FileName)"
                                        class="btn btn-success me-1"
                                        title="Xem Giấy tờ đất có công chứng đã ký"
                                        target="_blank"
                                        [href]="getViewerUrl(Doc1.FileName)">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-sm-12 col-12">
                        <span style="color: #548b0f;">Đẩy lên Sơ đồ vùng nuôi</span>

                        <div class="col-12" style="display: flex;">
                            <div style="display: flex;" class="col-lg-12 col-sm-12 col-12">
                                <a class="btn btn-success me-1" title="Đẩy lên Sơ đồ vùng nuôi đã ký" (click)="openFileInputQuyetDinh(86)">
                                    <i class="bi bi-cloud-upload"></i>
                                </a>
                                
                                <input #fileInput2 type="file" (change)="ChangeFileName1(86, $event.target.files)" style="display: none">
                                <div *ngIf="Doc2?.FileName === null">
                                    <a class="btn btn-secondary me-1" target="_blank" title="Tải về Sơ đồ vùng nuôi đã ký">
                                        <i class="bi bi-cloud-download"></i>
                                    </a>
                                    <a class="btn btn-secondary me-1" target="_blank" title="Xem Sơ đồ vùng nuôi đã ký">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </div>
                                
                                <div  *ngIf="Doc2?.FileName !== null">
                                    <a class="btn btn-success me-1" 
                                        title="Tải về Sơ đồ vùng nuôi đã ký"
                                        target="_blank"
                                        [href]="Doc2.FileName">
                                        <i class="bi bi-cloud-download"></i>
                                    </a>
            
                                    <a *ngIf="isPDFFile(Doc2.FileName) || isWordOrExcelFile(Doc2.FileName) || isHtmlFile(Doc2.FileName)"
                                        class="btn btn-success me-1"
                                        title="Xem Sơ đồ vùng nuôi đã ký"
                                        target="_blank"
                                        [href]="getViewerUrl(Doc2.FileName)">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!IsCompany" class="row gx-4" style="display: flex;">
                    <div class="col-lg-9 col-sm-12 col-12">
                    </div>
                    <div class="col-lg-3 col-sm-12 col-12">
                        <div class="col-lg-12 col-sm-12 col-12">
                            <span>Lý do trả hồ sơ</span>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <input name="LydoTrave" [(ngModel)]="PlanThamDinhService.FormData.DanhMucProductGroupName" placeholder="Lý do trả về"
                            type="text" class="form-control" />
                        </div>
                        <div style="display: flex;" class="col-lg-12 col-sm-12 col-12">
                            <a class="btn btn-success me-1" title="Đẩy lên file đính kèm" (click)="openFileInputQuyetDinh(555555)">
                                <i class="bi bi-cloud-upload"></i>
                            </a>
                            
                            <input #fileInputDinhKem type="file" (change)="ChangeFileName1(555555, $event.target.files)" style="display: none">
                            <div *ngIf="DinhKemDoc?.FileName === null">
                                <a class="btn btn-secondary me-1" target="_blank" title="Tải về file đính kèm">
                                    <i class="bi bi-cloud-download"></i>
                                </a>
                                <a class="btn btn-secondary me-1" target="_blank" title="Xem file đính kèm">
                                    <i class="bi bi-eye"></i>
                                </a>
                            </div>
                            
                            <div  *ngIf="DinhKemDoc?.FileName !== null">
                                <a class="btn btn-success me-1" 
                                    title="Tải về file đính kèm"
                                    target="_blank"
                                    [href]="DinhKemDoc.FileName">
                                    <i class="bi bi-cloud-download"></i>
                                </a>
        
                                <a *ngIf="isPDFFile(DinhKemDoc.FileName) || isWordOrExcelFile(DinhKemDoc.FileName) || isHtmlFile(DinhKemDoc.FileName)"
                                    class="btn btn-success me-1"
                                    title="Xem Đơn file đính kèm"
                                    target="_blank"
                                    [href]="getViewerUrl(DinhKemDoc.FileName)">
                                    <i class="bi bi-eye"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row gx-4" style="display: flex;">
                    <div *ngIf="!IsCompany" class="col-lg-6 col-sm-12 col-12">

                    </div>
                    <div *ngIf="IsCompany" class="col-lg-9 col-sm-12 col-12">

                    </div>
                    <div *ngIf="IsCompany" class="col-lg-3 col-sm-12 col-12">
                        <a style="width: 100%;" title="Gửi Hồ sơ cho cơ quan quản lý" class="btn btn-info" (click)="SendData()"><i
                                class="bi bi-sd-card"></i>Gửi Hồ sơ cho cơ quan quản lý</a>
                    </div>
                    <!-- <div *ngIf="IsCompany" class="col-lg-3 col-sm-12 col-12">
                        <a style="width: 100%;" title="Gửi Hồ sơ cho cơ quan quản lý" class="btn btn-info"><i
                                class="bi bi-sd-card"></i>Gửi Hồ sơ cho cơ quan quản lý</a>
                    </div> -->
                    <div *ngIf="!IsCompany" class="col-lg-3 col-sm-12 col-12">
                        <a style="width: 100%;" title="Gửi Hồ sơ cho cơ quan quản lý" class="btn btn-info" (click)="TiepNhan()">
                            <i class="bi bi-person-check"></i>Tiếp nhận</a>
                    </div>
                    <div *ngIf="!IsCompany" class="col-lg-3 col-sm-12 col-12">
                        <a style="width: 100%;" title="Gửi Hồ sơ cho cơ quan quản lý" class="btn btn-danger" (click)="TraVe()">
                            <i class="bi bi-person-dash"></i>Trả hồ sơ</a>
                    </div>
                </div>
<!--                 
                <div *ngIf="!IsCompany" class="row gx-4" style="display: flex;">
                    <div class="col-lg-9 col-sm-12 col-12">
                    </div>
                    <div class="col-lg-3 col-sm-12 col-12">
                        <input name="LydoTrave" [(ngModel)]="PlanThamDinhService.FormData.DanhMucProductGroupName" placeholder="Lý do trả về"
                        type="text" class="form-control" />
                    </div>
                </div> -->

                <h5 class="card-title text-danger">Bước 05: Đẩy lên file đã đã ký để hoàn thành</h5>
                <div class="row gx-4" style="display: flex;">
                    <div *ngIf="!IsCompany" class="col-lg-3 col-sm-12 col-12">
                        <a style="width: 100%;" title="Giấy xác nhận" (click)="PlanThamDinhCompanyDocumentAdd(84)"
                            class="btn btn-success"><i class="bi bi-cloud-download"></i> Giấy xác nhận</a>
    
                        <div class="col-12" style="display: flex;">
                            <div style="display: flex;" class="col-lg-12 col-sm-12 col-12">
                                <a class="btn btn-success me-1" title="Đẩy lên Giấy xác nhận đã ký" (click)="openFileInputQuyetDinh(84)">
                                    <i class="bi bi-cloud-upload"></i>
                                </a>
                                
                                <input #fileInputDecision type="file" (change)="ChangeFileName1(84, $event.target.files)" 
                                style="display: none">
                                <div *ngIf="DecisionDoc?.FileName === null">
                                    <a class="btn btn-secondary me-1" target="_blank" title="Tải về Giấy xác nhận đã ký">
                                        <i class="bi bi-cloud-download"></i>
                                    </a>
                                    <a class="btn btn-secondary me-1" target="_blank" title="Xem Giấy xác nhận đã ký">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </div>
                                
                                <div  *ngIf="DecisionDoc?.FileName !== null">
                                    <a class="btn btn-success me-1" 
                                        title="Tải về Giấy xác nhận đã ký"
                                        target="_blank"
                                        [href]="DecisionDoc.FileName">
                                        <i class="bi bi-cloud-download"></i>
                                    </a>
            
                                    <a *ngIf="isPDFFile(DecisionDoc.FileName) || isWordOrExcelFile(DecisionDoc.FileName) || isHtmlFile(DecisionDoc.FileName)"
                                        class="btn btn-success me-1"
                                        title="Xem Giấy xác nhận đã ký"
                                        target="_blank"
                                        [href]="getViewerUrl(DecisionDoc.FileName)">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!IsCompany" class="col-lg-3 col-sm-12 col-12">
                        <a style="width: 100%;" title="Giấy chứng nhận" (click)="PlanThamDinhCompanyDocumentAdd(87)"
                            class="btn btn-success"><i class="bi bi-cloud-download"></i> Giấy chứng nhận</a>
                        
                        <div class="col-12" style="display: flex;">
                            <div style="display: flex;" class="col-lg-12 col-sm-12 col-12">
                                <a class="btn btn-success me-1" title="Đẩy lên Giấy chứng nhận đã ký" (click)="openFileInputQuyetDinh(87)">
                                    <i class="bi bi-cloud-upload"></i>
                                </a>
    
                                <input #fileInputConfirm type="file" (change)="ChangeFileName1(87, $event.target.files)" style="display: none">
                                <div *ngIf="ConfirmDoc?.FileName === null">
                                    <a class="btn btn-secondary me-1" target="_blank" title="Tải về Giấy chứng nhận đã ký">
                                        <i class="bi bi-cloud-download"></i>
                                    </a>
                                    <a class="btn btn-secondary me-1" target="_blank" title="Xem Giấy chứng nhận đã ký">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </div>
                                
                                <div  *ngIf="ConfirmDoc?.FileName !== null">
                                    <a class="btn btn-success me-1" 
                                        title="Tải về Giấy chứng nhận đã ký"
                                        target="_blank"
                                        [href]="ConfirmDoc.FileName">
                                        <i class="bi bi-cloud-download"></i>
                                    </a>
            
                                    <a *ngIf="isPDFFile(ConfirmDoc.FileName) || isWordOrExcelFile(ConfirmDoc.FileName) || isHtmlFile(ConfirmDoc.FileName)"
                                        class="btn btn-success me-1"
                                        title="Xem Giấy chứng nhận đã ký"
                                        target="_blank"
                                        [href]="getViewerUrl(ConfirmDoc.FileName)">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!IsCompany" class="col-lg-3 col-sm-12 col-12">
                        <a style="width: 100%;" title="Quyết định" (click)="PlanThamDinhCompanyDocumentAdd(88)"
                            class="btn btn-success"><i class="bi bi-cloud-download"></i> Quyết định</a>
    
                        <div class="col-12" style="display: flex;">
                            <div style="display: flex;" class="col-lg-12 col-sm-12 col-12">
                                <a class="btn btn-success me-1" title="Đẩy lên Quyết định đã ký" (click)="openFileInputQuyetDinh(88)">
                                    <i class="bi bi-cloud-upload"></i>
                                </a>
                                
                                <input #fileInputPlan type="file" (change)="ChangeFileName1(88, $event.target.files)" style="display: none">
                                <div *ngIf="PlanDoc?.FileName === null">
                                    <a class="btn btn-secondary me-1" target="_blank" title="Tải về Quyết định đã ký">
                                        <i class="bi bi-cloud-download"></i>
                                    </a>
                                    <a class="btn btn-secondary me-1" target="_blank" title="Xem Quyết định đã ký">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </div>
                                
                                <div  *ngIf="PlanDoc?.FileName !== null">
                                    <a class="btn btn-success me-1" 
                                        title="Tải về Quyết định đã ký"
                                        target="_blank"
                                        [href]="PlanDoc.FileName">
                                        <i class="bi bi-cloud-download"></i>
                                    </a>
            
                                    <a *ngIf="isPDFFile(PlanDoc.FileName) || isWordOrExcelFile(PlanDoc.FileName) || isHtmlFile(PlanDoc.FileName)"
                                        class="btn btn-success me-1"
                                        title="Xem Quyết định đã ký"
                                        target="_blank"
                                        [href]="getViewerUrl(PlanDoc.FileName)">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!IsCompany" class="col-lg-3 col-sm-12 col-12">
                        <span style="color: #548b0f;">Đẩy lên Biên bản thẩm định đã ký</span>
                        <div class="col-12" style="display: flex;">
                            <div style="display: flex;" class="col-lg-12 col-sm-12 col-12">
                                <a class="btn btn-success me-1" title="Đẩy lên Biên bản thẩm định đã ký" (click)="openFileInputQuyetDinh(99)">
                                    <i class="bi bi-cloud-upload"></i>
                                </a>
                                
                                <input #fileInput3 type="file" (change)="ChangeFileName1(99, $event.target.files)" style="display: none">
                                <div *ngIf="Doc3?.FileName === null">
                                    <a class="btn btn-secondary me-1" target="_blank" title="Tải về Biên bản thẩm định đã ký">
                                        <i class="bi bi-cloud-download"></i>
                                    </a>
                                    <a class="btn btn-secondary me-1" target="_blank" title="Xem Biên bản thẩm định đã ký">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </div>
                                
                                <div  *ngIf="Doc3?.FileName !== null">
                                    <a class="btn btn-success me-1" 
                                        title="Tải về Biên bản thẩm định đã ký"
                                        target="_blank"
                                        [href]="Doc3.FileName">
                                        <i class="bi bi-cloud-download"></i>
                                    </a>
            
                                    <a *ngIf="isPDFFile(Doc3.FileName) || isWordOrExcelFile(Doc3.FileName) || isHtmlFile(Doc3.FileName)"
                                        class="btn btn-success me-1"
                                        title="Xem Biên bản thẩm định đã ký"
                                        target="_blank"
                                        [href]="getViewerUrl(Doc3.FileName)">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="PlanThamDinhService.IsShowLoading"></app-loading>