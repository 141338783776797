<div class="app-body">
  <div class="row">
    <div class="col-xxl-12">
      <div class="card" style="margin-left: 1px">
        <div class="card-body">
          <div class="custom-tabs-container">
            <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link active" id="tab-RegisterHarvest" data-bs-toggle="tab" href="#RegisterHarvest"
                  role="tab" aria-controls="RegisterHarvest" aria-selected="true">
                  <span class="badge bg-info" style="font-size: 16px">Thu hoạch Nhuyễn thể 02 mảnh vỏ</span>
                </a>
              </li>
            </ul>
            <div class="tab-content" id="customTabContent">
              <div class="tab-pane fade show active" id="RegisterHarvest" role="tabpanel">
                <div class="row gx-4">
                  <div class="col-lg-10 col-sm-12 col-12">
                    <input [(ngModel)]="RegisterHarvestService.BaseParameter.SearchString" placeholder="Tìm ..."
                      class="form-control" (keyup.enter)="RegisterHarvestSearch()" />
                  </div>
                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="row gx-4">
                        <div class="col-lg-6 col-sm-6 col-6">
                            <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                (click)="RegisterHarvestSearch()"><i class="bi bi-search"></i></a>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-6">
                            <a style="width: 100%;" class="btn btn-success" title="Thêm mới"
                                (click)="RegisterHarvestAdd(0)"><i class="bi bi-plus"></i></a>
                        </div>
                    </div>
                </div>
                </div>
                <div class="card mb-4 card-info">
                  <div class="card-header">
                    <h5 class="card-title text-white">
                      Danh sách
                      <span *ngIf="RegisterHarvestService.List">({{ RegisterHarvestService.List.length }} items)</span>
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="table-outer">
                      <div class="table-responsive">
                        <table *ngIf="!NotificationService.IsMobile" mat-table matSort #RegisterHarvestSort="matSort"
                          [dataSource]="RegisterHarvestService.DataSource" class="table table-striped m-0">
                          <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              STT
                            </th>
                            <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                              <div *ngIf="RegisterHarvestPaginator">
                                {{RegisterHarvestPaginator.pageSize * RegisterHarvestPaginator.pageIndex + i + 1}}
                              </div>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="ID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              ID
                            </th>
                            <td mat-cell *matCellDef="let element">
                              {{element.ID}}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="NgayBatDau">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Bắt đầu
                            </th>
                            <td mat-cell *matCellDef="let element" style="text-align: right;">
                              {{element.NgayBatDau
                              | date:'dd/MM/yyyy'}}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="NgayKetThuc">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Kết thúc
                            </th>
                            <td mat-cell *matCellDef="let element" style="text-align: right;">
                              {{element.NgayKetThuc
                              | date:'dd/MM/yyyy'}}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="CompanyInfoName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Đơn vị Tổ chức
                            </th>
                            <td mat-cell *matCellDef="let element">
                              <a style="cursor: pointer;" title="{{element.CompanyInfoName}}" class="link-primary"
                                (click)="CompanyInfoAdd(element.CompanyInfoID)"><b>{{element.CompanyInfoName}}</b></a>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="DanhMucLayMauName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Giống
                            </th>
                            <td mat-cell *matCellDef="let element">
                              <a style="cursor: pointer" title="{{element.DanhMucLayMauName}}" class="link-primary"
                                (click)="RegisterHarvestAdd(element.ID)"><b>{{element.DanhMucLayMauName}}</b></a>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="Save">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                            <td mat-cell *matCellDef="let element">
                              <a *ngIf="element.ID > 0"
                                  class="btn btn-success me-1"
                                  title="Xem"
                                  (click)="RegisterHarvestAdd(element.ID)">
                                  <i class="bi bi-eye"></i>
                              </a>
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="RegisterHarvestService.DisplayColumns004"></tr>
                          <tr mat-row *matRowDef="let row;columns: RegisterHarvestService.DisplayColumns004"></tr>
                        </table>
                        <mat-paginator *ngIf="!NotificationService.IsMobile" #RegisterHarvestPaginator="matPaginator"
                          [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                          [showFirstLastButtons]></mat-paginator>

                        <table *ngIf="NotificationService.IsMobile" mat-table matSort #RegisterHarvestSort="matSort"
                          [dataSource]="RegisterHarvestService.DataSource" class="table table-striped m-0">
                          <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Danh sách
                            </th>
                            <td mat-cell *matCellDef="let element; index as i">

                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="RegisterHarvestService.DisplayColumnsMobile"></tr>
                          <tr mat-row *matRowDef="let row; columns: RegisterHarvestService.DisplayColumnsMobile"></tr>
                        </table>
                        <mat-paginator *ngIf="NotificationService.IsMobile" #RegisterHarvestPaginator="matPaginator"
                          [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                          [showFirstLastButtons]></mat-paginator>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="RegisterHarvestService.IsShowLoading"></app-loading>