export const environment = {
  production: false,
  DomainURL: "",
  DomainDestination: "",
  APIURL: "https://attpnnTT17.bentre.gov.vn/api/v1/",
  APIRootURL: "https://attpnnTT17.bentre.gov.vn/",
  APIUploadURL: "https://attpnntuongtac.bentre.gov.vn/api/v1/",
  APIUploadRootURL: "https://attpnntuongtac.bentre.gov.vn/",
  LoginURL: "https://attpnnxacthuc.bentre.gov.vn/",
  Website: "https://attpnnchinhsach.bentre.gov.vn/",
  Image: "Image",
  Company: "Company",
  Membership: "Membership",
  Barcode: "Barcode",
  QRcode: "QRcode",
  OrderDelivery: "OrderDelivery",
  MapIcon: "https://bando.vungtrong.com/Image/HTX4_30.png",
  Download: "Download",
  IPRegistry: "https://ipv4.myexternalip.com/json",
  LoadingFile: "loading.gif",
  DialogConfigWidth: "80%",
  DialogConfigWidth60: "60%",
  InitializationString: "",
  InitializationNumber: 0,
  PageSize: 10,
  DocumentTemplateIDATTPBienBanThamDinh: 37,
  BienBanATTPParentID: 1,
  ProvinceDataIDBenTre: 53,
  CoQuanQuanLyID: 1,
  CompanyInfoID: 33,
  DanhMucATTPTinhTrangID: 1,
  DanhMucATTPTinhTrangIDDoiThamDinh: 2,
  DanhMucATTPTinhTrangIDTraHuyHoSo: 6,
  DanhMucATTPTinhTrangIDDaPheDuyet: 10,
  ThanhVienIDNumber: 80,
  PlanTypeIDCoSoNuoi: 11,
  PlanTypeIDDangKyATTP: 9,
  PlanTypeIDThamDinhATTP: 3,
  PlanTypeIDChuoiCungUng: 7,
  PlanTypeIDChuoiCungUngKeHoachLayMau: 16,
  PlanTypeIDGiamSatDuLuong: 1,
  PlanTypeIDAnToanThucPhamSauThuHoach: 4,
  PlanTypeIDCamKet17: 12,
  PlanTypeIDNhuyenTheHaiManhVo: 2,
  PlanTypeIDTuCongBoSanPham: 13,
  PlanTypeIDThanhTraChuyenNganh: 8,
  PlanTypeIDKiemTraTapChat: 5,
  PlanTypeIDDangKyMaDongGoi: 10,
  PlanTypeIDDangKyMaVungTrong: 6,
  DanhMucSanPhamID: 890,
  DanhMucDuAnID: 1,
  ThanhVienQuanTriID: 3,
  ThanhVienCoQuanQuanLyID: 2,
  ThanhVienChuyenGiaID: 4,
  ThanhVienNongDanID: 1,
  DanhMucThanhVienIDKhachMoi: 6,
  DanhMucThanhVienIDNhanVien: 5,
  DanhMucTinhThanhID: 58,
  MapZoom: 8,
  Latitude: 10.235012,
  Longitude: 106.3797368,
  StateAgencyIDChiCucQLCLNongLamThuySan: 1,
  StateAgencyIDChiCucBaoVeThucVat: 2,
  StateAgencyIDChiCucThuySan: 3,
  ProductUnitIDTan: 8,
  DanhMucQuocGiaIDVietNam: 1,
  Bearer: "Bearer null",
  PlanThamDinh: "PlanThamDinh",
  MaptilerAPIKey: "6iFTqps4QVACLERa26MA",
  TokenDefault: "d85df3cb-5688-4b1d-b120-50479b23d2a0",
  Token: "Token",
  TokenFCM: "TokenFCM",
  ThanhVienCompanyInfoID: "ThanhVienCompanyInfoID",
  ThanhVienParentID: "ThanhVienParentID",
  ThanhVienID: "ThanhVienID",
  ThanhVienHoTen: "ThanhVienHoTen",
  ThanhVienTaiKhoan: "ThanhVienTaiKhoan",
  ThanhVienFileName: "ThanhVienFileName",
  ZTong: "Z.Tổng",
  HTMLExtension: ".html",
  Homepage: "Homepage",
  Login: "Login",
  UploadSuccess: "Upload thành công.",
  UploadNotSuccess: "Upload không thành công.",
  SaveSuccess: "Lưu thành công.",
  SaveNotSuccess: "Lưu không thành công.",
  DeleteConfirm: "Bạn muốn xóa dữ liệu này?",
  DeleteSuccess: "Xóa thành công.",
  DeleteNotSuccess: "Xóa không thành công.",
  LoginNotSuccess: "Đăng nhập không thành công.",
  UserNameExists: "Tài khoản đã tồn tại.",
  UserNameRequired: "UserName là bắt buộc.",
  HTX0001: "Hợp tác xã chưa có dữ liệu Năng lực sản xuất.",
  XacNhanDangKy: "Xác nhận gửi đăng ký.",
  XacNhanTiepNhan: "Xác nhận tiếp nhận hồ sơ.",
  XacNhanTraHoSo: "Xác nhận trả lại hồ sơ.",
  XacNhanPheDuyetHoSo: "Xác nhận phê duyệt hồ sơ.",
  TruyXuatNguonGocAPIURL: "https://api.cms.cft.carbonunit.net/api/v1/",
  TruyXuatNguonGocAPIRootURL: "https://api.cms.cft.carbonunit.net/",
  TruyXuatNguonGocAPIUploadURL: "https://api.upload.cft.carbonunit.net/api/v1/",
  TruyXuatNguonGocAPIUploadRootURL: "https://api.upload.cft.carbonunit.net/",
  TruyXuatNguonGocDanhMucUngDungToKen: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJKV1RTZXJ2aWNlQWNjZXNzVG9rZW4iLCJqdGkiOiJjODVlY2E3OC0yYTFlLTRmN2YtOWY3OC1lMzUwOGU0YzVkMjUiLCJpYXQiOiI2LzExLzIwMjQgMTA6MDQ6MTMgQU0iLCJVc2VySWQiOiIxIiwiRGlzcGxheU5hbWUiOiJER0siLCJVc2VyTmFtZSI6ImRpZ2l0YWxraW5nZG9tcGx1c0BnbWFpbC5jb20iLCJFbWFpbCI6ImRpZ2l0YWxraW5nZG9tcGx1c0BnbWFpbC5jb20iLCJleHAiOjE3MjA2OTIyNTMsImlzcyI6IkpXVEF1dGhlbnRpY2F0aW9uU2VydmVyIiwiYXVkIjoiSldUU2VydmljZVBvc3RtYW5DbGllbnQifQ.rs2VV1G1UibNF5W6ZDXs_bk4d10ycGKMESgRb2IEUgc",
  TruyXuatNguonGocDanhMucUngDungID: "TruyXuatNguonGocDanhMucUngDungID",
  TruyXuatNguonGocToKen: "TruyXuatNguonGocToKen",
  TruyXuatNguonGocMaSo: "00000000",
  TruyXuatNguonGocToChucExists: "Tổ chức đã tồn tại.",
  KhongTimThay: "Không tìm thấy dữ liệu.",
  BieuMauBBThamDinh: "Biên bản thẩm định",
  SentMailSuccess: "Gửi mail thành công.",
  SentMailNotSuccess: "Gửi mail thất bại.",
  ShowResult: true,
  QDLayMauDuLuongThuySanDoc: 43, //QUYẾT ĐỊNH Về việc lấy mẫu giám sát dư lượng thủy sản
  MauGiaoNhanDoc: 56, //TỔNG HỢP THÔNG TIN MẪU GIAO NHẬN
  NamBatDau: 2013,
  NamKeThuc: 2030,
  DMStatusSend:11,
  DMStatusTemp:12,
  DMStatusSuccess:13,
  DMStatusTiepNhan:1,
  DMStatusTraVe:6,
  BMGiamSat:"Biên bản giám sát vùng trồng",
  BMKiemTra:"Biên bản kiểm tra vùng trồng",
  BMGiamSatCSDG:"Biên bản giám sát cơ sở đóng gói",
  BMKiemTraCSDG:"Biên bản kiểm tra cơ sở đóng gói",
  BMCacGiaytoKhac:"Các giấy tờ chứng nhận khác (cơ quan nhà nước)",
  HSDaGuiCQQL:11,
  VutrongCoQuanQuanLyKiemTraThucTe: 14,
  DangKyChuyenChuThe:"Đăng ký chuyển chủ thể MSVT",
  HienThiLyDo:["Đăng ký chuyển chủ thể MSVT", "Không đạt",'Trả/Hủy hồ sơ','Thu hồi','Đình chỉ']
};
