<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-PlanThamDinhDetailAnToanThucPhamSauThuHoach" data-bs-toggle="tab"
                href="#PlanThamDinhDetailAnToanThucPhamSauThuHoach" role="tab"
                aria-controls="PlanThamDinhDetailAnToanThucPhamSauThuHoach" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">An toàn thực phẩm sau thu hoạch [{{PlanThamDinhService.FormData}}]</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="PlanThamDinhDetailAnToanThucPhamSauThuHoach" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <h4>Bước 01: Nhập Thông tin</h4>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Nội dung</label>
                                <input name="Name" [(ngModel)]="PlanThamDinhService.FormData.Name"
                                    placeholder="Nội dung" type="text" class="form-control" />
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Năm</label>
                                <input name="Nam" [(ngModel)]="PlanThamDinhService.FormData.Nam" placeholder="0"
                                    type="number" class="form-control" style="text-align: right;" />
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Đợt</label>
                                <input name="SoDot" [(ngModel)]="PlanThamDinhService.FormData.SoDot" placeholder="0"
                                    type="number" class="form-control" style="text-align: right;" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ngày bắt đầu</label>
                                <input [ngModel]="PlanThamDinhService.FormData.NgayBatDau | date:'yyyy-MM-dd'"
                                    (ngModelChange)="DateNgayBatDau($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                                    type="date" class="form-control" autocomplete="off">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ngày kết thúc</label>
                                <input [ngModel]="PlanThamDinhService.FormData.NgayKetThuc | date:'yyyy-MM-dd'"
                                    (ngModelChange)="DateNgayKetThuc($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                                    type="date" class="form-control" autocomplete="off">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ngày gửi mẫu</label>
                                <input [ngModel]="PlanThamDinhService.FormData.NgayGuiMau | date:'yyyy-MM-dd'"
                                    (ngModelChange)="DateNgayGuiMau($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                                    type="date" class="form-control" autocomplete="off">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="row gx-4">
                        <div class="col-lg-4 col-sm-12 col-12 row gx-4">
                            <a style="width: 100%; height: min-content;" title="Lưu thay đổi" (click)="PlanThamDinhSave()" class="btn btn-info"><i
                                    class="bi bi-sd-card"></i> Lưu thay
                                đổi</a>
                        </div>
                        <div class="col-lg-4 col-sm-12 col-12">
                            <a style="width: 100%;" title="Thêm Mẫu xét nghiệm" (click)="DanhMucLayMauAdd(0)"
                                class="btn btn-success"><i class="bi bi-plus"></i> Thêm Mẫu xét nghiệm (Nếu
                                thiếu)</a>
                        </div>
                        <div class="col-lg-4 col-sm-12 col-12">
                            <a style="width: 100%;" title="Thêm Chỉ tiêu" (click)="DanhMucLayMauChiTieuAdd(0)"
                                class="btn btn-success"><i class="bi bi-plus"></i> Thêm Chỉ tiêu (Nếu thiếu)</a>
                        </div>
                    </div>
                    <div class="card mb-4 card-success">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bước 02: Tạo kế hoạch </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhDanhMucLayMauSort="matSort"
                                        [dataSource]="PlanThamDinhDanhMucLayMauService.DataSource"
                                        class="table table-striped m-0">
                                        
                                        <!-- Cột Khu vực -->
                                        <ng-container matColumnDef="DistrictDataID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 234px;">Khu vực</th>
                                            <td mat-cell *matCellDef="let element">
                                                <select class="form-select" name="DistrictDataID{{element.ID}}" [(ngModel)]="element.DistrictDataID" [disabled]="element.ID>0">
                                                    <option [value]=""></option>
                                                    <option *ngFor="let item of DistrictDataService.List" [value]="item.ID">
                                                        {{ item.Name }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef></td>
                                        </ng-container>
                                    
                                        <!-- Cột Phân loại -->
                                        <ng-container matColumnDef="DanhMucLayMauPhanLoaiID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 208px;">Phân loại</th>
                                            <td mat-cell *matCellDef="let element">
                                                <select class="form-select" name="DanhMucLayMauPhanLoaiID{{element.ID}}" [(ngModel)]="element.DanhMucLayMauPhanLoaiID" [disabled]="element.ID>0">
                                                    <option [value]=""></option>
                                                    <option *ngFor="let item of DanhMucLayMauPhanLoaiService.List" [value]="item.ID">
                                                        {{ item.Name }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef></td>
                                        </ng-container>
                                    
                                        <!-- Cột Tên Mẫu -->
                                        <ng-container matColumnDef="DanhMucLayMauID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tên Mẫu</th>
                                            <td mat-cell *matCellDef="let element">
                                                <select *ngIf="element.ID == 0" class="form-select" name="DanhMucLayMauID{{element.ID}}" [(ngModel)]="element.DanhMucLayMauID">
                                                    <option [value]=""></option>
                                                    <option *ngFor="let item of DanhMucLayMauService.List" [value]="item.ID">
                                                        {{ item.Name }}
                                                    </option>
                                                </select>
                                                <div *ngIf="element.ID > 0">
                                                    <b>{{ element.DanhMucLayMauName }}</b>
                                                </div>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef style="color: #000000;
                                                font-weight: bold;
                                                font-size: 16px;
                                                text-transform: uppercase;
                                                padding: 10px;
                                                padding-top: 0px;
                                                padding-bottom: 0px;
                                                white-space: nowrap;
                                                text-align: right;
                                                padding-right: 25px;">
                                                Tổng
                                            </td>
                                        </ng-container>
                                    
                                        <!-- Cột Số lượng (mẫu) -->
                                        <ng-container matColumnDef="SoLuongLayMau">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tổng số lượng (mẫu)</th>
                                            <td mat-cell *matCellDef="let element" style="text-align: right;">
                                                <input name="SoLuongLayMau" [(ngModel)]="element.SoLuongLayMau" placeholder="0" type="number" class="form-control" style="text-align: right;" [disabled]="element.ID>0"/>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef style="text-align: right; padding-right: 25px;">
                                                {{ getTotal('SoLuongLayMau') }}
                                            </td>
                                        </ng-container>
                                    
                                        <!-- Cột Chỉ tiêu phân tích -->
                                        <ng-container matColumnDef="DanhMucLayMauChiTieuID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ tiêu phân tích</th>
                                            <td mat-cell *matCellDef="let element">
                                                <select *ngIf="element.ID == 0" class="form-select" name="DanhMucLayMauChiTieuID{{element.ID}}" [(ngModel)]="element.DanhMucLayMauChiTieuID" [disabled]="element.ID>0">
                                                    <option [value]=""></option>
                                                    <option *ngFor="let item of DanhMucLayMauChiTieuService.List" [value]="item.ID">
                                                        {{ item.Name }}
                                                    </option>
                                                </select>
                                                <div *ngIf="element.ID > 0">
                                                    <b>{{ element.DanhMucLayMauChiTieuName }}</b>
                                                </div>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef></td>
                                        </ng-container>
                                    
                                        <!-- Cột Số lần phân tích -->
                                        <ng-container matColumnDef="ChiTieuPhanTichSL">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Số lần phân tích</th>
                                            <td mat-cell *matCellDef="let element" style="text-align: right;">
                                                <input name="ChiTieuPhanTich" [(ngModel)]="element.SoLuongChiTieu" placeholder="0" type="number" class="form-control" style="text-align: right;" [disabled]="element.ID>0"/>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef style="text-align: right; padding-right: 25px;">
                                                {{ getTotal('SoLuongChiTieu') }}
                                            </td>
                                        </ng-container>
                                    
                                        <!-- Cột Ngày lấy mẫu -->
                                        <ng-container matColumnDef="NgayGhiNhan">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày lấy mẫu</th>
                                            <td mat-cell *matCellDef="let element">
                                                <input name="NgayGhiNhan{{element.ID}}" [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'" (ngModelChange)="DatePlanThamDinhDanhMucLayMauNgayGhiNhan(element, $event)" [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off" [disabled]="element.ID>0"/>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef></td>
                                        </ng-container>
                                    
                                        <!-- Cột Lưu -->
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef></th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info" (click)="PlanThamDinhDanhMucLayMauSave(element)">
                                                    <i class="bi bi-sd-card"></i>
                                                </a>
                                                &nbsp;&nbsp;
                                                <a *ngIf="element.ID>0" class="btn btn-success" title="Copy"
                                                    (click)="CopyKeHoach(element)"><i
                                                        class="bi bi-subtract"></i></a>
                                                &nbsp;&nbsp;
                                                <a class="btn btn-danger" (click)="PlanThamDinhDanhMucLayMauDelete(element)" *ngIf="element.ID > 0">
                                                    <i class="bi bi-trash"></i>
                                                </a>
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef></td>
                                        </ng-container>
                                    
                                        <!-- Header row -->
                                        <tr mat-header-row *matHeaderRowDef="PlanThamDinhDanhMucLayMauService.DisplayColumns015"></tr>
                                        <!-- Data rows -->
                                        <tr mat-row *matRowDef="let row; columns: PlanThamDinhDanhMucLayMauService.DisplayColumns015;"></tr>
                                        <!-- Footer row -->
                                        <tr mat-footer-row *matFooterRowDef="PlanThamDinhDanhMucLayMauService.DisplayColumns015"></tr>
                                    </table>
                                
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #PlanThamDinhDanhMucLayMauPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhDanhMucLayMauSort="matSort"
                                        [dataSource]="PlanThamDinhDanhMucLayMauService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhDanhMucLayMauService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhDanhMucLayMauService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #PlanThamDinhDanhMucLayMauPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-4 col-sm-12 col-12">
                        <a style="width: 100%;" class="btn btn-success" title="Thêm mới" (click)="ThanhVienAdd(0)"><i
                                class="bi bi-plus"></i> Thêm thành viên (nếu thiếu)</a>
                    </div>
                    <br />
                    <div class="card mb-4 card-danger">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bước 03: Chọn thành viên tham gia Đoàn công tác
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhThanhVienSort="matSort"
                                        [dataSource]="PlanThamDinhThanhVienService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="ThanhVienID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cán bộ đơn vị + Khách
                                                mời
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <select *ngIf="element.ID==0" class="form-select"
                                                    name="element.ThanhVienID" [(ngModel)]="element.ThanhVienID">
                                                    <option *ngFor="let item of ThanhVienService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}} ({{item.AgencyDepartmentName}} -
                                                        {{item.StateAgencyName}})</option>
                                                </select>
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.ThanhVienName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DanhMucChucDanhID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chức danh
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <select *ngIf="element.ID==0" class="form-select"
                                                    name="element.DanhMucChucDanhID"
                                                    [(ngModel)]="element.DanhMucChucDanhID">
                                                    <option *ngFor="let item of DanhMucChucDanhService.List;"
                                                        [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.DanhMucChucDanhName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info" (click)="PlanThamDinhThanhVienSave(element)"
                                                    *ngIf="element.ID==0"><i class="bi bi-sd-card"></i>
                                                </a>
                                                <a class="btn btn-danger" (click)="PlanThamDinhThanhVienDelete(element)"
                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhThanhVienService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhThanhVienService.DisplayColumns001;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #PlanThamDinhThanhVienPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhThanhVienSort="matSort"
                                        [dataSource]="PlanThamDinhThanhVienService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Cán bộ: </label>
                                                        <select *ngIf="element.ID==0" class="form-select"
                                                            name="element.ThanhVienID"
                                                            [(ngModel)]="element.ThanhVienID">
                                                            <option *ngFor="let item of ThanhVienService.List;"
                                                                [value]="item.ID">
                                                                {{item.Name}}</option>
                                                        </select>
                                                        <label class="form-label"
                                                            *ngIf="element.ID>0">{{element.ThanhVienName}}</label>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Chức danh: </label>
                                                        <select *ngIf="element.ID==0" class="form-select"
                                                            name="element.DanhMucChucDanhID"
                                                            [(ngModel)]="element.DanhMucChucDanhID">
                                                            <option *ngFor="let item of DanhMucChucDanhService.List;"
                                                                [value]="item.ID">
                                                                {{item.Name}}</option>
                                                        </select>
                                                        <label class="form-label"
                                                            *ngIf="element.ID>0">{{element.DanhMucChucDanhName}}</label>
                                                    </div>
                                                    <div class="row gx-4">
                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                            <a class="btn btn-info" style="width: 100%"
                                                                (click)="PlanThamDinhThanhVienSave(element)"
                                                                *ngIf="element.ID==0"><i class="bi bi-sd-card"></i>
                                                            </a>
                                                            <a class="btn btn-danger" style="width: 100%;"
                                                                (click)="PlanThamDinhThanhVienDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </div>
                                                        <br />
                                                        <br />
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhThanhVienService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhThanhVienService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #PlanThamDinhThanhVienPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="display: flex; justify-content: flex-end;">
                    <div class="col-lg-4 col-sm-12 col-12 row gx-4">
                        <a style="width: 100%; height: min-content;" title="Lưu thay đổi" (click)="PlanThamDinhSave()" class="btn btn-info"><i
                                class="bi bi-sd-card"></i> Lưu thay
                            đổi</a>
                    </div>
                    <div class="col-lg-4 col-sm-12 col-12">
                        <a title="Tải về Quyết định" (click)="PlanThamDinhCompanyDocumentAdd(60)"
                            class="btn btn-success" style="width: 100%;"><i class="bi bi-cloud-download"></i> Quyết định</a>

                        <div class="col-12" style="display: flex;">
                            <div style="display: flex;" class="col-lg-4 col-sm-12 col-12">
                                <a class="btn btn-success me-1" title="Đẩy lên Quyết định đã ký" (click)="openFileInputQuyetDinh(60)">
                                    <i class="bi bi-cloud-upload"></i>
                                </a>
                                <input #fileInputDecision type="file" (change)="ChangeFileName1(60, $event.target.files)" style="display: none">
                                <div *ngIf="DecisionDoc?.FileName === null">
                                    <a class="btn btn-secondary me-1" target="_blank" title="Tải về Quyết định đã ký">
                                        <i class="bi bi-cloud-download"></i>
                                    </a>
                                    <a class="btn btn-secondary me-1" target="_blank" title="Xem Quyết định đã ký">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </div>
                                
                                <div  *ngIf="DecisionDoc?.FileName !== null">
                                    <a class="btn btn-success me-1" 
                                        title="Tải về Quyết định đã ký"
                                        target="_blank"
                                        [href]="DecisionDoc.FileName">
                                        <i class="bi bi-cloud-download"></i>
                                    </a>
            
                                    <a *ngIf="isPDFFile(DecisionDoc.FileName) || isWordOrExcelFile(DecisionDoc.FileName) || isHtmlFile(DecisionDoc.FileName)"
                                        class="btn btn-success me-1"
                                        title="Xem Quyết định đã ký"
                                        target="_blank"
                                        [href]="getViewerUrl(DecisionDoc.FileName)">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-4 col-sm-12 col-12">
                        <a style="width: 100%;" title="Tải về Mẫu giao nhận" (click)="PlanThamDinhCompanyDocumentAdd(61)"
                            class="btn btn-success"><i class="bi bi-cloud-download"></i> Kế hoạch</a>

                            
                        <div class="col-12" style="display: flex;">
                            <div style="display: flex;" class="col-lg-4 col-sm-12 col-12">
                                <a class="btn btn-success me-1" title="Đẩy lên Quyết định đã ký" (click)="openFileInputQuyetDinh(61)">
                                    <i class="bi bi-cloud-upload"></i>
                                </a>
                                <input #fileInputPlan type="file" (change)="ChangeFileName1(61, $event.target.files)" style="display: none">
                                <div *ngIf="PlanDoc?.FileName === null">
                                    <a class="btn btn-secondary me-1" target="_blank" title="Tải về Quyết định đã ký">
                                        <i class="bi bi-cloud-download"></i>
                                    </a>
                                    <a class="btn btn-secondary me-1" target="_blank" title="Xem Quyết định đã ký">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </div>
                                
                                <div  *ngIf="PlanDoc?.FileName !== null">
                                    <a class="btn btn-success me-1" 
                                        title="Tải về Quyết định đã ký"
                                        target="_blank"
                                        [href]="PlanDoc.FileName">
                                        <i class="bi bi-cloud-download"></i>
                                    </a>
            
                                    <a *ngIf="isPDFFile(PlanDoc.FileName) || isWordOrExcelFile(PlanDoc.FileName) || isHtmlFile(PlanDoc.FileName)"
                                        class="btn btn-success me-1"
                                        title="Xem Quyết định đã ký"
                                        target="_blank"
                                        [href]="getViewerUrl(PlanDoc.FileName)">
                                        <i class="bi bi-eye"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-warning">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bước 04: Chọn Đơn vị Tổ chức
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhDanhMucLayMauSort002="matSort"
                                        [dataSource]="PlanThamDinhDanhMucLayMauService.DataSource002"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                
                                                <a *ngIf="element.SortOrder == -1" class="btn btn-info" title="Lưu"
                                                    (click)="SaveCompany(element)"><i class="bi bi-sd-card"></i>
                                                </a>
                                                <!-- <a class="btn btn-success" title="Copy"
                                                    (click)="PlanThamDinhDanhMucLayMauCopy(element)"><i
                                                        class="bi bi-subtract"></i> Copy</a> -->
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Active">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Mẫu nhiễm
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DistrictDataID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Khu vực
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div *ngIf="element.ID>0 && element.SortOrder == -1">
                                                    <b>{{element.DistrictDataName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DanhMucLayMauID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Mẫu xét nghiệm
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div *ngIf="element.ID>0 && element.SortOrder == -1">
                                                    <b>{{element.DanhMucLayMauName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DanhMucLayMauChiTieuID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chỉ tiêu
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div *ngIf="element.ID>0">
                                                    <b>{{element.DanhMucLayMauChiTieuName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="CompanyInfoID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 350px;">Đơn vị Tổ chức
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input *ngIf="element.SortOrder == -1" placeholder="Tìm..." class="form-control"
                                                    (keyup)="CompanyInfoFilter($event.target.value, element);">
                                                <mat-select *ngIf="element.SortOrder == -1" class="form-control"
                                                    name="element.CompanyInfoID"
                                                    [(ngModel)]="element.CompanyInfoID"
                                                    (selectionChange)="CompanyInfoChange(element)">
                                                    <mat-option *ngFor="let item of element.ListCompanyInfo" [value]="item.ID">
                                                        <span *ngIf="item.Name">{{item.Name}} </span><span *ngIf="item.ProductGroupName!=null && item.ProductGroupName!=''" > - {{item.ProductGroupName}}</span>
                                                    </mat-option>
                                                </mat-select>
                                                <div *ngIf="element.SortOrder != -1" >
                                                    <b>{{element.CompanyInfoName}}</b>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="TypeName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 135px;">Mã mẫu
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="text-align: right;">
                                                <input name="TypeName" 
                                                [(ngModel)]="element.TypeName" 
                                                placeholder="Mã mẫu" type="text" 
                                                class="form-control"/>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="TenMau">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 135px;">Tên mẫu thực tế
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="text-align: right;">
                                                <input name="TenMau" 
                                                [(ngModel)]="element.TenMau" 
                                                placeholder="Tên mẫu thực tế" type="text" 
                                                class="form-control"/>
                                            </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="GioiHanToiDa">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Giới hạn tối đa
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="text-align: right;">
                                                <input name="GioiHanToiDa" 
                                                [(ngModel)]="element.GioiHanToiDa" 
                                                placeholder="0" type="number" 
                                                class="form-control" 
                                                style="text-align: right;"/>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="KetQuaPhanTich">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Kết quả phân tích
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="text-align: right;">
                                                <input name="KetQuaPhanTich" 
                                                [(ngModel)]="element.KetQuaPhanTich" 
                                                placeholder="Kết quả phân tích" type="text" 
                                                class="form-control" 
                                                style="text-align: right;"/>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Note">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị tính
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="text-align: right;">
                                                
                                                <input name="Note" 
                                                [(ngModel)]="element.Note" 
                                                placeholder="Đơn vị tính" type="text" 
                                                class="form-control" 
                                                style="text-align: right;"/>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="NgayGhiNhan">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="text-align: right;">
                                                <input name="NgayGhiNhan{{element.ID}}"
                                                [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                autocomplete="off">
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhDanhMucLayMauService.DisplayColumns016">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhDanhMucLayMauService.DisplayColumns016;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #PlanThamDinhDanhMucLayMauPaginator002="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #PlanThamDinhDanhMucLayMauSort002="matSort"
                                        [dataSource]="PlanThamDinhDanhMucLayMauService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="PlanThamDinhDanhMucLayMauService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: PlanThamDinhDanhMucLayMauService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #PlanThamDinhDanhMucLayMauPaginator002="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="display: flex; justify-content: flex-end;">
                        <div class="col-lg-8 col-sm-12 col-12 row gx-4">
                        </div>
                        <div class="col-lg-4 col-sm-12 col-12">
                            <a style="width: 100%;" title="Tải về Mẫu giao nhận" (click)="PlanThamDinhCompanyDocumentAdd(62)"
                                class="btn btn-success"><i class="bi bi-cloud-download"></i> Thông báo nhiễm</a>
                        </div>
                    </div>
                    
                    <div class="col-12" style="display: flex; justify-content: flex-end;">
                        <div style="display: flex;" class="col-lg-4 col-sm-12 col-12">
                            <a class="btn btn-success me-1" title="Đẩy lên Thông báo đã ký" (click)="openFileInputQuyetDinh(62)">
                                <i class="bi bi-cloud-upload"></i>
                            </a>
                            <input #fileInputPoisoningNotice type="file" (change)="ChangeFileName1(62, $event.target.files)" style="display: none">
                            <div *ngIf="PoisoningNoticeDoc?.FileName === null">
                                <a class="btn btn-secondary me-1" target="_blank" title="Tải về Thông báo đã ký">
                                    <i class="bi bi-cloud-download"></i>
                                </a>
                                <a class="btn btn-secondary me-1" target="_blank" title="Xem Thông báo đã ký">
                                    <i class="bi bi-eye"></i>
                                </a>
                            </div>
                            
                            <div  *ngIf="PoisoningNoticeDoc?.FileName !== null">
                                <a class="btn btn-success me-1" 
                                    title="Tải về Thông báo đã ký"
                                    target="_blank"
                                    [href]="PoisoningNoticeDoc.FileName">
                                    <i class="bi bi-cloud-download"></i>
                                </a>
        
                                <a *ngIf="isPDFFile(PoisoningNoticeDoc.FileName) || isWordOrExcelFile(PoisoningNoticeDoc.FileName) || isHtmlFile(PoisoningNoticeDoc.FileName)"
                                    class="btn btn-success me-1"
                                    title="Xem Thông báo đã ký"
                                    target="_blank"
                                    [href]="getViewerUrl(PoisoningNoticeDoc.FileName)">
                                    <i class="bi bi-eye"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="PlanThamDinhService.IsShowLoading"></app-loading>