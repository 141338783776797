import { Component, OnInit, Inject, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';

import { DistrictData } from 'src/app/shared/DistrictData.model';
import { DistrictDataService } from 'src/app/shared/DistrictData.service';
import { WardData } from 'src/app/shared/WardData.model';
import { WardDataService } from 'src/app/shared/WardData.service';

import { ProductGroup } from 'src/app/shared/ProductGroup.model';
import { ProductGroupService } from 'src/app/shared/ProductGroup.service';

import { CompanyInfo } from 'src/app/shared/CompanyInfo.model';
import { CompanyInfoService } from 'src/app/shared/CompanyInfo.service';
import { CompanyInfoVungTrong } from 'src/app/shared/CompanyInfoVungTrong.model';
import { CompanyInfoVungTrongService } from 'src/app/shared/CompanyInfoVungTrong.service';
import { CompanyInfoVungTrongNongHo } from 'src/app/shared/CompanyInfoVungTrongNongHo.model';
import { CompanyInfoVungTrongNongHoService } from 'src/app/shared/CompanyInfoVungTrongNongHo.service';

import { CompanyInfoVungTrongDocuments } from 'src/app/shared/CompanyInfoVungTrongDocuments.model';
import { CompanyInfoVungTrongDocumentsService } from 'src/app/shared/CompanyInfoVungTrongDocuments.service';

import { CompanyInfoVungTrongToaDo } from 'src/app/shared/CompanyInfoVungTrongToaDo.model';
import { CompanyInfoVungTrongToaDoService } from 'src/app/shared/CompanyInfoVungTrongToaDo.service';

import { PlanThamDinh } from 'src/app/shared/PlanThamDinh.model';
import { PlanThamDinhService } from 'src/app/shared/PlanThamDinh.service';

import { PlanThamDinhCompanyDocument } from 'src/app/shared/PlanThamDinhCompanyDocument.model';
import { PlanThamDinhCompanyDocumentService } from 'src/app/shared/PlanThamDinhCompanyDocument.service';
import { PlanThamDinhCompanyDocumentDetailComponent } from '../plan-tham-dinh-company-document-detail/plan-tham-dinh-company-document-detail.component';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

import { DanhMucATTPLoaiHoSo } from 'src/app/shared/DanhMucATTPLoaiHoSo.model';
import { DanhMucATTPLoaiHoSoService } from 'src/app/shared/DanhMucATTPLoaiHoSo.service';

import { DanhMucATTPTinhTrang } from 'src/app/shared/DanhMucATTPTinhTrang.model';
import { DanhMucATTPTinhTrangService } from 'src/app/shared/DanhMucATTPTinhTrang.service';

import { DanhMucATTPXepLoai } from 'src/app/shared/DanhMucATTPXepLoai.model';
import { DanhMucATTPXepLoaiService } from 'src/app/shared/DanhMucATTPXepLoai.service';

import { DanhMucThiTruong } from 'src/app/shared/DanhMucThiTruong.model';
import { DanhMucThiTruongService } from 'src/app/shared/DanhMucThiTruong.service';

import { MatHangXuatKhau } from 'src/app/shared/MatHangXuatKhau.model';
import { MatHangXuatKhauService } from 'src/app/shared/MatHangXuatKhau.service';
import { HistoryService } from 'src/app/shared/History.service';
import { History } from 'src/app/shared/History.model';


import * as maplibregl from 'maplibre-gl';
import { ThanhVienDetailComponent } from '../thanh-vien-detail/thanh-vien-detail.component';
import { ThanhVienDetail001Component } from '../thanh-vien-detail001/thanh-vien-detail001.component';
import { CompanyInfoVungTrongDocumentsDetailComponent } from '../company-info-vung-trong-documents-detail/company-info-vung-trong-documents-detail.component';

import { DownloadService } from 'src/app/shared/Download.service';
import { UploadService } from 'src/app/shared/Upload.service';

@Component({
  selector: 'app-company-info-vung-trong-detail',
  templateUrl: './company-info-vung-trong-detail.component.html',
  styleUrls: ['./company-info-vung-trong-detail.component.css']
})
export class CompanyInfoVungTrongDetailComponent implements OnInit {
  @ViewChild('CompanyInfoVungTrongSanPhamSort') CompanyInfoVungTrongSanPhamSort: MatSort;
  @ViewChild('CompanyInfoVungTrongSanPhamPaginator') CompanyInfoVungTrongSanPhamPaginator: MatPaginator;

  @ViewChild('CompanyInfoVungTrongThiTruongSort') CompanyInfoVungTrongThiTruongSort: MatSort;
  @ViewChild('CompanyInfoVungTrongThiTruongPaginator') CompanyInfoVungTrongThiTruongPaginator: MatPaginator;

  @ViewChild('CompanyInfoVungTrongNongHoSort') CompanyInfoVungTrongNongHoSort: MatSort;
  @ViewChild('CompanyInfoVungTrongNongHoPaginator') CompanyInfoVungTrongNongHoPaginator: MatPaginator;

  @ViewChild('CompanyInfoVungTrongDocumentsSort') CompanyInfoVungTrongDocumentsSort: MatSort;
  @ViewChild('CompanyInfoVungTrongDocumentsPaginator') CompanyInfoVungTrongDocumentsPaginator: MatPaginator;

  @ViewChild('CompanyInfoVungTrongDocumentsSort002') CompanyInfoVungTrongDocumentsSort002: MatSort;
  @ViewChild('CompanyInfoVungTrongDocumentsPaginator002') CompanyInfoVungTrongDocumentsPaginator002: MatPaginator;

  @ViewChild('HistorySort002') HistorySort002: MatSort;
  @ViewChild('HistoryPaginator002') HistoryPaginator002: MatPaginator;

  @ViewChild('CompanyInfoVungTrongToaDoSort') CompanyInfoVungTrongToaDoSort: MatSort;
  @ViewChild('CompanyInfoVungTrongToaDoPaginator') CompanyInfoVungTrongToaDoPaginator: MatPaginator;

  @ViewChild('PlanThamDinhCompanyDocumentSort') PlanThamDinhCompanyDocumentSort: MatSort;
  @ViewChild('PlanThamDinhCompanyDocumentPaginator') PlanThamDinhCompanyDocumentPaginator: MatPaginator;

  @ViewChild('fileInput1', { static: false }) fileInput1!: ElementRef;
  @ViewChildren('fileInput') fileInputs!: QueryList<ElementRef>;
  @ViewChildren('fileInput3') fileInputs3!: QueryList<ElementRef>;
  @ViewChildren('fileInputs4') fileInputs4!: QueryList<ElementRef>;
  
  @ViewChild('uploadGiamSatDuLuong') uploadGiamSatDuLuong!: ElementRef;
  @ViewChild('uploadGiamSatDuLuong1') uploadGiamSatDuLuong1!: ElementRef;
  @ViewChild('uploadGiamSatDuLuong2') uploadGiamSatDuLuong2!: ElementRef;
  
  domainURL: string = environment.DomainURL;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CompanyInfoVungTrongDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {ID:number, IsStaff:boolean, CompanyInfoVungTrongDetail: CompanyInfoVungTrong},
    
    public NotificationService: NotificationService,

    public DownloadService: DownloadService,

    public ProductGroupService: ProductGroupService,

    public DistrictDataService: DistrictDataService,
    public WardDataService: WardDataService,
    public MatHangXuatKhauService: MatHangXuatKhauService,
    
    public CompanyInfoService: CompanyInfoService,
    public CompanyInfoVungTrongService: CompanyInfoVungTrongService,
    public HistoryService: HistoryService,
    public CompanyInfoVungTrongNongHoService: CompanyInfoVungTrongNongHoService,

    public CompanyInfoVungTrongDocumentsService: CompanyInfoVungTrongDocumentsService,
    public CompanyInfoVungTrongToaDoService: CompanyInfoVungTrongToaDoService,
    public PlanThamDinhCompanyDocumentService: PlanThamDinhCompanyDocumentService,
    public PlanThamDinhService: PlanThamDinhService,
    public UploadService: UploadService,

    public ThanhVienService: ThanhVienService,
    public DanhMucATTPLoaiHoSoService: DanhMucATTPLoaiHoSoService,
    public DanhMucATTPTinhTrangService: DanhMucATTPTinhTrangService,
    public DanhMucATTPXepLoaiService: DanhMucATTPXepLoaiService,
    public DanhMucThiTruongService: DanhMucThiTruongService,

  ) { }
  IsStaff:boolean = false
  excelUploadBieuMauDanhSachNongHoURL: string = environment.APIUploadRootURL + environment.Download + "/Bieu_mau_danh_sach_nong_ho_MSVT.xlsx";
  ngOnInit(): void {
    this.ThanhVienGetLogin();
    if(this.data.IsStaff){
      this.IsStaff = this.data.IsStaff;
    }
    this.ProductGroupSearch();
    
    this.DanhMucATTPLoaiHoSoSearch();
    this.DanhMucATTPTinhTrangSearch();
    this.DanhMucATTPXepLoaiSearch();
    this.DanhMucThiTruongSearch();
    this.ThanhVienSearch();
    this.CompanyInfoSearch();
    this.DistrictDataSearch();
    this.TimKiemMatHangXuatKhau();
    this.TimKiemLichSu();
    
    this.countResultSetting = 0;
    this.CompanyInfoVungTrongService.FormData.WardDataID = null
    this.CompanyInfoVungTrongService.FormData.DistrictDataID = null
    this.CompanyInfoVungTrongService.FormData.DanhMucATTPLoaiHoSoID = null
    this.CompanyInfoVungTrongService.FormData.DanhMucATTPTinhTrangID = null
    this.templateStaffList = [];
    this.templateStaffList.push(environment.BMGiamSat);
    this.templateStaffList.push(environment.BMKiemTra);
    this.templateStaffList.push(environment.BMCacGiaytoKhac);
  }
  TimKiemLichSu(){
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    // this.data.CompanyInfoVungTrongDetail
    if(this.data.CompanyInfoVungTrongDetail != undefined)
      this.HistoryService.BaseParameter.SearchString = this.data.CompanyInfoVungTrongDetail.Code;
    else
      this.HistoryService.BaseParameter.SearchString = "";
    this.HistoryService.GetBySearchStringToListAsync().subscribe(
      res => {
        this.HistoryService.List = (res as History[]).sort((a, b) => (a.LastUpdatedDate < b.LastUpdatedDate ? 1 : -1));
        this.HistoryService.DataSource = new MatTableDataSource(this.MatHangXuatKhauService.List);
        this.HistoryService.DataSource.sort = this.HistorySort002;
        this.HistoryService.DataSource.paginator = this.HistoryPaginator002;
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
    
  }
  TimKiemMatHangXuatKhau(){
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    // this.data.CompanyInfoVungTrongDetail
    if(this.data.CompanyInfoVungTrongDetail != undefined)
      this.MatHangXuatKhauService.BaseParameter.SearchString = this.data.CompanyInfoVungTrongDetail.Code;
    else
      this.MatHangXuatKhauService.BaseParameter.SearchString = "";
    this.MatHangXuatKhauService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.MatHangXuatKhauService.List = (res as MatHangXuatKhau[]).sort((a, b) => (a.SortOrder < b.SortOrder ? 1 : -1));
        let idZeroItem = this.MatHangXuatKhauService.List.filter(p => p.ID === 0);
        idZeroItem.forEach(element => {
          element.HienTrangID = 2;
        });
        const otherItems = this.MatHangXuatKhauService.List.filter(p => p.ID !== 0);
        this.MatHangXuatKhauService.List = [...idZeroItem, ...otherItems];
        this.MatHangXuatKhauService.DataSource = new MatTableDataSource(this.MatHangXuatKhauService.List);
        this.MatHangXuatKhauService.DataSource.sort = this.CompanyInfoVungTrongDocumentsSort002;
        this.MatHangXuatKhauService.DataSource.paginator = this.CompanyInfoVungTrongDocumentsPaginator002;
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
    
  }
  MatHangXuatKhauSave(element:MatHangXuatKhau){
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    element.ParentID = this.CompanyInfoVungTrongService.FormData.ID;
    element.Code = this.CompanyInfoVungTrongService.FormData.Code;
    this.MatHangXuatKhauService.FormData = element;
    this.MatHangXuatKhauService.SaveAsync().subscribe(
      res => {
        this.TimKiemMatHangXuatKhau();
        this.NotificationService.warn(environment.SaveSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }
  templateStaffList:string[]
  SetDataToCompanyInfo(){
    if(this.countResultSetting==2){
      if(this.data.ID>0){
        // this.CompanyInfoVungTrongService.FormData.CompanyInfoID = 0
        const exist = this.CompanyInfoService.List.find(c=>c.ID == this.CompanyInfoVungTrongService.FormData.CompanyInfoID);
        this.CompanyInfoVungTrongService.FormData.Code = this.data.CompanyInfoVungTrongDetail.Code
        if(!exist){
          this.CompanyInfoVungTrongService.IsShowLoading = true;
          this.CompanyInfoService.BaseParameter.ID = this.data.CompanyInfoVungTrongDetail.CompanyInfoID;
          this.CompanyInfoService.GetByIDAsync().subscribe(
            res => {
              this.CompanyInfoService.FormData = res as CompanyInfo;
              this.CompanyInfoService.List.push(this.CompanyInfoService.FormData);
              this.CompanyInfoVungTrongService.FormData.DistrictDataID = this.data.CompanyInfoVungTrongDetail.DistrictDataID;
              this.WardDataSearch(true);
              this.CompanyInfoVungTrongService.IsShowLoading = false;
            },
            err => {
              this.CompanyInfoVungTrongService.IsShowLoading = false;
            }
          );
        }else{
          this.CompanyInfoVungTrongService.FormData = this.data.CompanyInfoVungTrongDetail;
        }
      }else{
        if(!this.data.IsStaff){
          this.CompanyInfoVungTrongService.FormData.CompanyInfoID = this.ThanhVienService.FormDataLogin.CompanyInfoID
          this.CompanyInfoChange()
        }
        this.CompanyInfoVungTrongService.FormData.DanhMucATTPTinhTrangID = 12
        this.CompanyInfoVungTrongService.FormData.DanhMucATTPTinhTrangName = "Bản Nháp"
        this.CompanyInfoVungTrongService.FormData.DanhMucATTPXepLoaiID = 5
        this.CompanyInfoVungTrongService.FormData.DanhMucATTPXepLoaiName = "Chưa thẩm định"
      }
      this.CompanyInfoVungTrongToaDoSearch();
      this.CompanyInfoVungTrongNongHoSearch();
      this.CompanyInfoVungTrongDocumentsSearch();
      this.CompanyInfoVungTrongService.FormData.ProvinceDataID = environment.ProvinceDataIDBenTre;
      this.CompanyInfoVungTrongService.FormData.ProvinceDataName = 'Tỉnh Bến Tre';
    }
    else
      this.countResultSetting++;
  }
  CheckTemplateOfStaff(element:CompanyInfoVungTrongDocuments){
    if(this.templateStaffList.find(t=>t == element.Name))
    {
      if(this.IsStaff){
        return true
      }
    }else
      return true
    return false
  }
  DateNgayGhiNhan(value) {
    this.CompanyInfoVungTrongService.FormData.NgayGhiNhan = new Date(value);
  }
  CompanyInfoVungTrongDocumentsNgayGhiNhan(element: CompanyInfoVungTrongDocuments, value) {
    element.NgayGhiNhan = new Date(value);
  }
  ThanhVienGetLogin() {
    this.ThanhVienService.GetLogin();
  }
  CompanyInfoChange() {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    this.CompanyInfoService.BaseParameter.ID = this.CompanyInfoVungTrongService.FormData.CompanyInfoID;
    this.CompanyInfoService.GetByIDAsync().subscribe(
      res => {
        this.CompanyInfoService.FormData = res as CompanyInfo;
        this.CompanyInfoVungTrongService.FormData.CompanyInfoID = this.CompanyInfoService.FormData.ID;
        this.CompanyInfoVungTrongService.FormData.CompanyInfoName = this.CompanyInfoService.FormData.Name;
        this.CompanyInfoVungTrongService.FormData.DaiDienCoSo = this.CompanyInfoService.FormData.fullname;
        this.CompanyInfoVungTrongService.FormData.DaiDienCoSoChucVu = this.CompanyInfoService.FormData.role_name;
        this.CompanyInfoVungTrongService.FormData.DaiDienCoSoDienThoai = this.CompanyInfoService.FormData.phone;
        this.CompanyInfoVungTrongService.FormData.DaiDienCoSoEmail = this.CompanyInfoService.FormData.email;
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }

  CompanyInfoSearch() {
    this.CompanyInfoService.ComponentGet000ToListAsync();
  }
  CompanyInfoFilter(searchString: string) {
    this.CompanyInfoService.Filter000(searchString);
  }
  countResultSetting:number=0
  async DanhMucATTPTinhTrangSearch(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.DanhMucATTPTinhTrangService.ComponentGetAllToListAsyncUpdate().subscribe(
        res => {
          this.SetDataToCompanyInfo()
          // if(this.countResultSetting==2)
          //   this.SetDataToCompanyInfo()
          // else
          //   this.countResultSetting++;
          // this.CompanyInfoVungTrongService.FormData.DanhMucATTPTinhTrangID = this.data.CompanyInfoVungTrongDetail.DanhMucATTPTinhTrangID;
          resolve();
        },
        err => {
          reject(err);
        }
      );
    });
  }
  FindbyExist(idInpupt:number,type:number){
    if(type==1)
    {
      if(environment.HienThiLyDo.find(element =>element == this.DanhMucATTPLoaiHoSoService.List.find(l=>l.ID == idInpupt).Name)) return true;
    }
    if(type == 2)
    {
      if(environment.HienThiLyDo.find(element =>element == this.DanhMucATTPTinhTrangService.List.find(l=>l.ID == idInpupt).Name)) return true;
    }
    if(type == 3)
    {
      if(environment.HienThiLyDo.find(element =>element == this.DanhMucATTPXepLoaiService.List.find(l=>l.ID == idInpupt).Name)) return true;
    }
    return false
    // return this.DanhMucATTPLoaiHoSoService.List.find(l=>l.ID == idInpupt).Name;
  }
  IsShowDVTC:boolean = true
  IsShowLyDoCSDG:boolean = false
  IsShowLyDoVT:boolean = false
  ChangeLyDoCSDG(){
    this.IsShowLyDoCSDG = this.FindbyExist(this.CompanyInfoVungTrongService.FormData.DanhMucATTPXepLoaiID,3)
  }
  ChangeLyDoHS(){
    this.IsShowLyDoVT = this.FindbyExist(this.CompanyInfoVungTrongService.FormData.DanhMucATTPTinhTrangID,2)
  }
  ATTPLoaiHoSoChange(){
    const result = this.FindbyExist(this.CompanyInfoVungTrongService.FormData.DanhMucATTPLoaiHoSoID, 1);
    // const result = danhMucATTPLoaiHoSoName != environment.DangKyChuyenChuThe;
    if(!result) this.IsShowDVTC = true;
    else this.IsShowDVTC = false;
    if(result && !this.IsStaff){
      this.CompanyInfoVungTrongService.IsShowLoading = true;
      this.CompanyInfoVungTrongService.FormData.CompanyInfoID = this.ThanhVienService.FormDataLogin.CompanyInfoID
      this.CompanyInfoChange();
      this.CompanyInfoVungTrongService.IsShowLoading = false;
    }
  }
  // DanhMucATTPTinhTrangSearch() {
  //   this.DanhMucATTPTinhTrangService.ComponentGetAllToListAsync();
  // }
  async DanhMucATTPLoaiHoSoSearch(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.DanhMucATTPLoaiHoSoService.ComponentGetAllToListAsyncUpdate().subscribe(
        res => {
          this.SetDataToCompanyInfo()
          // this.CompanyInfoVungTrongService.FormData.DanhMucATTPLoaiHoSoID = this.data.CompanyInfoVungTrongDetail.DanhMucATTPLoaiHoSoID;
          resolve();
        },
        err => {
          reject(err);
        }
      );
    });
  }
  // DanhMucATTPLoaiHoSoSearch() {
  //   this.DanhMucATTPLoaiHoSoService.ComponentGetAllToListAsync();
  // }

  DanhMucATTPXepLoaiSearch() {
    this.DanhMucATTPXepLoaiService.ComponentGetAllToListAsync();
  }

  openFileInput2(element: any) {
    const index = this.CompanyInfoVungTrongDocumentsService.List.indexOf(element); // Tìm index của element
    if (index !== -1) {
      const inputElement = this.fileInputs.toArray()[index];
      inputElement.nativeElement.click();
    }
  }

  openFileInput3(element: any) {
    const index = this.MatHangXuatKhauService.List.indexOf(element); // Tìm index của element
    if (index !== -1) {
      const inputElement = this.fileInputs3.toArray()[index];
      inputElement.nativeElement.click();
    }
  }
  openFileInput4(element: any) {
    const index = this.MatHangXuatKhauService.List.indexOf(element); // Tìm index của element
    if (index !== -1) {
      const inputElement = this.fileInputs4.toArray()[index];
      inputElement.nativeElement.click();
    }
  }
  
  isPDFFile(fileName: string): boolean {
    return /\.pdf$/i.test(fileName);
  }
  isWordOrExcelFile(fileName: string): boolean {
    return /\.(doc|docx|xlsx)$/i.test(fileName);
  }
  isHtmlFile(fileName: string): boolean {
    return /\.(html)$/i.test(fileName);
  }
  getViewerUrl(fileName: string): string {
    if (this.isPDFFile(fileName) || this.isHtmlFile(fileName)) {
      return fileName;
    } else if (this.isWordOrExcelFile(fileName)) {
      return `https://docs.google.com/viewer?url=${encodeURIComponent(fileName)}&embedded=true`;
    }
    return fileName;
  }

  DistrictDataSearch() {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    this.DistrictDataService.BaseParameter.ParentID = environment.ProvinceDataIDBenTre;
    let setDistrict = false;
    this.DistrictDataService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DistrictDataService.List = (res as DistrictData[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        if (this.DistrictDataService.List) {
          if (this.DistrictDataService.List.length > 0) {
            if (this.CompanyInfoVungTrongService.FormData.ID == 0) {
              this.CompanyInfoVungTrongService.FormData.DistrictDataID = this.DistrictDataService.List[0].ID;
            }
          }
        }
        this.SetDataToCompanyInfo()
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }

  WardDataSearch(IsSetData:boolean = false) {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    
    if(this.CompanyInfoVungTrongService != undefined && this.CompanyInfoVungTrongService.FormData.DistrictDataID != null)
      this.WardDataService.BaseParameter.ParentID = this.CompanyInfoVungTrongService.FormData.DistrictDataID;
    else
      this.WardDataService.BaseParameter.ParentID = 0;
    this.WardDataService.GetByParentIDToListAsync().subscribe(
      res => {
        this.WardDataService.List = (res as WardData[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        
        if(IsSetData) 
          this.CompanyInfoVungTrongService.FormData = this.data.CompanyInfoVungTrongDetail;
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }
  DanhMucThiTruongChange() {
    if (this.CompanyInfoVungTrongService.FormData.ThiTruong) {
      this.CompanyInfoVungTrongService.FormData.ThiTruong = this.CompanyInfoVungTrongService.FormData.ThiTruong + "; " + this.CompanyInfoVungTrongService.FormData.Display;
    }
    else {
      this.CompanyInfoVungTrongService.FormData.ThiTruong = this.CompanyInfoVungTrongService.FormData.Display;
    }
  }

  ProductGroupSearch() {
    this.ProductGroupService.ComponentGetAllToListAsync();
  }

  DanhMucThiTruongSearch() {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    this.DanhMucThiTruongService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucThiTruongService.List = (res as DanhMucThiTruong[]).sort((a, b) => (a.Name > b.Name ? 1 : -1)).filter(d=>d?.Name?.trim()!="" && d?.Name != null);
        this.DanhMucThiTruongService.ListFilter = this.DanhMucThiTruongService.List;
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }


  openFileInput(){
    this.fileInput1.nativeElement.click();
  }
  CompanyInfoVungTrongToaDoSearch() {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    this.CompanyInfoVungTrongToaDoService.BaseParameter.SearchString = this.CompanyInfoVungTrongService.FormData.Code;
    this.CompanyInfoVungTrongToaDoService.GetBySearchStringToListAsync().subscribe(
      res => {
        this.CompanyInfoVungTrongToaDoService.List = (res as CompanyInfoVungTrongToaDo[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.CompanyInfoVungTrongToaDoService.DataSource = new MatTableDataSource(this.CompanyInfoVungTrongToaDoService.List);
        this.CompanyInfoVungTrongToaDoService.DataSource.sort = this.CompanyInfoVungTrongToaDoSort;
        this.CompanyInfoVungTrongToaDoService.DataSource.paginator = this.CompanyInfoVungTrongToaDoPaginator;
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }
  CompanyInfoVungTrongToaDoSave(element: CompanyInfoVungTrongToaDo) {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    element.ParentID = this.CompanyInfoVungTrongService.FormData.ID;
    element.Code = this.CompanyInfoVungTrongService.FormData.Code;
    this.CompanyInfoVungTrongToaDoService.FormData = element;
    this.CompanyInfoVungTrongToaDoService.SaveAsync().subscribe(
      res => {
        this.CompanyInfoVungTrongToaDoSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }
  CompanyInfoVungTrongDocumentsDownload() {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    let CompanyInfoVungTrongDocuments1:string[]=[];
    for (let i = 0; i < this.CompanyInfoVungTrongDocumentsService.List.length; i++) {
      let CompanyInfoVungTrongDocuments = this.CompanyInfoVungTrongDocumentsService.List[i];
      if (CompanyInfoVungTrongDocuments.FileName) {
        if (CompanyInfoVungTrongDocuments.FileName.length > 0) {
          CompanyInfoVungTrongDocuments1.push(CompanyInfoVungTrongDocuments.FileName);          
          // window.open(CompanyInfoVungTrongDocuments.FileName, "_blank");
        }
      }
    }
    // this.CompanyInfoVungTrongDocumentsService.List = CompanyInfoVungTrongDocuments1;
    this.CompanyInfoVungTrongDocumentsService.createzip(CompanyInfoVungTrongDocuments1).subscribe(
      (res: any) => {
        // Kiểm tra phản hồi có URL
        if (res && res.url) {
          const fileUrl = `${res.url}`;
          const link = document.createElement('a');
          link.href = fileUrl;
          link.target = '_blank'; // Mở trong tab mới (tùy chọn)
          link.download = fileUrl.split('/').pop() || 'download.zip'; // Tên file tải xuống
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // Dọn dẹp
        } else {
          console.error('No URL returned from the API.');
        }
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );

  }
  
  ChangeFileName2(element: CompanyInfoVungTrongDocuments, files: FileList | null) {
    if (files && files.length > 0) {
      this.CompanyInfoVungTrongDocumentsService.FileToUpload = files;
      this.PlanThamDinhCompanyDocumentsSave1(element)
    }
  }

  CompanyInfoVungTrongDocumentsSearch() {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    this.CompanyInfoVungTrongDocumentsService.BaseParameter.SearchString = this.CompanyInfoVungTrongService.FormData.Code;
    this.CompanyInfoVungTrongDocumentsService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.CompanyInfoVungTrongDocumentsService.List = (res as CompanyInfoVungTrongDocuments[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));

        this.GiamSatFile = this.CompanyInfoVungTrongDocumentsService.List.find(c=>c.DocumentTemplateID == 422112);
        this.GiamSatFile1 = this.CompanyInfoVungTrongDocumentsService.List.find(c=>c.DocumentTemplateID == 422113);
        this.GiamSatFile2 = this.CompanyInfoVungTrongDocumentsService.List.find(c=>c.DocumentTemplateID == 422114);
        
        this.CompanyInfoVungTrongDocumentsService.DataSource = new MatTableDataSource(
          this.CompanyInfoVungTrongDocumentsService.List.filter(x => x.IsPheDuyet != true && x.Note == null)
        );
        this.CompanyInfoVungTrongDocumentsService.DataSource.sort = this.CompanyInfoVungTrongDocumentsSort;
        this.CompanyInfoVungTrongDocumentsService.DataSource.paginator = this.CompanyInfoVungTrongDocumentsPaginator;

        this.CompanyInfoVungTrongDocumentsService.DataSource002 = new MatTableDataSource(
          this.CompanyInfoVungTrongDocumentsService.List.filter(x => x.IsPheDuyet == true || x.ID == 0)
        );
        this.CompanyInfoVungTrongDocumentsService.DataSource002.sort = this.CompanyInfoVungTrongDocumentsSort002;
        this.CompanyInfoVungTrongDocumentsService.DataSource002.paginator = this.CompanyInfoVungTrongDocumentsPaginator002;
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }
  CompanyInfoVungTrongDocumentsSave(element: CompanyInfoVungTrongDocuments) {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    element.ParentID = this.CompanyInfoVungTrongService.FormData.ID;
    element.Code = this.CompanyInfoVungTrongService.FormData.Code;
    this.CompanyInfoVungTrongDocumentsService.FormData = element;
    this.CompanyInfoVungTrongDocumentsService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.CompanyInfoVungTrongDocumentsService.FormData = (res as CompanyInfoVungTrongDocuments);
        this.CompanyInfoVungTrongDocumentsSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }

  ChangeFileName3(element:MatHangXuatKhau, files: FileList | null) {
    if (files && files.length > 0) {
      this.MatHangXuatKhauService.FileToUpload = files;
      this.PlanThamDinhCompanyDocumentsSave2(element)
    }
  }
  ChangeFileName4(element:MatHangXuatKhau, files: FileList | null) {
    if (files && files.length > 0) {
      this.MatHangXuatKhauService.FileToUpload = files;
      this.PlanThamDinhCompanyDocumentsSave3(element)
    }
  }
  PlanThamDinhCompanyDocumentsSave3(matHangXuatKhau: MatHangXuatKhau) {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    matHangXuatKhau.ParentID = this.CompanyInfoVungTrongService.FormData.ID;
    matHangXuatKhau.Code = this.CompanyInfoVungTrongService.FormData.Code;
    this.MatHangXuatKhauService.FormData = matHangXuatKhau;
    this.MatHangXuatKhauService.SaveAndUploadFileAsync().subscribe(
      res => {
        matHangXuatKhau.FileDinhKem = (res as MatHangXuatKhau).FileName;
        this.NotificationService.warn(environment.SaveSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }

  PlanThamDinhCompanyDocumentsSave2(matHangXuatKhau: MatHangXuatKhau) {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    matHangXuatKhau.ParentID = this.CompanyInfoVungTrongService.FormData.ID;
    matHangXuatKhau.Code = this.CompanyInfoVungTrongService.FormData.Code;
    this.MatHangXuatKhauService.FormData = matHangXuatKhau;
    this.MatHangXuatKhauService.SaveAndUploadFileAsync().subscribe(
      res => {
        // let changeData = this.MatHangXuatKhauService.List.find(m=>m.IDTemp = matHangXuatKhau.IDTemp);
        // changeData.FileName = (res as MatHangXuatKhau)
        matHangXuatKhau.FileName = (res as MatHangXuatKhau).FileName;
        // this.TimKiemMatHangXuatKhau();
        this.NotificationService.warn(environment.SaveSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompanyDocumentsSave1(CompanyInfoVungTrongDocument: CompanyInfoVungTrongDocuments) {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    CompanyInfoVungTrongDocument.ParentID = this.CompanyInfoVungTrongService.FormData.ID;
    CompanyInfoVungTrongDocument.Code = this.CompanyInfoVungTrongService.FormData.Code;
    this.CompanyInfoVungTrongDocumentsService.FormData = CompanyInfoVungTrongDocument;
    this.CompanyInfoVungTrongDocumentsService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.CompanyInfoVungTrongDocumentsSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }
  CompanyInfoVungTrongDocumentsSave002(element: CompanyInfoVungTrongDocuments) {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    element.ParentID = this.CompanyInfoVungTrongService.FormData.ID;
    element.Code = this.CompanyInfoVungTrongService.FormData.Code;
    element.IsPheDuyet = true;
    this.CompanyInfoVungTrongDocumentsService.FormData = element;
    this.CompanyInfoVungTrongDocumentsService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.CompanyInfoVungTrongDocumentsService.FormData = (res as CompanyInfoVungTrongDocuments);
        this.CompanyInfoVungTrongDocumentsSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }
  CompanyInfoVungTrongDocumentsDelete(element: CompanyInfoVungTrongDocuments) {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    this.CompanyInfoVungTrongDocumentsService.BaseParameter.ID = element.ID;
    this.CompanyInfoVungTrongDocumentsService.RemoveAsync().subscribe(
      res => {
        this.CompanyInfoVungTrongDocumentsSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }

  CompanyInfoVungTrongDocumentsAdd(element: CompanyInfoVungTrongDocuments) {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    this.CompanyInfoVungTrongDocumentsService.BaseParameter.ID = element.ID;
    this.CompanyInfoVungTrongDocumentsService.GetByIDAsync().subscribe(
      res => {
        this.CompanyInfoVungTrongDocumentsService.FormData = res as CompanyInfoVungTrongDocuments;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: this.CompanyInfoVungTrongDocumentsService.FormData.ID };
        const dialog = this.dialog.open(CompanyInfoVungTrongDocumentsDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }

  // SubmitCompanyInfo() {
  //   this.UploadService.IsShowLoading = true;
  //   this.UploadService.File = this.uploadCompanyInfo.nativeElement.files[0];
  //   this.UploadService.PostCompanyInfo_CompanyInfoLichSuKiemTraByExcelFileAsync().subscribe(
  //     res => {
  //       this.UploadService.IsShowLoading = false;
  //       this.NotificationService.warn(environment.UploadSuccess);
  //     },
  //     err => {
  //       this.UploadService.IsShowLoading = false;
  //       this.NotificationService.warn(environment.UploadNotSuccess);
  //     }
  //   );
  // }
  
  GiamSatFile1: PlanThamDinhCompanyDocument
  GiamSatFile2: PlanThamDinhCompanyDocument
  GiamSatFile: PlanThamDinhCompanyDocument
  UploadFileGiamSat(){
    this.uploadGiamSatDuLuong.nativeElement.click();
  }
  UploadFileGiamSat1(){
    this.uploadGiamSatDuLuong1.nativeElement.click();
  }
  UploadFileGiamSat2(){
    this.uploadGiamSatDuLuong2.nativeElement.click();
  }
  ChangeFileName1(files: FileList | null) {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    this.UploadService.File = files[0];
    this.UploadService.BaseParameter.SearchString = this.CompanyInfoVungTrongService.FormData.Code;
    this.UploadService.PostNongHoByExcelFileAsync().subscribe(
      res => {
        this.CompanyInfoVungTrongNongHoSearch();
        this.CompanyInfoVungTrongService.IsShowLoading = false;
        this.NotificationService.warn(environment.UploadSuccess);
      },
      err => {
        this.CompanyInfoVungTrongService.IsShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );


    // this.NotificationService.warn(environment.UploadSuccess);
    // let uploadNongHo:CompanyInfoVungTrongDocuments  = {};
    // uploadNongHo.DocumentTemplateID = 422112
    // this.CompanyInfoVungTrongDocumentsService.FileToUpload = files;
    // this.PlanThamDinhCompanyDocumentsSave1(uploadNongHo);
    // if (files && files.length > 0) {
    //   this.PlanThamDinhCompanyDocumentService.FileToUpload = files;
    //   const docNongHo:number=999991
    //   this.PlanThamDinhCompanyDocumentsSave(docNongHo)
    // }
  }
  ChangeNhuyenThe02ManhVo1(files: FileList) {
    if (files && files.length > 0) {
      this.NotificationService.warn(environment.UploadSuccess);
      let uploadNongHo:CompanyInfoVungTrongDocuments  = {};
      uploadNongHo.DocumentTemplateID = 422113
      this.CompanyInfoVungTrongDocumentsService.FileToUpload = files;
      uploadNongHo.Note = "FileUpload";
      this.PlanThamDinhCompanyDocumentsSave1(uploadNongHo);
    }
  }
  ChangeNhuyenThe02ManhVo2(files: FileList) {
    if (files && files.length > 0) {
      this.NotificationService.warn(environment.UploadSuccess);
      let uploadNongHo:CompanyInfoVungTrongDocuments  = {};
      uploadNongHo.DocumentTemplateID = 422114
      this.CompanyInfoVungTrongDocumentsService.FileToUpload = files;
      uploadNongHo.Note = "FileUpload";
      this.PlanThamDinhCompanyDocumentsSave1(uploadNongHo);
    }
  }
  
  ChangeNhuyenThe02ManhVo(files: FileList) {
    if (files && files.length > 0) {
      this.NotificationService.warn(environment.UploadSuccess);
      let uploadNongHo:CompanyInfoVungTrongDocuments  = {};
      uploadNongHo.DocumentTemplateID = 422112
      this.CompanyInfoVungTrongDocumentsService.FileToUpload = files;
      uploadNongHo.Note = "FileUpload";
      this.PlanThamDinhCompanyDocumentsSave1(uploadNongHo);
    }
  }
  SHOWRESULT :boolean = environment.ShowResult
  PlanThamDinhCompanyDocumentsSave(documentTemplateID) {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    let element: PlanThamDinhCompanyDocument = new PlanThamDinhCompanyDocument()
    element.DocumentTemplateID = documentTemplateID;
    // element.IsUploadConfirm = this.SHOWRESULT;
    // element.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;
    // element.Code = this.PlanThamDinhService.FormData.Code;
    // element.PlanTypeID = environment.PlanTypeIDCoSoNuoi
    // this.PlanThamDinhCompanyDocumentService.FormData = element;
    this.PlanThamDinhCompanyDocumentService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }
  ChangeFileName(element: CompanyInfoVungTrongDocuments, files: FileList) {
    if (files) {
      this.CompanyInfoVungTrongDocumentsService.FileToUpload = files;
      //this.CompanyInfoVungTrongDocumentsSave(element);
    }
  }


  CompanyInfoVungTrongNongHoSearch() {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    this.CompanyInfoVungTrongNongHoService.BaseParameter.SearchString = this.CompanyInfoVungTrongService.FormData.Code;
    this.CompanyInfoVungTrongNongHoService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.CompanyInfoVungTrongNongHoService.List = (res as CompanyInfoVungTrongNongHo[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        const idZeroItem = this.CompanyInfoVungTrongNongHoService.List.filter(p => p.ID === 0);
        const otherItems = this.CompanyInfoVungTrongNongHoService.List.filter(p => p.ID !== 0);
        this.CompanyInfoVungTrongNongHoService.List = [...idZeroItem, ...otherItems];
        this.CompanyInfoVungTrongNongHoService.DataSource = new MatTableDataSource(this.CompanyInfoVungTrongNongHoService.List);
        this.CompanyInfoVungTrongNongHoService.DataSource.sort = this.CompanyInfoVungTrongNongHoSort;
        this.CompanyInfoVungTrongNongHoService.DataSource.paginator = this.CompanyInfoVungTrongNongHoPaginator;
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }
  CompanyInfoVungTrongNongHoSave(element: CompanyInfoVungTrongNongHo) {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    element.ParentID = this.CompanyInfoVungTrongService.FormData.ID;
    element.Code = this.CompanyInfoVungTrongService.FormData.Code;
    this.CompanyInfoVungTrongNongHoService.FormData = element;
    this.CompanyInfoVungTrongNongHoService.SaveAsync().subscribe(
      res => {
        this.CompanyInfoVungTrongNongHoSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }
  CompanyInfoVungTrongNongHoDelete(element: CompanyInfoVungTrongNongHo) {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    this.CompanyInfoVungTrongNongHoService.BaseParameter.ID = element.ID;
    this.CompanyInfoVungTrongNongHoService.RemoveAsync().subscribe(
      res => {
        this.CompanyInfoVungTrongNongHoSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }
  CheckHSVT(){
    let myHistory:History = {}
    if(this.CompanyInfoVungTrongService.FormData.DanhMucATTPTinhTrangID == 5 || this.CompanyInfoVungTrongService.FormData.DanhMucATTPTinhTrangID == 6){
      return myHistory = {
        Code: this.CompanyInfoVungTrongService.FormData.Code,
        TinhTrangHS : this.CompanyInfoVungTrongService.FormData.DanhMucATTPTinhTrangName,
        LyDoHS : this.CompanyInfoVungTrongService.FormData.NguyenNhanKhongDatHS,
        TepHS : this.GiamSatFile?.FileName,
        TinhTrangVT : this.CompanyInfoVungTrongService.FormData.DanhMucATTPXepLoaiName,
        LyDoVT : "",
        TepVT : "",
      }
    }else if(this.CompanyInfoVungTrongService.FormData.DanhMucATTPXepLoaiID == 9 || this.CompanyInfoVungTrongService.FormData.DanhMucATTPXepLoaiID == 7 || this.CompanyInfoVungTrongService.FormData.DanhMucATTPXepLoaiID == 18){
      return myHistory = {
        Code: this.CompanyInfoVungTrongService.FormData.Code,
        TinhTrangHS : this.CompanyInfoVungTrongService.FormData.DanhMucATTPTinhTrangName,
        LyDoHS : "",
        TepHS : "",
        TinhTrangVT : this.CompanyInfoVungTrongService.FormData.DanhMucATTPXepLoaiName,
        LyDoVT : this.CompanyInfoVungTrongService.FormData.NguyenNhanKhongDatVT,
        TepVT : this.GiamSatFile1?.FileName,
      }
    }else if(this.CompanyInfoVungTrongService.FormData.DanhMucATTPXepLoaiID == 17){
      return myHistory = {
        Code: this.CompanyInfoVungTrongService.FormData.Code,
        TinhTrangHS : this.CompanyInfoVungTrongService.FormData.DanhMucATTPTinhTrangName,
        LyDoHS : "",
        TepHS : "",
        TinhTrangVT : this.CompanyInfoVungTrongService.FormData.DanhMucATTPXepLoaiName,
        LyDoVT : this.CompanyInfoVungTrongService.FormData.GhiChuNoiDungMSVC,
        TepVT : this.GiamSatFile2?.FileName,
      }
    }else{
      return myHistory = {
        Code: this.CompanyInfoVungTrongService.FormData.Code,
        TinhTrangHS : this.CompanyInfoVungTrongService.FormData.DanhMucATTPTinhTrangName,
        LyDoHS : "",
        TepHS : "",
        TinhTrangVT : this.CompanyInfoVungTrongService.FormData.DanhMucATTPXepLoaiName,
        LyDoVT : "",
        TepVT : "",
      }
    }
  }
  CompanyInfoVungTrongSave(IsSentRequestToManger:boolean = false,IsSaveHistory:boolean = false) {
    if(IsSentRequestToManger){
      this.CompanyInfoVungTrongService.FormData.DanhMucATTPTinhTrangID = 11;
    }
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    this.CompanyInfoVungTrongService.FormData.ParentID = this.CompanyInfoVungTrongService.FormData.CompanyInfoID;
    this.CompanyInfoVungTrongService.FormData.PlanTypeID = environment.PlanTypeIDDangKyMaDongGoi;
    this.CompanyInfoVungTrongService.FormData.TypePlan = 1;
    this.CompanyInfoVungTrongService.SaveAsync().subscribe(
      res => {
        this.CompanyInfoVungTrongService.FormData = res as CompanyInfoVungTrong;
        if(IsSaveHistory){
          let myHistory:History = this.CheckHSVT();
          this.HistoryService.FormData = myHistory
          this.HistoryService.SaveAsync().subscribe(
            res =>{
              this.TimKiemLichSu()
              this.NotificationService.warn(environment.SaveSuccess);
              // this.NotificationService.warn(this.PlanThamDinhService.ComponentSaveForm());
              this.CompanyInfoVungTrongService.IsShowLoading = false;
            },
            err =>{
              this.NotificationService.warn(environment.SaveNotSuccess);
              this.CompanyInfoVungTrongService.IsShowLoading = false;
            }
          )
        }else{
          this.NotificationService.warn(environment.SaveSuccess);
          // this.NotificationService.warn(this.PlanThamDinhService.ComponentSaveForm());
          this.CompanyInfoVungTrongService.IsShowLoading = false;
        }
        //this.PlanThamDinhSave();
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }

  PlanThamDinhSave() {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhService.FormData.ParentID = environment.PlanTypeIDDangKyMaDongGoi;
    this.PlanThamDinhService.FormData.StateAgencyID = environment.StateAgencyIDChiCucBaoVeThucVat;
    this.PlanThamDinhService.SaveAsync().subscribe(
      res => {
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }

  /*
  CompanyInfoVungTrongSave() {
    this.CompanyInfoVungTrongService.FormData.ParentID = this.ThanhVienService.FormDataLogin.CompanyInfoID;
    this.NotificationService.warn(this.CompanyInfoVungTrongService.ComponentSaveForm());
  }
  */

  PlanThamDinhCompanyDocumentAdd(DocumentTemplateID: number) {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    this.PlanThamDinhCompanyDocumentService.BaseParameter.PlanThamDinhID = this.CompanyInfoVungTrongService.FormData.ID;
    this.PlanThamDinhCompanyDocumentService.BaseParameter.DocumentTemplateID = DocumentTemplateID;
    this.PlanThamDinhCompanyDocumentService.GetByPlanThamDinhID_DocumentTemplateIDAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyDocumentService.FormData = res as PlanThamDinhCompanyDocument;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: this.PlanThamDinhCompanyDocumentService.FormData.ID };
        const dialog = this.dialog.open(PlanThamDinhCompanyDocumentDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }
  ThanhVienSearch() {
    this.ThanhVienService.BaseParameter.ParentID = environment.DanhMucThanhVienIDNhanVien;
    this.ThanhVienService.BaseParameter.Active = true;
    this.ThanhVienService.ComponentGetByParentIDToListAsync();
  }
  ThanhVienAdd(ID: number) {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    this.ThanhVienService.BaseParameter.ID = ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.FormData = res as ThanhVien;
        this.ThanhVienService.FormData.ParentID = environment.DanhMucThanhVienIDNhanVien;
        //this.ThanhVienService.FormData.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;
        //this.ThanhVienService.FormData.PlanThamDinhCode = this.PlanThamDinhService.FormData.Code;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienDetail001Component, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.ThanhVienSearch();
        });
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }

  Close() {
    this.dialogRef.close();
  }


  map: maplibregl.Map | undefined;

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  ngAfterViewInit() {
    //this.MapLoad();
  }


  ngOnDestroy() {
    this.map?.remove();
  }

  MapInitialization(longitude, latitude) {
    let zoom = 10;
    if ((latitude > 90) || (latitude == 0)) {
      latitude = environment.Latitude;
      longitude = environment.Longitude;
    }
    this.map = new maplibregl.Map({
      container: this.mapContainer.nativeElement,
      style: 'https://api.maptiler.com/maps/hybrid/style.json?key=' + environment.MaptilerAPIKey,
      center: [longitude, latitude],
      zoom: zoom,
      pitch: 45,
    });

    this.map.addControl(
      new maplibregl.NavigationControl({
        visualizePitch: true,
        showZoom: true,
        showCompass: true
      })
    );
    this.map.addControl(
      new maplibregl.FullscreenControl({
      })
    );
    this.map.on('load', () => {

      this.map.addSource("HoangSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/HoangSa01.png",
        "coordinates": [
          [111.09665858054495, 17.432475898867523],
          [113.11720985517763, 17.38420482529338],
          [112.79285037220984, 15.643938718432054],
          [110.88537855035554, 15.672592116966598],
        ]
      });
      this.map.addLayer({
        "id": "HoangSa",
        "source": "HoangSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

      this.map.addSource("TruongSa", {
        "type": "image",
        "url": environment.DomainURL + "assets/image/TruongSa01.png",
        "coordinates": [
          [112.32373278444106, 12.236103169381323],
          [117.4620551483049, 11.606334626304161],
          [115.59654957671216, 7.357025445897818],
          [110.62186805246108, 7.811210355974268],


        ]
      });
      this.map.addLayer({
        "id": "TruongSa",
        "source": "TruongSa",
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

    });
  }

  MapLoad() {

    document.getElementById("map").style.height = "500px";

    if (this.CompanyInfoVungTrongService.FormData) {
      let latitude = environment.Latitude;
      let longitude = environment.Longitude;
      if (Number(this.CompanyInfoVungTrongService.FormData.KinhDo) > 0) {
        if (Number(this.CompanyInfoVungTrongService.FormData.ViDo) > 0) {
          latitude = Number(this.CompanyInfoVungTrongService.FormData.ViDo);
          longitude = Number(this.CompanyInfoVungTrongService.FormData.KinhDo);
        }
      }
      this.MapInitialization(longitude, latitude);
      if (latitude <= 90) {
        let popupContent = "<div style='opacity: 0.8; background-color: transparent;'>";
        popupContent = popupContent + "<a style='text-align: center;' class='link-primary form-label' href='#'><h1>" + this.CompanyInfoVungTrongService.FormData.Name + " [" + this.CompanyInfoVungTrongService.FormData.ID + "]</h1></a>";
        popupContent = popupContent + "<div>Đơn vị: <b>" + this.CompanyInfoVungTrongService.FormData.Name + "</b></div>";
        popupContent = popupContent + "<div>Điện thoại: <b>" + this.CompanyInfoVungTrongService.FormData.DaiDienCoSoDienThoai + "</b></div>";
        popupContent = popupContent + "<div>Địa chỉ: <b>" + this.CompanyInfoVungTrongService.FormData.DiaChi + "</b></div>";
        popupContent = popupContent + "</div>";

        let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
          .setMaxWidth("600px");

        var el = document.createElement('div');
        el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/logo_30.png)";
        el.style.width = '30px';
        el.style.height = '30px';

        let marker = new maplibregl.Marker({ element: el })
          .setLngLat([longitude, latitude])
          .setPopup(popup)
          .addTo(this.map);
      }

      if (this.CompanyInfoVungTrongToaDoService.List) {
        if (this.CompanyInfoVungTrongToaDoService.List.length > 0) {
          let listPolygon = [];
          let listToaDoPolygon = [];
          for (let i = 0; i < this.CompanyInfoVungTrongToaDoService.List.length; i++) {
            let CompanyInfoVungTrongToaDo = this.CompanyInfoVungTrongToaDoService.List[i];
            if (CompanyInfoVungTrongToaDo.IsTrungTam == true) {
              var el = document.createElement('div');
              el.style.backgroundColor = "green";
              el.style.width = '10px';
              el.style.height = '10px';

              let marker = new maplibregl.Marker({ element: el })
                .setLngLat([Number(CompanyInfoVungTrongToaDo.KinhDo), Number(CompanyInfoVungTrongToaDo.ViDo)])
                .addTo(this.map);
            }
            else {
              listToaDoPolygon.push([Number(CompanyInfoVungTrongToaDo.KinhDo), Number(CompanyInfoVungTrongToaDo.ViDo)]);
            }
          }
          listPolygon.push(listToaDoPolygon);
          let layerID = 'Layer_CompanyInfoVungTrongToaDo';
          let sourceID = 'Source_CompanyInfoVungTrongToaDo';

          this.map.on('load', () => {
            this.map.addSource(sourceID, {
              'type': 'geojson',
              'data': {
                'type': 'Feature',
                'properties': {
                  "name": "VÙng trồng",
                  "address": "",
                },
                'geometry': {
                  'type': 'Polygon',
                  'coordinates': listPolygon,
                }
              }
            });
            let color = this.DownloadService.GetRandomColor(listPolygon.length);
            this.map.addLayer({
              'id': layerID,
              'type': 'fill',
              'source': sourceID,
              'paint': {
                'fill-color': color,
                'fill-opacity': 0.5,
                'fill-outline-color': color,
              }
            });
          });
        }
      }
    }
  }
}

