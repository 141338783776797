import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatHangXuatKhau } from 'src/app/shared/MatHangXuatKhau.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class MatHangXuatKhauService extends BaseService{
    List: MatHangXuatKhau[] | undefined;
    FormData!: MatHangXuatKhau;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "MatHangXuatKhau";
    }
}

