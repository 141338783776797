import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';

import { DanhMucATTPLoaiHoSo } from 'src/app/shared/DanhMucATTPLoaiHoSo.model';
import { DanhMucATTPLoaiHoSoService } from 'src/app/shared/DanhMucATTPLoaiHoSo.service';
import { DanhMucATTPTinhTrang } from 'src/app/shared/DanhMucATTPTinhTrang.model';
import { DanhMucATTPTinhTrangService } from 'src/app/shared/DanhMucATTPTinhTrang.service';
import { DanhMucATTPXepLoai } from 'src/app/shared/DanhMucATTPXepLoai.model';
import { DanhMucATTPXepLoaiService } from 'src/app/shared/DanhMucATTPXepLoai.service';

import { ProductGroup } from 'src/app/shared/ProductGroup.model';
import { ProductGroupService } from 'src/app/shared/ProductGroup.service';
import { CompanyInfo } from 'src/app/shared/CompanyInfo.model';
import { CompanyInfoService } from 'src/app/shared/CompanyInfo.service';

import { ATTPInfo } from 'src/app/shared/ATTPInfo.model';
import { ATTPInfoService } from 'src/app/shared/ATTPInfo.service';
import { ATTPInfoProductGroups } from 'src/app/shared/ATTPInfoProductGroups.model';
import { ATTPInfoProductGroupsService } from 'src/app/shared/ATTPInfoProductGroups.service';
import { ATTPInfoDocuments } from 'src/app/shared/ATTPInfoDocuments.model';
import { ATTPInfoDocumentsService } from 'src/app/shared/ATTPInfoDocuments.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ViewofficeComponentComponent } from '../viewoffice-component/viewoffice-component.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-attpinfo-detail',
  templateUrl: './attpinfo-detail.component.html',
  styleUrls: ['./attpinfo-detail.component.css']
})
export class ATTPInfoDetailComponent implements OnInit {

  @ViewChild('ATTPInfoProductGroupsSort') ATTPInfoProductGroupsSort: MatSort;
  @ViewChild('ATTPInfoProductGroupsPaginator') ATTPInfoProductGroupsPaginator: MatPaginator;

  @ViewChild('ATTPInfoDocumentsSort') ATTPInfoDocumentsSort: MatSort;
  @ViewChild('ATTPInfoDocumentsPaginator') ATTPInfoDocumentsPaginator: MatPaginator;
  searchSubject: Subject<string> = new Subject();
  companyInfoSearch: string;
  companyInfo: CompanyInfo = new CompanyInfo();
  fileNameCreateNew: string;
  elementContent: ATTPInfoDocuments;
  constructor(
    public dialogRef: MatDialogRef<ATTPInfoDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    private sanitizer: DomSanitizer,

    public DanhMucATTPLoaiHoSoService: DanhMucATTPLoaiHoSoService,
    public DanhMucATTPTinhTrangService: DanhMucATTPTinhTrangService,
    public DanhMucATTPXepLoaiService: DanhMucATTPXepLoaiService,

    public CompanyInfoService: CompanyInfoService,
    public ProductGroupService: ProductGroupService,

    public ATTPInfoService: ATTPInfoService,
    public ATTPInfoProductGroupsService: ATTPInfoProductGroupsService,
    public ATTPInfoDocumentsService: ATTPInfoDocumentsService,

    public ThanhVienService: ThanhVienService,

  ) {
  }

  ngOnInit(): void {
    this.CompanyInfoSearch();
    this.ProductGroupSearch();
    this.DanhMucATTPLoaiHoSoSearch();
    this.DanhMucATTPTinhTrangSearch();
    this.DanhMucATTPXepLoaiSearch();
    this.ATTPInfoDocumentsSearch();
    this.ATTPInfoProductGroupsSearch();
    this.ThanhVienGetLogin();
    // debounce
    this.searchSubject.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(async (searchString) => this.CompanyInfoFilter(searchString))
    ).subscribe(
      (data: any) => {
        this.CompanyInfoService.List = data;
      },
      (error) => {
        console.error('Error fetching company info:', error);
      }
    );
  }

  onSearchChange(searchString: string) {
    this.searchSubject.next(searchString); // Gửi giá trị đến subject
  }

  ThanhVienGetLogin() {
    this.ThanhVienService.GetLogin();
  }

  CompanyInfoSearch() {
    this.CompanyInfoService.ComponentGet000ToListAsync();
  }

  CompanyInfoFilter(searchString: string) {
    if (searchString.trim() === "")
      return;
    this.CompanyInfoService.BaseParameter.ID = environment.InitializationNumber;
    if (this.CompanyInfoService.BaseParameter.ID === null) {
      this.CompanyInfoService.BaseParameter.ID = 0
    }
    this.CompanyInfoService.SearchOrganizationAbsolute(searchString).subscribe(
      res => {
        this.companyInfoSearch = ""
        if (this.CompanyInfoService.List[0]) {
          //planThamDinhCompanies.CompanyInfoID = this.CompanyInfoService.List[0].ID
        } else {
          this.CompanyInfoEmpty();
          setTimeout(() => { // this will make the execution after the above boolean has changed
            //planThamDinhCompanies.CompanyInfoID = 0
          }, 0);
        }
      },
      err => {
        this.companyInfoSearch = ""
      }
    );
  }

  CompanyInfoEmpty() {
    const com = new CompanyInfo();
    com.ID = 0;
    com.Name = '';
    const comlist = []
    comlist.push(com);
    this.CompanyInfoService.List = comlist
    this.CompanyInfoService.ListFilter = comlist
  }

  DanhMucATTPLoaiHoSoSearch() {
    this.DanhMucATTPLoaiHoSoService.ComponentGetAllToListAsync();
  }
  DanhMucATTPTinhTrangSearch() {
    this.DanhMucATTPTinhTrangService.ComponentGetAllToListAsync();
  }
  DanhMucATTPXepLoaiSearch() {
    this.DanhMucATTPXepLoaiService.ComponentGetAllToListAsync();
  }

  ProductGroupSearch() {
    this.ProductGroupService.ComponentGetAllToListAsync();
  }


  DateNgayGhiNhan(value) {
    this.ATTPInfoService.FormData.NgayGhiNhan = new Date(value);
  }

  ATTPInfoDocumentsSearch() {
    this.ATTPInfoService.IsShowLoading = true;
    this.ATTPInfoDocumentsService.BaseParameter.SearchString = this.ATTPInfoService.FormData.Code;
    this.ATTPInfoDocumentsService.GetBySearchStringToListAsync().subscribe(
      res => {
        this.ATTPInfoDocumentsService.List = (res as ATTPInfoDocuments[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ATTPInfoDocumentsService.DataSource = new MatTableDataSource(this.ATTPInfoDocumentsService.List);
        this.ATTPInfoDocumentsService.DataSource.sort = this.ATTPInfoDocumentsSort;
        this.ATTPInfoDocumentsService.DataSource.paginator = this.ATTPInfoDocumentsPaginator;
        this.ATTPInfoService.IsShowLoading = false;
      },
      err => {
        this.ATTPInfoService.IsShowLoading = false;
      }
    );
  }
  ATTPInfoDocumentsSave(element: ATTPInfoDocuments) {
    this.ATTPInfoService.IsShowLoading = true;
    element.ParentID = this.ATTPInfoService.FormData.ID;
    element.Code = this.ATTPInfoService.FormData.Code;
    this.ATTPInfoDocumentsService.FormData = element;
    this.ATTPInfoDocumentsService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.ATTPInfoDocumentsService.FormData = (res as ATTPInfoDocuments);
        element.FileName = this.ATTPInfoDocumentsService.FormData.FileName;
        this.NotificationService.warn(environment.SaveSuccess);
        this.fileNameCreateNew = this.ATTPInfoDocumentsService.FormData.FileName;
        this.ATTPInfoService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.ATTPInfoService.IsShowLoading = false;
      }
    );
  }

  

  ViewFile(element: any) {
    const fileUrl = this.fileNameCreateNew;
    if (fileUrl) {
      const fileExtension = fileUrl.split('.').pop()?.toLowerCase();
      const viewableTypes = ['pdf', 'jpg', 'jpeg', 'png', 'txt'];
      const officeTypes = ['doc', 'docx', 'xls', 'xlsx'];

      if (viewableTypes.includes(fileExtension || '')) {
        this.openPopup(fileUrl);  
      } else if (officeTypes.includes(fileExtension || '')) {
        const viewerUrl = `https://docs.google.com/gview?url=${fileUrl}&embedded=true`;
        this.openPopup(viewerUrl);
      } else {
        const a = document.createElement('a');
        a.href = fileUrl;
        a.download = fileUrl.split('/').pop() || 'download';
        a.click();
      }
    }
  }

  openPopup(fileUrl: string) {
    this.dialog.open(ViewofficeComponentComponent, {
      data: { fileUrl: fileUrl },
      width: '100%',  
      height: '100%',
      panelClass: 'full-screen-dialog',
    });
  }

  ChangeFileName(element: ATTPInfoDocuments, files: FileList) {
    if (files) {
      this.ATTPInfoDocumentsService.FileToUpload = files;
      this.ATTPInfoDocumentsSave(element);
      // this.ATTPInfoDocumentsSearch();
      // this.fileNameCreateNew = this.ATTPInfoDocumentsService.FormData.FileName;
    }
  }

  // ChangeFileNameContentEdit(element: ATTPInfoDocuments, files: FileList) {
  //   if (files) {
  //     this.ATTPInfoDocumentsService.FileToUpload = files;
  //     this.ATTPInfoDocumentsSave(element);
  //     // this.ATTPInfoDocumentsSearch();
  //     // this.fileNameCreateNew = this.ATTPInfoDocumentsService.FormData.FileName;
  //   }
  // }

  ATTPInfoProductGroupsSearch() {
    this.ATTPInfoService.IsShowLoading = true;
    this.ATTPInfoProductGroupsService.BaseParameter.SearchString = this.ATTPInfoService.FormData.Code;
    this.ATTPInfoProductGroupsService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.ATTPInfoProductGroupsService.List = (res as ATTPInfoProductGroups[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ATTPInfoProductGroupsService.DataSource = new MatTableDataSource(this.ATTPInfoProductGroupsService.List);
        this.ATTPInfoProductGroupsService.DataSource.sort = this.ATTPInfoProductGroupsSort;
        this.ATTPInfoProductGroupsService.DataSource.paginator = this.ATTPInfoProductGroupsPaginator;
        this.ATTPInfoService.IsShowLoading = false;
      },
      err => {
        this.ATTPInfoService.IsShowLoading = false;
      }
    );
  }
  ATTPInfoProductGroupsSave(element: ATTPInfoProductGroups) {
    this.ATTPInfoService.IsShowLoading = true;
    element.ParentID = this.ATTPInfoService.FormData.ID;
    element.Code = this.ATTPInfoService.FormData.Code;
    this.ATTPInfoProductGroupsService.FormData = element;
    this.ATTPInfoProductGroupsService.SaveAsync().subscribe(
      res => {
        this.ATTPInfoProductGroupsSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.ATTPInfoService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.ATTPInfoService.IsShowLoading = false;
      }
    );
  }
  ATTPInfoProductGroupsDelete(element: ATTPInfoProductGroups) {
    this.ATTPInfoService.IsShowLoading = true;
    this.ATTPInfoProductGroupsService.BaseParameter.ID = element.ID;
    this.ATTPInfoProductGroupsService.RemoveAsync().subscribe(
      res => {
        this.ATTPInfoProductGroupsSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.ATTPInfoService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.ATTPInfoService.IsShowLoading = false;
      }
    );
  }

  ATTPInfoSave() {
    //this.ATTPInfoService.FormData.ParentID = this.ThanhVienService.FormDataLogin.CompanyInfoID;
    this.NotificationService.warn(this.ATTPInfoService.ComponentSaveForm());
    this.Close();
  }

  Close() {
    this.dialogRef.close();
  }

}
