import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';

import { DanhMucChucDanh } from 'src/app/shared/DanhMucChucDanh.model';
import { DanhMucChucDanhService } from 'src/app/shared/DanhMucChucDanh.service';
import { DistrictData } from 'src/app/shared/DistrictData.model';
import { DistrictDataService } from 'src/app/shared/DistrictData.service';
import { DanhMucThoiGianLayMau } from 'src/app/shared/DanhMucThoiGianLayMau.model';
import { DanhMucThoiGianLayMauService } from 'src/app/shared/DanhMucThoiGianLayMau.service';
import { DanhMucLayMau } from 'src/app/shared/DanhMucLayMau.model';
import { DanhMucLayMauService } from 'src/app/shared/DanhMucLayMau.service';
import { DanhMucLayMauChiTieu } from 'src/app/shared/DanhMucLayMauChiTieu.model';
import { DanhMucLayMauChiTieuService } from 'src/app/shared/DanhMucLayMauChiTieu.service';
import { DanhMucLayMauPhanLoai } from 'src/app/shared/DanhMucLayMauPhanLoai.model';
import { DanhMucLayMauPhanLoaiService } from 'src/app/shared/DanhMucLayMauPhanLoai.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { CompanyInfo } from 'src/app/shared/CompanyInfo.model';
import { CompanyInfoService } from 'src/app/shared/CompanyInfo.service';

import { PlanThamDinh } from 'src/app/shared/PlanThamDinh.model';
import { PlanThamDinhService } from 'src/app/shared/PlanThamDinh.service';
import { PlanThamDinhCompanies } from 'src/app/shared/PlanThamDinhCompanies.model';
import { PlanThamDinhCompaniesService } from 'src/app/shared/PlanThamDinhCompanies.service';
import { PlanThamDinhThanhVien } from 'src/app/shared/PlanThamDinhThanhVien.model';
import { PlanThamDinhThanhVienService } from 'src/app/shared/PlanThamDinhThanhVien.service';
import { PlanThamDinhDistrictData } from 'src/app/shared/PlanThamDinhDistrictData.model';
import { PlanThamDinhDistrictDataService } from 'src/app/shared/PlanThamDinhDistrictData.service';
import { PlanThamDinhCompanyDocument } from 'src/app/shared/PlanThamDinhCompanyDocument.model';
import { PlanThamDinhCompanyDocumentService } from 'src/app/shared/PlanThamDinhCompanyDocument.service';
import { PlanThamDinhDanhMucLayMau } from 'src/app/shared/PlanThamDinhDanhMucLayMau.model';
import { PlanThamDinhDanhMucLayMauService } from 'src/app/shared/PlanThamDinhDanhMucLayMau.service';


import { DanhMucLayMauChiTieuDetailComponent } from '../danh-muc-lay-mau-chi-tieu-detail/danh-muc-lay-mau-chi-tieu-detail.component';
import { DanhMucLayMauDetailComponent } from '../danh-muc-lay-mau-detail/danh-muc-lay-mau-detail.component';
import { PlanThamDinhDanhMucLayMauDetailGiamSatDuLuongComponent } from '../plan-tham-dinh-danh-muc-lay-mau-detail-giam-sat-du-luong/plan-tham-dinh-danh-muc-lay-mau-detail-giam-sat-du-luong.component';
import { PlanThamDinhCompanyDocumentDetailComponent } from '../plan-tham-dinh-company-document-detail/plan-tham-dinh-company-document-detail.component';
import { ThanhVienDetailComponent } from '../thanh-vien-detail/thanh-vien-detail.component';
import { PlanThamDinhDanhMucLayMauDetailAnToanThucPhamSauThuHoachComponent } from '../plan-tham-dinh-danh-muc-lay-mau-detail-an-toan-thuc-pham-sau-thu-hoach/plan-tham-dinh-danh-muc-lay-mau-detail-an-toan-thuc-pham-sau-thu-hoach.component';
import { find } from 'rxjs/operators';
import * as e from 'express';

@Component({
  selector: 'app-plan-tham-dinh-detail-an-toan-thuc-pham-sau-thu-hoach001',
  templateUrl: './plan-tham-dinh-detail-an-toan-thuc-pham-sau-thu-hoach001.component.html',
  styleUrls: ['./plan-tham-dinh-detail-an-toan-thuc-pham-sau-thu-hoach001.component.css']
})
export class PlanThamDinhDetailAnToanThucPhamSauThuHoach001Component implements OnInit {

  @ViewChild('PlanThamDinhDanhMucLayMauSort') PlanThamDinhDanhMucLayMauSort: MatSort;
  @ViewChild('PlanThamDinhDanhMucLayMauPaginator') PlanThamDinhDanhMucLayMauPaginator: MatPaginator;

  @ViewChild('PlanThamDinhDanhMucLayMauSort001') PlanThamDinhDanhMucLayMauSort001: MatSort;
  @ViewChild('PlanThamDinhDanhMucLayMauPaginator001') PlanThamDinhDanhMucLayMauPaginator001: MatPaginator;

  @ViewChild('PlanThamDinhDanhMucLayMauSort002') PlanThamDinhDanhMucLayMauSort002: MatSort;
  @ViewChild('PlanThamDinhDanhMucLayMauPaginator002') PlanThamDinhDanhMucLayMauPaginator002: MatPaginator;

  @ViewChild('PlanThamDinhCompanyDocumentSort') PlanThamDinhCompanyDocumentSort: MatSort;
  @ViewChild('PlanThamDinhCompanyDocumentPaginator') PlanThamDinhCompanyDocumentPaginator: MatPaginator;

  @ViewChild('PlanThamDinhThanhVienSort') PlanThamDinhThanhVienSort: MatSort;
  @ViewChild('PlanThamDinhThanhVienPaginator') PlanThamDinhThanhVienPaginator: MatPaginator;

  @ViewChild('fileInputPoisoningNotice', { static: false }) fileInputPoisoningNotice!: ElementRef;
  @ViewChild('fileInputDecision', { static: false }) fileInputDecision!: ElementRef;
  @ViewChild('fileInputPlan', { static: false }) fileInputPlan!: ElementRef;
  
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<PlanThamDinhDetailAnToanThucPhamSauThuHoach001Component>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,

    public DanhMucChucDanhService: DanhMucChucDanhService,
    public DanhMucThoiGianLayMauService: DanhMucThoiGianLayMauService,
    public DanhMucLayMauPhanLoaiService: DanhMucLayMauPhanLoaiService,
    public DistrictDataService: DistrictDataService,
    public DanhMucLayMauService: DanhMucLayMauService,
    public DanhMucLayMauChiTieuService: DanhMucLayMauChiTieuService,

    public ThanhVienService: ThanhVienService,
    public CompanyInfoService: CompanyInfoService,

    public PlanThamDinhService: PlanThamDinhService,
    public PlanThamDinhCompaniesService: PlanThamDinhCompaniesService,
    public PlanThamDinhThanhVienService: PlanThamDinhThanhVienService,
    public PlanThamDinhDistrictDataService: PlanThamDinhDistrictDataService,
    public PlanThamDinhCompanyDocumentService: PlanThamDinhCompanyDocumentService,
    public PlanThamDinhDanhMucLayMauService: PlanThamDinhDanhMucLayMauService,

  ) { }

  ngOnInit(): void {
    this.DanhMucThoiGianLayMauSearch();
    this.DanhMucLayMauSearch();
    this.DanhMucLayMauChiTieuSearch();
    this.DistrictDataSearch();
    this.ThanhVienSearch();
    this.DanhMucChucDanhSearch();
    this.DanhMucLayMauPhanLoaiSearch();
    this.CompanyInfoSearch();
    this.PlanThamDinhDanhMucLayMauSearch();
    this.PlanThamDinhCompanyDocumentSearch();
    this.PlanThamDinhThanhVienSearch();
  }
 
  isPDFFile(fileName: string): boolean {
    return /\.pdf$/i.test(fileName);
  }
  isWordOrExcelFile(fileName: string): boolean {
    return /\.(doc|docx|xlsx)$/i.test(fileName);
  }
  isHtmlFile(fileName: string): boolean {
    return /\.(html)$/i.test(fileName);
  }
  
  getViewerUrl(fileName: string): string {
    if (this.isPDFFile(fileName) || this.isHtmlFile(fileName)) {
      return fileName;
    } else if (this.isWordOrExcelFile(fileName)) {
      return `https://docs.google.com/viewer?url=${encodeURIComponent(fileName)}&embedded=true`;
    }
    return fileName; // Mặc định
  }
  ChangeFileName1(documentTemplateID: number, files: FileList | null) {
    if (files && files.length > 0) {
      this.PlanThamDinhCompanyDocumentService.FileToUpload = files;
      this.PlanThamDinhCompanyDocumentsSave(documentTemplateID)
    }
  }
  PoisoningNoticeDoc:PlanThamDinhCompanyDocument
  DecisionDoc:PlanThamDinhCompanyDocument
  PlanDoc:PlanThamDinhCompanyDocument
  
  SHOWRESULT :boolean = environment.ShowResult

  DateNgayBatDau(value) {
    this.PlanThamDinhService.FormData.NgayBatDau = new Date(value);
  }
  DateNgayKetThuc(value) {
    this.PlanThamDinhService.FormData.NgayKetThuc = new Date(value);
  }
  DateNgayGuiMau(value) {
    this.PlanThamDinhService.FormData.NgayGuiMau = new Date(value);
  }
  DatePlanThamDinhDanhMucLayMauNgayGhiNhan(element: PlanThamDinhDanhMucLayMau, value) {
    element.NgayGhiNhan = new Date(value);
  }
  DanhMucChucDanhSearch() {
    this.DanhMucChucDanhService.ComponentGetAllToListAsync();
  }
  DanhMucThoiGianLayMauSearch() {
    this.DanhMucThoiGianLayMauService.ComponentGetAllToListAsync();
  }
  DanhMucLayMauSearch() {
    this.DanhMucLayMauService.ComponentGetAllToListRefreshAsync();
  }
  DanhMucLayMauChiTieuSearch() {
    this.DanhMucLayMauChiTieuService.ComponentGetAllToListRefreshAsync();
  }
  DistrictDataSearch() {
    this.DistrictDataService.ComponentGetAllToListAsync();
  }
  DanhMucLayMauPhanLoaiSearch() {
    this.DanhMucLayMauPhanLoaiService.ComponentGetAllToListAsync();
  }
  ThanhVienSearch() {
    this.ThanhVienService.BaseParameter.StateAgencyID = environment.StateAgencyIDChiCucQLCLNongLamThuySan;
    this.ThanhVienService.BaseParameter.Active = true;
    this.ThanhVienService.ComponentGetByStateAgencyID_ActiveToListAsync();
  }
  DanhMucLayMauAdd(ID: number) {
    this.PlanThamDinhCompaniesService.IsShowLoading = true;
    this.DanhMucLayMauService.BaseParameter.ID = ID;
    this.DanhMucLayMauService.GetByIDAsync().subscribe(
      res => {
        this.DanhMucLayMauService.FormData = res as DanhMucLayMau;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(DanhMucLayMauDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.DanhMucLayMauSearch();
        });
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
      }
    );
  }
  DanhMucLayMauChiTieuAdd(ID: number) {
    this.PlanThamDinhCompaniesService.IsShowLoading = true;
    this.DanhMucLayMauChiTieuService.BaseParameter.ID = ID;
    this.DanhMucLayMauChiTieuService.GetByIDAsync().subscribe(
      res => {
        this.DanhMucLayMauChiTieuService.FormData = res as DanhMucLayMauChiTieu;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(DanhMucLayMauChiTieuDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.DanhMucLayMauChiTieuSearch();
        });
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhThanhVienSearch() {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhThanhVienService.BaseParameter.SearchString = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhThanhVienService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.PlanThamDinhThanhVienService.List = (res as PlanThamDinhThanhVien[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.PlanThamDinhThanhVienService.DataSource = new MatTableDataSource(this.PlanThamDinhThanhVienService.List);
        this.PlanThamDinhThanhVienService.DataSource.sort = this.PlanThamDinhThanhVienSort;
        this.PlanThamDinhThanhVienService.DataSource.paginator = this.PlanThamDinhThanhVienPaginator;
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhThanhVienSave(element: PlanThamDinhThanhVien) {
    this.PlanThamDinhService.IsShowLoading = true;
    element.ParentID = this.PlanThamDinhService.FormData.ID;
    element.Code = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhThanhVienService.FormData = element;
    this.PlanThamDinhThanhVienService.SaveAsync().subscribe(
      res => {
        this.PlanThamDinhThanhVienSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhThanhVienDelete(element: PlanThamDinhThanhVien) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhThanhVienService.BaseParameter.ID = element.ID;
    this.PlanThamDinhThanhVienService.RemoveAsync().subscribe(
      res => {
        this.PlanThamDinhThanhVienSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }

  PlanThamDinhDanhMucLayMauSearch() {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhDanhMucLayMauService.BaseParameter.SearchString = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhDanhMucLayMauService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.PlanThamDinhDanhMucLayMauService.List = (res as PlanThamDinhDanhMucLayMau[]).sort((a, b) => (a.CreatedDate < b.CreatedDate ? 1 : -1)).filter(p=>p.IsClone != true);
        this.listClone = [...this.PlanThamDinhDanhMucLayMauService.List.filter(p=>p.ID!=0)]
        const idZeroItem = this.PlanThamDinhDanhMucLayMauService.List.filter(p => p.ID === 0);
        const otherItems = this.PlanThamDinhDanhMucLayMauService.List.filter(p => p.ID !== 0);
        idZeroItem.filter(p=>p.SoLuongLayMau = null);
        
        // Đưa phần tử ID == 0 lên đầu (nếu tồn tại)
        this.PlanThamDinhDanhMucLayMauService.List = [...idZeroItem, ...otherItems];
        this.PlanThamDinhDanhMucLayMauService.ListDanhMucLayMau = [];
        this.PlanThamDinhDanhMucLayMauService.ListDanhMucLayMau = this.PlanThamDinhDanhMucLayMauService.List;
        // for (let i = 0; i < this.PlanThamDinhDanhMucLayMauService.List.length; i++) {
        //   let PlanThamDinhDanhMucLayMau = this.PlanThamDinhDanhMucLayMauService.List[i];
        //   this.PlanThamDinhDanhMucLayMauService.ListDanhMucLayMau.push(PlanThamDinhDanhMucLayMau);
        //   // if (PlanThamDinhDanhMucLayMau.IsGoiY == false) {
        //   //   this.PlanThamDinhDanhMucLayMauService.ListDanhMucLayMau.push(PlanThamDinhDanhMucLayMau);
        //   // }
        //   // if (PlanThamDinhDanhMucLayMau.ID == environment.InitializationNumber) {
        //   //   this.PlanThamDinhDanhMucLayMauService.ListDanhMucLayMau.push(PlanThamDinhDanhMucLayMau);
        //   // }
        // }
        this.PlanThamDinhDanhMucLayMauService.DataSource = new MatTableDataSource(this.PlanThamDinhDanhMucLayMauService.List);
        this.PlanThamDinhDanhMucLayMauService.DataSource.sort = this.PlanThamDinhDanhMucLayMauSort;
        this.PlanThamDinhDanhMucLayMauService.DataSource.paginator = this.PlanThamDinhDanhMucLayMauPaginator;

        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
    this.PlanThamDinhDanhMucLayMauService.GetBySearchStringToListAsync().subscribe(
      res => {
        this.PlanThamDinhDanhMucLayMauService.ListFilter = (res as PlanThamDinhDanhMucLayMau[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.PlanThamDinhDanhMucLayMauService.ListDistrictData = [];

        this.listClone1 = [...this.PlanThamDinhDanhMucLayMauService.ListFilter.filter(p=>p.ID!=0 && p.IsClone == true)]
        for (let i = 0; i < this.PlanThamDinhDanhMucLayMauService.ListFilter.length; i++) {
          let PlanThamDinhDanhMucLayMau = this.PlanThamDinhDanhMucLayMauService.ListFilter[i];
          if (PlanThamDinhDanhMucLayMau.ID > 0) {
            let ListDistrictData = this.PlanThamDinhDanhMucLayMauService.ListDistrictData.filter(item => item.DistrictDataID == PlanThamDinhDanhMucLayMau.DistrictDataID);
            if (ListDistrictData.length == 0) {
              this.PlanThamDinhDanhMucLayMauService.ListDistrictData.push(PlanThamDinhDanhMucLayMau);
            }
          }
        }
        this.PlanThamDinhDanhMucLayMauService.DataSource001 = new MatTableDataSource(this.PlanThamDinhDanhMucLayMauService.ListDistrictData);
        this.PlanThamDinhDanhMucLayMauService.DataSource001.sort = this.PlanThamDinhDanhMucLayMauSort001;
        this.PlanThamDinhDanhMucLayMauService.DataSource001.paginator = this.PlanThamDinhDanhMucLayMauPaginator001;

        let sortedList = this.listClone1.sort((a, b) => {
          if (a.ProductUnitID > b.ProductUnitID) return 1;
          if (a.ProductUnitID < b.ProductUnitID) return -1;
        
          // Khi a.ProductUnitID === b.ProductUnitID, kiểm tra SortOrder
          if (a.SortOrder === -1 && b.SortOrder !== -1) return -1;
          if (a.SortOrder !== -1 && b.SortOrder === -1) return 1;
        
          return 0; // Giữ nguyên thứ tự nếu không có điều kiện khác
        });
        sortedList.forEach(element => {
          if(element.CompanyInfoID!=null && element.CompanyInfoID!=0 && element.SortOrder === -1){
            this.CompanyInfoFilter(element.CompanyInfoID+"",element);
          }
        });
        // let sortedList = this.listClone1.sort((a, b) => (a.ProductUnitID > b.ProductUnitID ? 1 : -1))
        this.PlanThamDinhDanhMucLayMauService.DataSource002 = new MatTableDataSource(sortedList);
        this.PlanThamDinhDanhMucLayMauService.DataSource002.sort = this.PlanThamDinhDanhMucLayMauSort002;
        this.PlanThamDinhDanhMucLayMauService.DataSource002.paginator = this.PlanThamDinhDanhMucLayMauPaginator002;

        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }

  getTotal(column: string): number {
    if(this.PlanThamDinhDanhMucLayMauService.ListDanhMucLayMau == null || this.PlanThamDinhDanhMucLayMauService.ListDanhMucLayMau == undefined)
      return 0;
    return this.PlanThamDinhDanhMucLayMauService.ListDanhMucLayMau
      .map((item: any) => item[column] || 0)
      .reduce((acc: number, value: number) => acc + value, 0);
  }
  FindNameByID(ID:number,list:any[]):string{
    return list.find(l=>l.ID == ID).Name;
  }
  FindObjectByID(ID:number,list:any[]):string{
    return list.find(l=>l.ID == ID);
  }
  listClone : PlanThamDinhDanhMucLayMau[]
  listClone1 : PlanThamDinhDanhMucLayMau[]
  
  CompanyInfoSearch() {
    this.CompanyInfoService.ComponentGet000ToListAsync();
  }

  CompanyInfoFilter(searchString: string,element: PlanThamDinhDanhMucLayMau) {
    if(searchString=='')
      return;
    this.ThanhVienService.IsShowLoading = true;
    this.CompanyInfoService.Filter000Asyn(searchString).subscribe(
      res => {
        element.ListCompanyInfo = this.CompanyInfoService.List;
        this.ThanhVienService.IsShowLoading = false;
      },
      err => {
        element.SearchCompany = '';
        this.ThanhVienService.IsShowLoading = false;
      }
    );
    // this.CompanyInfoService.Filter000(searchString);
  }
  CompanyInfoChange(element: PlanThamDinhDanhMucLayMau){
    // element.CompanyInfoID = event.value;
    const companyInfoSetData = this.FindObjectByID(element.CompanyInfoID, element.ListCompanyInfo) as CompanyInfo;
    element.CompanyInfoName = companyInfoSetData.Name;
    element.Description = companyInfoSetData.address;
    this.listClone1.forEach(e => {
      if(e.DistrictDataID == element.DistrictDataID && 
          e.DanhMucLayMauPhanLoaiID == element.DanhMucLayMauPhanLoaiID && 
          e.DanhMucLayMauID == element.DanhMucLayMauID && e.ProductUnitID == element.ProductUnitID)
      {
        e.CompanyInfoID = element.CompanyInfoID;
        e.CompanyInfoName = element.CompanyInfoName;
      }
    });
  }
  onCloseSelect(element: any): void {
    element.IsSelectFocus = false;
    console.log('mat-select lost focus');
  }
  SaveCompany(element){
    this.PlanThamDinhService.IsShowLoading = true;
    let planThamDinhDanhMucLayMauList = this.listClone1.filter(e => 
          e.DistrictDataID == element.DistrictDataID && 
          e.DanhMucLayMauPhanLoaiID == element.DanhMucLayMauPhanLoaiID && 
          e.DanhMucLayMauID == element.DanhMucLayMauID &&
          e.ProductUnitID == element.ProductUnitID);
    this.PlanThamDinhDanhMucLayMauService.SaveListAsync(planThamDinhDanhMucLayMauList).subscribe(
      res => {
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  CopyKeHoach(element: PlanThamDinhDanhMucLayMau){
    let copyPlan = this.PlanThamDinhDanhMucLayMauService.List.find(p=>p.ID == 0);
    copyPlan.DistrictDataID = element.DistrictDataID;
    copyPlan.DanhMucLayMauPhanLoaiID = element.DanhMucLayMauPhanLoaiID;
    copyPlan.DanhMucLayMauID = element.DanhMucLayMauID;
    copyPlan.DanhMucLayMauChiTieuID = element.DanhMucLayMauChiTieuID;
  }
  PlanThamDinhDanhMucLayMauSave(element: PlanThamDinhDanhMucLayMau) {
    let planThamDinhDanhMucLayMauList = [];
    this.PlanThamDinhService.IsShowLoading = true;
    element.ParentID = this.PlanThamDinhService.FormData.ID;
    element.Code = this.PlanThamDinhService.FormData.Code;
    element.DistrictDataName = this.FindNameByID(element.DistrictDataID,this.DistrictDataService.List);
    element.DanhMucLayMauPhanLoaiName = this.FindNameByID(element.DanhMucLayMauPhanLoaiID,this.DanhMucLayMauPhanLoaiService.List);
    element.DanhMucLayMauName = this.FindNameByID(element.DanhMucLayMauID,this.DanhMucLayMauService.List);
    element.DanhMucLayMauChiTieuName = this.FindNameByID(element.DanhMucLayMauChiTieuID,this.DanhMucLayMauChiTieuService.List);
    let findMainOfPlan = this.PlanThamDinhDanhMucLayMauService.ListDanhMucLayMau.find(
      p=>p.DistrictDataID == element.DistrictDataID && 
      p.DanhMucLayMauPhanLoaiID == element.DanhMucLayMauPhanLoaiID && 
      p.DanhMucLayMauID == element.DanhMucLayMauID && 
      p.SoLuongLayMau != null &&  
      p.SoLuongLayMau != undefined &&
      p.SoLuongLayMau > 0 && 
      p.ID > 0 &&
      (element.ID == 0 || element.ID != p.ID));
    let  target = this.PlanThamDinhDanhMucLayMauService.ListDanhMucLayMau.find(
      p=>p.DistrictDataID == element.DistrictDataID && 
      p.DanhMucLayMauPhanLoaiID == element.DanhMucLayMauPhanLoaiID && 
      p.DanhMucLayMauID == element.DanhMucLayMauID &&
      p.DanhMucLayMauChiTieuID == element.DanhMucLayMauChiTieuID &&
      p.ID > 0 &&
      (element.ID == 0 || element.ID != p.ID));
    
    // const maxCompanyInfoID = Math.max(...this.listClone1.map(item => item.ProductUnitID || 0)) || 0;
    const filterPlan = this.listClone1.filter(p=>p.DistrictDataID == element.DistrictDataID && 
      p.DanhMucLayMauPhanLoaiID == element.DanhMucLayMauPhanLoaiID && 
      p.DanhMucLayMauID == element.DanhMucLayMauID);
    const maxCompanyInfoID = filterPlan.length > 0 
    ? Math.max(...filterPlan.map(item => item.ProductUnitID || 0)) 
    : 0;
    const maxCompanyInfoID1 = this.listClone1.length > 0 
    ? Math.max(...this.listClone1.map(item => item.ProductUnitID || 0)) 
    : 0;
    const minCompanyInfoID = filterPlan.length > 0 
    ? Math.min(...filterPlan.filter(p=>p.DistrictDataID == element.DistrictDataID && 
      p.DanhMucLayMauPhanLoaiID == element.DanhMucLayMauPhanLoaiID && 
      p.DanhMucLayMauID == element.DanhMucLayMauID).map(item => item.ProductUnitID || 0)) 
    : 0;
    if(element.SoLuongLayMau>0){
      // thêm số lượng lấy mẫu result
      if (findMainOfPlan){ // đã có trước đó nên lấy số lượng hiện tại vừa thêm để tăng số lượng
        //cộng dồn số lượng
        findMainOfPlan.SoLuongLayMau += element.SoLuongLayMau;
        planThamDinhDanhMucLayMauList.push(findMainOfPlan);
        const listDanhMucLayMauChiTieuID = this.listClone
        .filter((p) => 
          p.DistrictDataID == element.DistrictDataID && 
          p.DanhMucLayMauPhanLoaiID == element.DanhMucLayMauPhanLoaiID && 
          p.DanhMucLayMauID == element.DanhMucLayMauID
        )
        .map((p) => ({
          DanhMucLayMauChiTieuID: parseInt(p.DanhMucLayMauChiTieuID + "", 10) || 0, // Chuyển về số nguyên, mặc định là 0 nếu không hợp lệ
          DanhMucLayMauChiTieuName: String(p.DanhMucLayMauChiTieuName || '') // Chuyển về chuỗi, mặc định là chuỗi rỗng nếu không hợp lệ
        }));

        const countSumChiTieu = listDanhMucLayMauChiTieuID.length;
        let comIdTemp = maxCompanyInfoID+1
        // thêm số lượng cho lấy mẫu
        for (let i = 0; i < element.SoLuongLayMau; i++) {
          for(let j = 0;j< countSumChiTieu;j++){
            const clonedElement = { ...element }; // Shallow copy
            if(j==0) clonedElement.SortOrder = -1
            clonedElement.IsClone = true;
            clonedElement.SoLuongLayMau = 0;
            clonedElement.ProductUnitID = comIdTemp;
            clonedElement.ID = 0;
            clonedElement.DanhMucLayMauChiTieuID = listDanhMucLayMauChiTieuID[j].DanhMucLayMauChiTieuID;
            clonedElement.DanhMucLayMauChiTieuName = listDanhMucLayMauChiTieuID[j].DanhMucLayMauChiTieuName;
            planThamDinhDanhMucLayMauList.push(clonedElement);
          }
          comIdTemp++;
        }

        comIdTemp = maxCompanyInfoID+1

        //đã có chỉ tiêu xét nghiệm
        if(target){
          target.SoLuongChiTieu += element.SoLuongChiTieu;
          let planThamDinhDanhMucLayMau = planThamDinhDanhMucLayMauList.find(p=>p.ID == target.ID);
          if(planThamDinhDanhMucLayMau){
            planThamDinhDanhMucLayMau.SoLuongChiTieu = target.SoLuongChiTieu;
          }else{
            planThamDinhDanhMucLayMauList.push(target);
          }
        }else{
          element.SoLuongLayMau = 0;
          // element.SortOrder = findMainOfPlan.SortOrder
          planThamDinhDanhMucLayMauList.push(element);

          // thêm số lượng cho chỉ tiêu mới
          let numPlus = minCompanyInfoID;
          for (let i = 0; i < findMainOfPlan.SoLuongLayMau; i++) {
            const clonedElement = { ...element }; // Shallow copy
            clonedElement.IsClone = true;
            clonedElement.SoLuongLayMau = 0;
            clonedElement.ID = 0;
            clonedElement.ProductUnitID = numPlus;
            numPlus++;
            planThamDinhDanhMucLayMauList.push(clonedElement);
          }
        }
      }
      else{ //trước đó chưa có
        let comIdTemp = maxCompanyInfoID1+1
        for (let i = 0; i < element.SoLuongLayMau; i++) {
          const clonedElement = { ...element }; // Shallow copy
          clonedElement.IsClone = true;
          clonedElement.SoLuongLayMau = 0;
          clonedElement.ID = 0;
          clonedElement.ProductUnitID = comIdTemp;
          clonedElement.SortOrder = -1
          comIdTemp++;
          planThamDinhDanhMucLayMauList.push(clonedElement);
        }
        
        planThamDinhDanhMucLayMauList.push(element);
      }
    }
    else{// thêm chỉ tiêu 
      if (target){//đã có chỉ tiêu xét nghiệm
        target.SoLuongChiTieu += element.SoLuongChiTieu;
        let planThamDinhDanhMucLayMau = planThamDinhDanhMucLayMauList.find(p=>p.ID == target.ID);
        if(planThamDinhDanhMucLayMau){
          planThamDinhDanhMucLayMau.SoLuongChiTieu = target.SoLuongChiTieu;
        }else{
          planThamDinhDanhMucLayMauList.push(target);
        }
      }
      else {
        if(element.ID>0){// nếu là sửa
          planThamDinhDanhMucLayMauList.push(element);
        }
        else{//nếu là thêm mới chỉ tiêu xét nghiệm
          let numPlus = minCompanyInfoID;
          planThamDinhDanhMucLayMauList.push(element);
          if(findMainOfPlan){
            for (let i = 0; i < findMainOfPlan.SoLuongLayMau; i++) {
              const clonedElement = { ...element };
              clonedElement.IsClone = true
              clonedElement.SoLuongLayMau = 0;
              clonedElement.ID = 0;
              clonedElement.ProductUnitID = numPlus;
              numPlus++;
              planThamDinhDanhMucLayMauList.push(clonedElement);
            }
          }
        }
      }
    }
    this.PlanThamDinhDanhMucLayMauService.SaveListAsync(planThamDinhDanhMucLayMauList).subscribe(
      res => {
        this.PlanThamDinhDanhMucLayMauSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
    
  }
  PlanThamDinhDanhMucLayMauDelete(element: PlanThamDinhDanhMucLayMau) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhDanhMucLayMauService.BaseParameter.ID = element.ID;
    this.PlanThamDinhDanhMucLayMauService.RemoveAsync().subscribe(
      res => {
        this.PlanThamDinhDanhMucLayMauSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhDanhMucLayMauAdd(ID: number) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhDanhMucLayMauService.BaseParameter.ID = ID;
    this.PlanThamDinhDanhMucLayMauService.GetByIDAsync().subscribe(
      res => {
        this.PlanThamDinhDanhMucLayMauService.FormData = res as PlanThamDinhDanhMucLayMau;
        this.PlanThamDinhDanhMucLayMauService.FormData.ParentID = this.PlanThamDinhService.FormData.ID;
        this.PlanThamDinhDanhMucLayMauService.FormData.Code = this.PlanThamDinhService.FormData.Code;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(PlanThamDinhDanhMucLayMauDetailAnToanThucPhamSauThuHoachComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.PlanThamDinhDanhMucLayMauSearch();
        });
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhDanhMucLayMauCopy(element: PlanThamDinhDanhMucLayMau) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhDanhMucLayMauService.FormData = element;
    this.PlanThamDinhDanhMucLayMauService.FormData.ID = environment.InitializationNumber;
    this.PlanThamDinhDanhMucLayMauService.SaveAsync().subscribe(
      res => {
        this.PlanThamDinhDanhMucLayMauService.FormData = res as PlanThamDinhDanhMucLayMau;
        this.PlanThamDinhDanhMucLayMauSearch();
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhSave() {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhService.FormData.ParentID = environment.PlanTypeIDAnToanThucPhamSauThuHoach;
    this.PlanThamDinhService.FormData.Active = true;
    this.PlanThamDinhService.SaveAsync().subscribe(
      res => {
        this.PlanThamDinhService.FormData = res as PlanThamDinh;
        this.PlanThamDinhDanhMucLayMauSearch();
        this.PlanThamDinhCompanyDocumentSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompanyDocumentSearch() {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhCompanyDocumentService.BaseParameter.SearchString = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhCompanyDocumentService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyDocumentService.List = (res as PlanThamDinhCompanyDocument[]).sort((a, b) => (a.SortOrder < b.SortOrder ? 1 : -1));
        this.PoisoningNoticeDoc = this.PlanThamDinhCompanyDocumentService.List.find(i=>i.IsUploadConfirm === true && i.DocumentTemplateID === 62);
        this.DecisionDoc = this.PlanThamDinhCompanyDocumentService.List.find(i=>i.IsUploadConfirm === true && i.DocumentTemplateID === 60);
        this.PlanDoc = this.PlanThamDinhCompanyDocumentService.List.find(i=>i.IsUploadConfirm === true && i.DocumentTemplateID === 61);
        this.PlanThamDinhCompanyDocumentService.List = this.PlanThamDinhCompanyDocumentService.List.filter(i => i.IsUploadConfirm !== true);
        this.PlanThamDinhCompanyDocumentService.DataSource = new MatTableDataSource(this.PlanThamDinhCompanyDocumentService.List);
        this.PlanThamDinhCompanyDocumentService.DataSource.sort = this.PlanThamDinhCompanyDocumentSort;
        this.PlanThamDinhCompanyDocumentService.DataSource.paginator = this.PlanThamDinhCompanyDocumentPaginator;
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompanyDocumentSave(element: PlanThamDinhCompanyDocument) {
    this.PlanThamDinhService.IsShowLoading = true;
    element.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;
    element.Code = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhCompanyDocumentService.FormData = element;
    this.PlanThamDinhCompanyDocumentService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyDocumentSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompanyDocumentDelete(element: PlanThamDinhCompanyDocument) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhCompanyDocumentService.BaseParameter.ID = element.ID;
    this.PlanThamDinhCompanyDocumentService.RemoveAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyDocumentSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  
  PlanThamDinhCompanyDocumentsSave(documentTemplateID) {
    this.PlanThamDinhCompaniesService.IsShowLoading = true;
    let element: PlanThamDinhCompanyDocument = new PlanThamDinhCompanyDocument()
    element.DocumentTemplateID = documentTemplateID;
    element.IsUploadConfirm = this.SHOWRESULT;
    element.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;
    element.Code = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhCompanyDocumentService.FormData = element;
    this.PlanThamDinhCompanyDocumentService.SaveAndUploadFileAsync().subscribe(
      res => {
        if (documentTemplateID == 62){
          this.PoisoningNoticeDoc = (res as PlanThamDinhCompanyDocument);
        } else if (documentTemplateID == 61){
          this.PlanDoc = (res as PlanThamDinhCompanyDocument);
        } else if (documentTemplateID == 60){
          this.DecisionDoc = (res as PlanThamDinhCompanyDocument);
        }
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhCompaniesService.IsShowLoading = false;
      }
    );
  }

  openFileInputQuyetDinh(numberDoc:number){
    if (numberDoc === 62) {
      this.fileInputPoisoningNotice.nativeElement.click();
    } else if (numberDoc === 61) {
      this.fileInputPlan.nativeElement.click();
    } else if (numberDoc === 60) {
      this.fileInputDecision.nativeElement.click();
    }
  }

  PlanThamDinhCompanyDocumentAdd(DocumentTemplateID: number) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhCompanyDocumentService.BaseParameter.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;
    this.PlanThamDinhCompanyDocumentService.BaseParameter.DocumentTemplateID = DocumentTemplateID;
    this.PlanThamDinhCompanyDocumentService.GetByPlanThamDinhID_DocumentTemplateIDAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyDocumentService.FormData = res as PlanThamDinhCompanyDocument;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: this.PlanThamDinhCompaniesService.FormData.ID };
        const dialog = this.dialog.open(PlanThamDinhCompanyDocumentDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  ChangeFileName(files: FileList) {
    if (files) {
      this.PlanThamDinhCompanyDocumentService.FileToUpload = files;
    }
  }
  ThanhVienAdd(ID: number) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.ThanhVienService.BaseParameter.ID = ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.FormData = res as ThanhVien;
        this.ThanhVienService.FormData.ParentID = environment.DanhMucThanhVienIDKhachMoi;
        this.ThanhVienService.FormData.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;
        this.ThanhVienService.FormData.PlanThamDinhCode = this.PlanThamDinhService.FormData.Code;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.ThanhVienSearch();
        });
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  Close() {
    this.dialogRef.close();
  }

}
