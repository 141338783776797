<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-NhuyenThe02ManhVo" data-bs-toggle="tab" href="#NhuyenThe02ManhVo"
                                    role="tab" aria-controls="NhuyenThe02ManhVo" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Nhuyễn thể 02 mảnh vỏ</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="NhuyenThe02ManhVo" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h1>Nhuyễn thể 02 mảnh vỏ</h1>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> <a class="link-primary"
                                                target="_blank" href="{{excelNhuyenThe02ManhVoURL}}" title="Tải về">Tải về</a>
                                            file
                                            excel mẫu
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 02:</label> Chọn file
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12" style="display: flex;">
                                            <!-- <input class="form-control" type="file" #uploadNhuyenThe02ManhVo
                                                (change)="ChangeNhuyenThe02ManhVo($event.target.files)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"> -->

                                            <a class="btn btn-success me-1" title="Đẩy lên file giám sát dư lượng" (click)="UploadFileGiamSat()">
                                                <i class="bi bi-cloud-upload"></i>
                                            </a>
                                            <input class="form-control" type="file" #uploadGiamSatDuLuong
                                                (change)="ChangeNhuyenThe02ManhVo($event.target.files)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="display: none">
                                            <div *ngIf="GiamSatFile?.FileName === null">
                                                <a class="btn btn-secondary me-1" target="_blank" title="Tải về">
                                                    <i class="bi bi-cloud-download"></i>
                                                </a>
                                                <a class="btn btn-secondary me-1" target="_blank" title="Xem">
                                                    <i class="bi bi-eye"></i>
                                                </a>
                                            </div>
                                            
                                            <div *ngIf="GiamSatFile?.FileName !== null">
                                                <a class="btn btn-success me-1" 
                                                    title="Tải về"
                                                    target="_blank"
                                                    [href]="GiamSatFile.FileName">
                                                    <i class="bi bi-cloud-download"></i>
                                                </a>

                                                <a *ngIf="isPDFFile(GiamSatFile.FileName) || isWordOrExcelFile(GiamSatFile.FileName) || isHtmlFile(GiamSatFile.FileName)"
                                                    class="btn btn-success me-1"
                                                    title="Xem"
                                                    target="_blank"
                                                    [href]="getViewerUrl(GiamSatFile.FileName)">
                                                    <i class="bi bi-eye"></i>
                                                </a>
                                            </div>
                                        </div>    
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 03:</label> Chọn năm
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <select class="form-select" name="UploadService.BaseParameter.Nam"
                                            [(ngModel)]="UploadService.BaseParameter.Nam"
                                            (change)="onYearChange($event)">
                                                <option *ngFor="let item of DownloadService.ListNam001;"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </option>
                                            </select>
                                        </div>                                  
                                        <br />
                                        <button [disabled]="!isNhuyenThe02ManhVo" class="btn btn-info"
                                            (click)="SubmitNhuyenThe02ManhVo()" style="width: 100%;">Upload</button>
                                    </div>
                                    <div class="col-lg-8 col-sm-12 col-12">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="UploadService.IsShowLoading"></app-loading>