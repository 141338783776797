import { Component, OnInit, Inject, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatSelectChange } from '@angular/material/select';

import { DanhMucChucDanh } from 'src/app/shared/DanhMucChucDanh.model';
import { DanhMucChucDanhService } from 'src/app/shared/DanhMucChucDanh.service';

import { DistrictData } from 'src/app/shared/DistrictData.model';
import { DistrictDataService } from 'src/app/shared/DistrictData.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { CompanyInfo } from 'src/app/shared/CompanyInfo.model';
import { CompanyInfoService } from 'src/app/shared/CompanyInfo.service';




import { PlanThamDinh } from 'src/app/shared/PlanThamDinh.model';
import { PlanThamDinhService } from 'src/app/shared/PlanThamDinh.service';
import { PlanThamDinhCompanies } from 'src/app/shared/PlanThamDinhCompanies.model';
import { PlanThamDinhCompaniesService } from 'src/app/shared/PlanThamDinhCompanies.service';
import { PlanThamDinhThanhVien } from 'src/app/shared/PlanThamDinhThanhVien.model';
import { PlanThamDinhThanhVienService } from 'src/app/shared/PlanThamDinhThanhVien.service';
import { CompanyInfoDetailComponent } from '../company-info-detail/company-info-detail.component';
import { ThanhVienDetailComponent } from '../thanh-vien-detail/thanh-vien-detail.component';
import { PlanThamDinhDistrictData } from 'src/app/shared/PlanThamDinhDistrictData.model';
import { PlanThamDinhDistrictDataService } from 'src/app/shared/PlanThamDinhDistrictData.service';
import { PlanThamDinhCompanyDocument } from 'src/app/shared/PlanThamDinhCompanyDocument.model';
import { PlanThamDinhCompanyDocumentService } from 'src/app/shared/PlanThamDinhCompanyDocument.service';
import { PlanThamDinhCompanyDocumentDetailComponent } from '../plan-tham-dinh-company-document-detail/plan-tham-dinh-company-document-detail.component';


@Component({
  selector: 'app-plan-tham-dinh-detail-thanh-tra-chuyen-nganh',
  templateUrl: './plan-tham-dinh-detail-thanh-tra-chuyen-nganh.component.html',
  styleUrls: ['./plan-tham-dinh-detail-thanh-tra-chuyen-nganh.component.css']
})
export class PlanThamDinhDetailThanhTraChuyenNganhComponent implements OnInit {

  @ViewChild('PlanThamDinhCompaniesSort') PlanThamDinhCompaniesSort: MatSort;
  @ViewChild('PlanThamDinhCompaniesPaginator') PlanThamDinhCompaniesPaginator: MatPaginator;
  @ViewChildren('fileInput') fileInputs!: QueryList<ElementRef>;

  @ViewChild('PlanThamDinhThanhVienSort') PlanThamDinhThanhVienSort: MatSort;
  @ViewChild('PlanThamDinhThanhVienPaginator') PlanThamDinhThanhVienPaginator: MatPaginator;

  @ViewChild('PlanThamDinhDistrictDataSort') PlanThamDinhDistrictDataSort: MatSort;
  @ViewChild('PlanThamDinhDistrictDataPaginator') PlanThamDinhDistrictDataPaginator: MatPaginator;

  @ViewChild('PlanThamDinhCompanyDocumentSort') PlanThamDinhCompanyDocumentSort: MatSort;
  @ViewChild('PlanThamDinhCompanyDocumentPaginator') PlanThamDinhCompanyDocumentPaginator: MatPaginator;



  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<PlanThamDinhDetailThanhTraChuyenNganhComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,

    public DanhMucChucDanhService: DanhMucChucDanhService,
    public DistrictDataService: DistrictDataService,


    public ThanhVienService: ThanhVienService,
    public CompanyInfoService: CompanyInfoService,

    public PlanThamDinhDistrictDataService: PlanThamDinhDistrictDataService,

    public PlanThamDinhService: PlanThamDinhService,
    public PlanThamDinhCompaniesService: PlanThamDinhCompaniesService,
    public PlanThamDinhThanhVienService: PlanThamDinhThanhVienService,
    public PlanThamDinhCompanyDocumentService: PlanThamDinhCompanyDocumentService,

  ) {
    this.NotificationService.IsSave = false;
  }

  ngOnInit(): void {
    this.DistrictDataSearch();
    this.CompanyInfoSearch();
    this.ThanhVienSearch();
    this.DanhMucChucDanhSearch();
    this.PlanThamDinhCompaniesSearch();
    this.PlanThamDinhThanhVienSearch();
    this.PlanThamDinhDistrictDataSearch();
    this.PlanThamDinhCompanyDocumentSearch();
    this.SetInitValue()
  }
  SetInitValue(){
    this.PlanThamDinhCompanyDocumentService.BaseParameter.SearchString = ""
  }
  DateNgayBatDau(value) {
    this.PlanThamDinhService.FormData.NgayBatDau = new Date(value);
  }
  DateNgayKetThuc(value) {
    this.PlanThamDinhService.FormData.NgayKetThuc = new Date(value);
  }
  DateNgayGuiMau(value) {
    this.PlanThamDinhService.FormData.NgayGuiMau = new Date(value);
  }
  DatePlanThamDinhCompaniesNgayGhiNhan(element: PlanThamDinhCompanies, value) {
    element.NgayGhiNhan = new Date(value);
  }
  
  CompanyInfoSearch() {
    this.CompanyInfoService.BaseParameter.Active = true;
    this.CompanyInfoService.BaseParameter.PlanTypeID = environment.PlanTypeIDThamDinhATTP;
    this.CompanyInfoService.BaseParameter.DistrictDataID = environment.InitializationNumber;
    this.CompanyInfoService.BaseParameter.WardDataID = environment.InitializationNumber;
    this.CompanyInfoService.BaseParameter.SearchString = environment.InitializationString;
    this.CompanyInfoService.BaseParameter.ID = environment.InitializationNumber;
    this.CompanyInfoService.ComponentGet100ToListAsync();
  }
  OnCompanyChange(event: MatSelectChange, planThamDinhCompanies: PlanThamDinhCompanies){
    const companyInfo :CompanyInfo = this.CompanyInfoService.List.find(c => c.ID = event.value);
    if(companyInfo!==null && companyInfo.ID>0){
      planThamDinhCompanies.TypeName = companyInfo.Name
      planThamDinhCompanies.Display = companyInfo.phone
      planThamDinhCompanies.Description = companyInfo.address
      planThamDinhCompanies.HTMLContent = companyInfo.DanhMucCompanyPhanLoaiName
    }
  }
  CompanyInfoFilter(searchString: string) {
    this.CompanyInfoService.Filter100(searchString);
  }

  DistrictDataSearch() {
    this.DistrictDataService.ComponentGetAllToListAsync();
  }
  DanhMucChucDanhSearch() {
    this.DanhMucChucDanhService.ComponentGetAllToListAsync();
  }

  ThanhVienSearch() {
    this.ThanhVienService.BaseParameter.StateAgencyID = environment.StateAgencyIDChiCucQLCLNongLamThuySan;
    this.ThanhVienService.BaseParameter.Active = true;
    this.ThanhVienService.ComponentGetByStateAgencyID_ActiveToListAsync();
  }

  PlanThamDinhDistrictDataSearch() {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhDistrictDataService.BaseParameter.SearchString = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhDistrictDataService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.PlanThamDinhDistrictDataService.List = (res as PlanThamDinhDistrictData[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.PlanThamDinhDistrictDataService.DataSource = new MatTableDataSource(this.PlanThamDinhDistrictDataService.List);
        this.PlanThamDinhDistrictDataService.DataSource.sort = this.PlanThamDinhDistrictDataSort;
        this.PlanThamDinhDistrictDataService.DataSource.paginator = this.PlanThamDinhDistrictDataPaginator;
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhDistrictDataSave(element: PlanThamDinhDistrictData) {
    this.PlanThamDinhService.IsShowLoading = true;
    element.ParentID = this.PlanThamDinhService.FormData.ID;
    element.Code = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhDistrictDataService.FormData = element;
    this.PlanThamDinhDistrictDataService.SaveAsync().subscribe(
      res => {
        this.PlanThamDinhDistrictDataSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhDistrictDataDelete(element: PlanThamDinhDistrictData) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhDistrictDataService.BaseParameter.ID = element.ID;
    this.PlanThamDinhDistrictDataService.RemoveAsync().subscribe(
      res => {
        this.PlanThamDinhDistrictDataSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }

  PlanThamDinhCompaniesSearch() {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhCompaniesService.BaseParameter.SearchString = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhCompaniesService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.PlanThamDinhCompaniesService.List = (res as PlanThamDinhCompanies[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.PlanThamDinhCompaniesService.DataSource = new MatTableDataSource(this.PlanThamDinhCompaniesService.List);
        this.PlanThamDinhCompaniesService.DataSource.sort = this.PlanThamDinhCompaniesSort;
        this.PlanThamDinhCompaniesService.DataSource.paginator = this.PlanThamDinhCompaniesPaginator;
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompaniesSave(element: PlanThamDinhCompanies) {
    this.PlanThamDinhService.IsShowLoading = true;
    element.ParentID = this.PlanThamDinhService.FormData.ID;
    element.Code = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhCompaniesService.FormData = element;
    this.PlanThamDinhCompaniesService.SaveAsync().subscribe(
      res => {
        this.PlanThamDinhCompaniesSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompaniesDelete(element: PlanThamDinhCompanies) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhCompaniesService.BaseParameter.ID = element.ID;
    this.PlanThamDinhCompaniesService.RemoveAsync().subscribe(
      res => {
        this.PlanThamDinhCompaniesSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }

  PlanThamDinhThanhVienSearch() {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhThanhVienService.BaseParameter.SearchString = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhThanhVienService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.PlanThamDinhThanhVienService.List = (res as PlanThamDinhThanhVien[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.PlanThamDinhThanhVienService.DataSource = new MatTableDataSource(this.PlanThamDinhThanhVienService.List);
        this.PlanThamDinhThanhVienService.DataSource.sort = this.PlanThamDinhThanhVienSort;
        this.PlanThamDinhThanhVienService.DataSource.paginator = this.PlanThamDinhThanhVienPaginator;
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhThanhVienSave(element: PlanThamDinhThanhVien) {
    this.PlanThamDinhService.IsShowLoading = true;
    element.ParentID = this.PlanThamDinhService.FormData.ID;
    element.Code = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhThanhVienService.FormData = element;
    this.PlanThamDinhThanhVienService.SaveAsync().subscribe(
      res => {
        this.PlanThamDinhThanhVienSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhThanhVienDelete(element: PlanThamDinhThanhVien) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhThanhVienService.BaseParameter.ID = element.ID;
    this.PlanThamDinhThanhVienService.RemoveAsync().subscribe(
      res => {
        this.PlanThamDinhThanhVienSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }

  PlanThamDinhSave() {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhService.FormData.ParentID = environment.PlanTypeIDThanhTraChuyenNganh;
    this.PlanThamDinhService.SaveAsync().subscribe(
      res => {
        this.PlanThamDinhService.FormData = res as PlanThamDinh;
        this.PlanThamDinhCompanyDocumentSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }

  ThanhVienAdd(ID: number) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.ThanhVienService.BaseParameter.ID = ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.FormData = res as ThanhVien;
        this.ThanhVienService.FormData.ParentID = environment.DanhMucThanhVienIDKhachMoi;
        this.ThanhVienService.FormData.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;
        this.ThanhVienService.FormData.PlanThamDinhCode = this.PlanThamDinhService.FormData.Code;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.ThanhVienSearch();
        });
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  CompanyInfoAdd(ID: number) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.CompanyInfoService.BaseParameter.ID = ID;
    this.CompanyInfoService.GetByIDAsync().subscribe(
      res => {
        this.CompanyInfoService.FormData = res as CompanyInfo;
        this.CompanyInfoService.FormData.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;
        this.CompanyInfoService.FormData.PlanThamDinhCode = this.PlanThamDinhService.FormData.Code;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(CompanyInfoDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.CompanyInfoSearch();
        });
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  checkloop:number = 0;
  loopNum:number = 0;
  ProcessDocuments(listCut: PlanThamDinhCompanyDocument[]): PlanThamDinhCompanyDocument[] {
    for (let i = 0; i < listCut.length; i++) {
      const currentDoc = listCut[i];
      
      // Tìm tất cả các phần tử có cùng DocumentTemplateID
      const matchingDocs = listCut.filter(
        (doc) => doc.DocumentTemplateID === currentDoc.DocumentTemplateID && doc.IsUploadConfirm !== true
      );
  
      // Kiểm tra nếu có nhiều hơn một phần tử có cùng DocumentTemplateID
      if (matchingDocs.length > 1) {
        if(this.loopNum===i){
          if(this.checkloop === 3){
            this.checkloop = 0;
            continue
          }
          this.checkloop ++;
        }else{
          this.loopNum = i;
        }
        const docWithFileName = matchingDocs.find((doc) => doc.FileName !== null);
        const docWithoutFileName = matchingDocs.find((doc) => doc.FileName === null);
        const docWithFileNames = matchingDocs.filter((doc) => doc.FileName === null);
        if(docWithFileNames.length === 2){
          const docWithTypeName = matchingDocs.find((doc) => doc.TypeName !== null);
          const docWithoutTypeName = matchingDocs.find((doc) => doc.TypeName === null);
          if (docWithTypeName && docWithoutTypeName) {
            const indexWithoutTypeName = listCut.indexOf(docWithoutTypeName);
    
            listCut.splice(indexWithoutTypeName, 1);
            i--;
            continue
          }
        }
        if (docWithFileName && docWithoutFileName) {
          // Thay thế vị trí của docWithoutFileName bằng docWithFileName
          const indexWithoutFileName = listCut.indexOf(docWithoutFileName);
          const indexWithFileName = listCut.indexOf(docWithFileName);
  
          if (indexWithoutFileName !== -1 && indexWithFileName !== -1) {
            // Đổi vị trí trong danh sách
            // listCut[indexWithoutFileName] = docWithoutFileName;
            docWithoutFileName.FileName = listCut[indexWithFileName].FileName;
            docWithoutFileName.ID = listCut[indexWithFileName].ID;
            listCut[indexWithFileName] = docWithoutFileName;
  
            // Xóa docWithFileName khỏi danh sách
            listCut.splice(indexWithoutFileName, 1);
            i--;
          }
        } else {
          listCut.splice(listCut.indexOf(matchingDocs[1]), 1)
          i--;
        }
      }
    }
    return listCut;
  }

  PlanThamDinhCompanyDocumentSearch() {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhCompanyDocumentService.BaseParameter.SearchString = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhCompanyDocumentService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        let listPlanThamDinh = (res as PlanThamDinhCompanyDocument[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        let dataClone = [...listPlanThamDinh.filter(l=>l.DocumentTemplateID !== null)]
        dataClone = this.ProcessDocuments(dataClone);
        let dataClone1 = [...dataClone]
        this.PlanThamDinhCompanyDocumentService.ListPlanThamDinh = dataClone1.filter(d => d.IsUploadConfirm !== true).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinh = new MatTableDataSource(this.PlanThamDinhCompanyDocumentService.ListPlanThamDinh.filter(p=>p.IsUploadConfirm!==true));
        this.PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinh.sort = this.PlanThamDinhCompanyDocumentSort;
        this.PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinh.paginator = this.PlanThamDinhCompanyDocumentPaginator;
        
        this.listCutGobal = dataClone.filter(d => d.IsUploadConfirm !== true).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.listUploadFilesGobal = listPlanThamDinh.filter(d => d.IsUploadConfirm === true);
        this.listUploadFilesGobal.forEach(element => {
          const planThamDinhCompanyDocumentExist = this.listCutGobal.find(l => l.DocumentTemplateID === element.DocumentTemplateID)
          if(planThamDinhCompanyDocumentExist){
            planThamDinhCompanyDocumentExist.IsUploadConfirm = true;
            planThamDinhCompanyDocumentExist.Display = element.FileName;
          }

        });
        this.PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinhSlice = new MatTableDataSource(this.listCutGobal);
        this.PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinh.sort = this.PlanThamDinhCompanyDocumentSort;
        this.PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinh.paginator = this.PlanThamDinhCompanyDocumentPaginator;
        let dataEmpty : PlanThamDinhCompanyDocument[] = [];
        this.PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinhHS = new MatTableDataSource(dataEmpty);
        this.PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinh.sort = this.PlanThamDinhCompanyDocumentSort;
        this.PlanThamDinhCompanyDocumentService.DataSourcePlanThamDinh.paginator = this.PlanThamDinhCompanyDocumentPaginator;

        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompanyDocumentSave(element: PlanThamDinhCompanyDocument) {
    this.PlanThamDinhService.IsShowLoading = true;
    element.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;
    element.Code = this.PlanThamDinhService.FormData.Code;
    this.PlanThamDinhCompanyDocumentService.FormData = element;
    this.PlanThamDinhCompanyDocumentService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyDocumentSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompanyDocumentDelete(element: PlanThamDinhCompanyDocument) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhCompanyDocumentService.BaseParameter.ID = element.ID;
    this.PlanThamDinhCompanyDocumentService.RemoveAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyDocumentSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  PlanThamDinhCompanyDocumentAdd(ID: number) {
    this.PlanThamDinhService.IsShowLoading = true;
    this.PlanThamDinhCompanyDocumentService.BaseParameter.ID = ID;
    this.PlanThamDinhCompanyDocumentService.GetByIDAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyDocumentService.FormData = res as PlanThamDinhCompanyDocument;
        this.PlanThamDinhCompanyDocumentService.FormData.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;
        this.PlanThamDinhCompanyDocumentService.FormData.TypeName = this.PlanThamDinhService.FormData.Display;        
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(PlanThamDinhCompanyDocumentDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.PlanThamDinhCompanyDocumentSearch()
          this.SetInitValue()
        });
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  ChangeFileName(element: PlanThamDinhCompanyDocument, files: FileList | null) {
    if (files && files.length > 0) {
      this.PlanThamDinhCompanyDocumentService.FileToUpload = files;
      this.PlanThamDinhCompanyDocumentsSave(element)
      // Thực hiện xử lý tên tệp
      // console.log(`Tên tệp mới của ${element.Name}: ${file.name}`);
    }
  }
  
  listCutGobal:PlanThamDinhCompanyDocument[]
  listUploadFilesGobal :PlanThamDinhCompanyDocument[]
  openFileInput(element: any) {
    const index = this.listCutGobal.indexOf(element); // Tìm index của element
    if (index !== -1) {
      const inputElement = this.fileInputs.toArray()[index];
      inputElement.nativeElement.click(); // Mở input của đúng element
    }
    // this.fileInputs.nativeElement.click();
  }
  SHOWRESULT :boolean = environment.ShowResult

  PlanThamDinhCompanyDocumentsSave(element: PlanThamDinhCompanyDocument) {
    this.PlanThamDinhService.IsShowLoading = true;
    element.PlanThamDinhID = this.PlanThamDinhService.FormData.ID;
    element.Code = this.PlanThamDinhService.FormData.Code;
    element.IsUploadConfirm = this.SHOWRESULT;
    this.PlanThamDinhCompanyDocumentService.FormData = element;
    this.PlanThamDinhCompanyDocumentService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.PlanThamDinhCompanyDocumentService.FormData = (res as PlanThamDinhCompanyDocument);
        element.Display = this.PlanThamDinhCompanyDocumentService.FormData.FileName;
        this.NotificationService.warn(environment.SaveSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.PlanThamDinhService.IsShowLoading = false;
      }
    );
  }
  
  isPDFFile(fileName: string): boolean {
    return /\.pdf$/i.test(fileName);
  }

  isHtmlFile(fileName: string): boolean {
    return /\.html$/i.test(fileName);
  }

  isWordOrExcelFile(fileName: string): boolean {
    return /\.(doc|docx|xlsx)$/i.test(fileName);
  }
  
  getViewerUrl(fileName: string): string {
    if (this.isPDFFile(fileName)||this.isHtmlFile(fileName)) {
      return fileName;
    } else if (this.isWordOrExcelFile(fileName)) {
      return `https://docs.google.com/viewer?url=${encodeURIComponent(fileName)}&embedded=true`;
    }
    return fileName; // Mặc định
  }

  Close() {
    this.dialogRef.close();
  }

}
