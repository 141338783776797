import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { CompanyInfoVungTrong } from 'src/app/shared/CompanyInfoVungTrong.model';
import { CompanyInfoVungTrongService } from 'src/app/shared/CompanyInfoVungTrong.service';

import { CompanyInfo } from 'src/app/shared/CompanyInfo.model';
import { CompanyInfoService } from 'src/app/shared/CompanyInfo.service';
//import { CoSoCompanyInfoVungTrongViewComponent } from '../co-so-CompanyInfoVungTrong-view/co-so-CompanyInfoVungTrong-view.component';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { CompanyInfoDonViDongGoiDetailComponent } from '../company-info-don-vi-dong-goi-detail/company-info-don-vi-dong-goi-detail.component';

import { CompanyInfoVungTrongDetailComponent } from '../company-info-vung-trong-detail/company-info-vung-trong-detail.component';
import { CompanyInfoDetailComponent } from '../company-info-detail/company-info-detail.component';
import { CoSoCompanyInfoVungTrongDetailComponent } from '../co-so-company-info-vung-trong-detail/co-so-company-info-vung-trong-detail.component';

@Component({
  selector: 'app-co-so-company-info-don-vi-dong-goi',
  templateUrl: './co-so-company-info-don-vi-dong-goi.component.html',
  styleUrls: ['./co-so-company-info-don-vi-dong-goi.component.css']
})
export class CoSoCompanyInfoDonViDongGoiComponent implements OnInit {

  @ViewChild('CompanyInfoVungTrongSort') CompanyInfoVungTrongSort: MatSort;
  @ViewChild('CompanyInfoVungTrongPaginator') CompanyInfoVungTrongPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,

    public CompanyInfoVungTrongService: CompanyInfoVungTrongService,

    public CompanyInfoService: CompanyInfoService,

    public ThanhVienService: ThanhVienService,
  ) { }

  ngOnInit(): void {
    this.ThanhVienGetLogin();
    this.CompanyInfoVungTrongSearch();
  }
  ThanhVienGetLogin() {
    this.ThanhVienService.GetLogin();
  }
  CompanyInfoVungTrongSearch() {    
    this.CompanyInfoVungTrongService.IsShowLoading = true;        
    this.CompanyInfoVungTrongService.BaseParameter.ParentID = this.ThanhVienService.FormDataLogin.CompanyInfoID;    
    this.CompanyInfoVungTrongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.CompanyInfoVungTrongService.List = (res as CompanyInfoVungTrong[]).sort((a, b) => (a.NgayGhiNhan < b.NgayGhiNhan ? 1 : -1)).filter(r=>r.TypePlan == 2);
        this.CompanyInfoVungTrongService.DataSource = new MatTableDataSource(this.CompanyInfoVungTrongService.List);
        this.CompanyInfoVungTrongService.DataSource.sort = this.CompanyInfoVungTrongSort;
        this.CompanyInfoVungTrongService.DataSource.paginator = this.CompanyInfoVungTrongPaginator;        
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {        
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }
  CompanyInfoVungTrongDelete(element: CompanyInfoVungTrong) {
    this.CompanyInfoVungTrongService.IsShowLoading = true;
    element.Active = false;
    this.CompanyInfoVungTrongService.FormData = element;
    this.CompanyInfoVungTrongService.FormData.ParentID = this.CompanyInfoVungTrongService.FormData.ID;
    this.CompanyInfoVungTrongService.SaveAsync().subscribe(
      res => {
        this.NotificationService.warn(environment.SaveSuccess);
        this.CompanyInfoVungTrongSearch();
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.CompanyInfoVungTrongService.IsShowLoading = false;
      }
    );
  }

  CompanyInfoVungTrongAdd(ID: number) {
    this.CompanyInfoVungTrongService.BaseParameter.ID = ID;    
    this.CompanyInfoVungTrongService.GetByIDAsync().subscribe(
      res => {
        if(ID==0){
          this.CompanyInfoVungTrongService.FormData = res as CompanyInfoVungTrong;
        }else{
          this.CompanyInfoVungTrongService.FormData = {}
        }
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID, IsStaff: false, CompanyInfoVungTrongDetail: res };
        const dialog = this.dialog.open(CompanyInfoDonViDongGoiDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.CompanyInfoVungTrongSearch();
        });
      },
      err => {
      }
    );
  }  
  CompanyInfoAdd(ID: number) {    
  }  
}
