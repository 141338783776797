import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { PlanThamDinhCompanyDocument } from 'src/app/shared/PlanThamDinhCompanyDocument.model';
import { PlanThamDinhCompanyDocumentService } from 'src/app/shared/PlanThamDinhCompanyDocument.service';

import { NamThang } from 'src/app/shared/NamThang.model';
import { DownloadService } from 'src/app/shared/Download.service';

import { UploadService } from 'src/app/shared/Upload.service';

@Component({
  selector: 'app-upload-giam-sat-du-luong',
  templateUrl: './upload-giam-sat-du-luong.component.html',
  styleUrls: ['./upload-giam-sat-du-luong.component.css']
})
export class UploadGiamSatDuLuongComponent implements OnInit {

  isGiamSatDuLuong: boolean = false;
  excelGiamSatDuLuongURL: string = environment.APIUploadRootURL + environment.Download + "/GiamSatDuLuong.xlsx";
  @ViewChild('uploadGiamSatDuLuong') uploadGiamSatDuLuong!: ElementRef;

  constructor(
    public NotificationService: NotificationService,  
    
    public DownloadService: DownloadService,
    public PlanThamDinhCompanyDocumentService: PlanThamDinhCompanyDocumentService,

    public UploadService: UploadService,
  ) { }

  ngOnInit(): void {
    this.ComponentGetListNam();
  }
  onYearChange(event: Event): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.GetPlanThamDinhCompanyDocument(selectedValue);
  }
  
  isPDFFile(fileName: string): boolean {
    return /\.pdf$/i.test(fileName);
  }

  isWordOrExcelFile(fileName: string): boolean {
    return /\.(doc|docx|xlsx)$/i.test(fileName);
  }

  isHtmlFile(fileName: string): boolean {
    return /\.(html)$/i.test(fileName);
  }
  
  getViewerUrl(fileName: string): string {
    if (this.isPDFFile(fileName)) {
      return fileName;
    } else if (this.isWordOrExcelFile(fileName)) {
      return `https://docs.google.com/viewer?url=${encodeURIComponent(fileName)}&embedded=true`;
    }
    return fileName; // Mặc định
  }
  GetPlanThamDinhCompanyDocument(selectedValue:string){
    this.UploadService.IsShowLoading = true;
    this.PlanThamDinhCompanyDocumentService.GetByYear(selectedValue,99999).subscribe(
      res => {
        this.GiamSatFile = (res as PlanThamDinhCompanyDocument);
        this.UploadService.IsShowLoading = false;

      },
      err => {
        this.UploadService.IsShowLoading = false;
      }
    );
  }
  ComponentGetListNam() {
    this.DownloadService.ComponentGetListNam();
  }

  ChangeGiamSatDuLuong(files: FileList) {
    if (files) {
      this.isGiamSatDuLuong = true;
    }
  }
  GiamSatFile: PlanThamDinhCompanyDocument
  UploadFileGiamSat(){
    this.uploadGiamSatDuLuong.nativeElement.click();
  }
  SubmitGiamSatDuLuong() {
    this.UploadService.IsShowLoading = true;
    this.UploadService.File = this.uploadGiamSatDuLuong.nativeElement.files[0];
    this.UploadService.PostGiamSatDuLuongByExcelFileAsync().subscribe(
      res => {
        this.UploadService.IsShowLoading = false;
        this.NotificationService.warn(environment.UploadSuccess);
      },
      err => {
        this.UploadService.IsShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }
}
