<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-CompanyInfoVungTrongDetail" data-bs-toggle="tab"
                href="#CompanyInfoVungTrongDetail" role="tab" aria-controls="CompanyInfoVungTrongDetail"
                aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Đăng ký mã số đóng gói
                    [{{CompanyInfoVungTrongService.FormData.ID}}]</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="CompanyInfoVungTrongDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <a style="width: 100%;" title="Hoàn thành" (click)="CompanyInfoVungTrongSave()"
                        class="btn btn-info"><i class="bi bi-sd-card"></i>
                        Lưu bước 01</a>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <h4>Bước 01: Nhập Thông tin</h4>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-12 col-sm-12 col-12">
                            <h4 class="text-center">Thông tin chung</h4>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">

                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Đăng ký cấp</label>
                                <select class="form-select" name="DanhMucATTPLoaiHoSoID"
                                    (change)="ATTPLoaiHoSoChange()"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.DanhMucATTPLoaiHoSoID">
                                    <option [value]="0"></option>
                                    <option *ngFor="let item of DanhMucATTPLoaiHoSoService.List;" [value]="item.ID">
                                        {{item.Name}}</option>
                                </select>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <!-- <label class="form-label">Tình trạng</label> -->
                                <label class="form-label">Tình trạng hồ sơ</label>
                                <select class="form-select" name="DanhMucATTPTinhTrangID" [disabled]="!IsStaff"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.DanhMucATTPTinhTrangID">
                                    <option [value]="0"></option>
                                    <option *ngFor="let item of DanhMucATTPTinhTrangService.List;" [value]="item.ID">
                                        {{item.Name}}</option>
                                </select>
                            </div>
                            
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Tình trạng cơ sở đóng gói</label>
                                <select class="form-select" name="DanhMucATTPXepLoaiID" [disabled]="!IsStaff"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.DanhMucATTPXepLoaiID">
                                    <option [value]="0"></option>
                                    <option *ngFor="let item of DanhMucATTPXepLoaiService.List;" [value]="item.ID">
                                        {{item.Name}}</option>
                                </select>
                            </div>
                            <!-- <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Xếp loại</label>
                                <select class="form-select" name="DanhMucATTPXepLoaiID"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.DanhMucATTPXepLoaiID">
                                    <option *ngFor="let item of DanhMucATTPXepLoaiService.List;" [value]="item.ID">
                                        {{item.Name}}</option>
                                </select>
                            </div> -->
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ghi nhận</label>
                                <input [ngModel]="CompanyInfoVungTrongService.FormData.NgayGhiNhan | date:'yyyy-MM-dd'"
                                    (ngModelChange)="DateNgayGhiNhan($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                                    type="date" class="form-control" autocomplete="off">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Mã hồ sơ (nếu chưa có thì để trống)</label>
                                <input name="MaHoSo" [(ngModel)]="CompanyInfoVungTrongService.FormData.MaHoSo"
                                    placeholder="Mã hồ sơ" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Mã số cơ sở đóng gói (nếu chưa có thì để trống)</label>
                                <input name="Name" [(ngModel)]="CompanyInfoVungTrongService.FormData.Name"
                                    placeholder="Mã số cơ sở đóng gói" type="text" class="form-control">
                            </div>
                            
                            <div *ngIf="IsShowLyDoVT" class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Nguyên nhân hồ sơ</label>
                                <input name="Name" [(ngModel)]="CompanyInfoVungTrongService.FormData.NguyenNhanKhongDatHS" disabled
                                    placeholder="Nguyên nhân hồ sơ" type="text" class="form-control">

                                <label class="form-label">Văn bản</label>
                                <div class="col-lg-12 col-sm-12 col-12" style="display: flex;">
                                    <div *ngIf="GiamSatFile?.FileName === null">
                                        <a class="btn btn-secondary me-1" target="_blank" title="Tải về văn bản">
                                            <i class="bi bi-cloud-download"></i>
                                        </a>
                                        <a class="btn btn-secondary me-1" target="_blank" title="Xem văn bản">
                                            <i class="bi bi-eye"></i>
                                        </a>
                                    </div>
                                    
                                    <div *ngIf="GiamSatFile?.FileName !== null">
                                        <a class="btn btn-success me-1" 
                                            title="Tải về văn bản"
                                            target="_blank"
                                            [href]="GiamSatFile.FileName">
                                            <i class="bi bi-cloud-download"></i>
                                        </a>

                                        <a *ngIf="isPDFFile(GiamSatFile.FileName) || isWordOrExcelFile(GiamSatFile.FileName) || isHtmlFile(GiamSatFile.FileName)"
                                            class="btn btn-success me-1"
                                            title="Xem văn bản"
                                            target="_blank"
                                            [href]="getViewerUrl(GiamSatFile.FileName)">
                                            <i class="bi bi-eye"></i>
                                        </a>
                                    </div>
                                </div>  
                            </div>
                            <!-- <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Báo cáo nguyên nhân (nếu có)</label>
                                <input name="Description" [(ngModel)]="CompanyInfoVungTrongService.FormData.Description"
                                    placeholder="Báo cáo nguyên nhân (nếu có)" type="text" class="form-control">
                            </div> -->
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Đơn vị Tổ chức</label>
                                <!-- <input name="CompanyInfoName"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.CompanyInfoName"
                                    placeholder="Đơn vị Tổ chức" type="text" class="form-control"> -->
                                <mat-select class="form-control" name="CompanyInfoVungTrongService.FormData.ParentID"
                                    *ngIf="IsStaff == true || !IsShowDVTC"
                                    (selectionChange)="CompanyInfoChange()"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.CompanyInfoID">
                                    <input placeholder="Tìm..." class="form-control"
                                        (keyup)="CompanyInfoFilter($event.target.value)">
                                    <mat-option *ngFor="let item of CompanyInfoService.ListFilter" [value]="item.ID">
                                        {{item.Name}}
                                    </mat-option>
                                </mat-select>
                                
                                <input name="CompanyInfoName" *ngIf="IsStaff != true && IsShowDVTC" [disabled]="true"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.CompanyInfoName"
                                    placeholder="Đơn vị Tổ chức" type="text" class="form-control">
                            </div>
                            <!-- <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Mã số thuế (nếu có)</label>
                                <input name="Code" [(ngModel)]="CompanyInfoVungTrongService.FormData.Code"
                                    placeholder="Mã số thuế (nếu có)" type="text" class="form-control">
                            </div> -->
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Đại diện cơ sở (nếu có)</label>
                                <input name="DaiDienCoSo" [(ngModel)]="CompanyInfoVungTrongService.FormData.DaiDienCoSo" [disabled]="!IsStaff"
                                    placeholder="Đại diện cơ sở" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Chức danh (nếu có)</label>
                                <input name="DaiDienCoSoChucVu"  [disabled]="!IsStaff"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.DaiDienCoSoChucVu"
                                    placeholder="Chức danh" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Điện thoại (dùng để gửi thông báo)</label>
                                <input name="DaiDienCoSoDienThoai" [disabled]="!IsStaff"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.DaiDienCoSoDienThoai"
                                    placeholder="Điện thoại" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Email (dùng để gửi thông báo)</label>
                                <input name="DaiDienCoSoEmail" [disabled]="!IsStaff"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.DaiDienCoSoEmail"
                                    placeholder="Email" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Ghi chú</label>
                                <input name="Note" [(ngModel)]="CompanyInfoVungTrongService.FormData.Note"
                                    placeholder="Ghi chú" type="text" class="form-control">
                            </div>
                            
                            <div *ngIf="IsShowLyDoCSDG" class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Nguyên nhân cơ sở đóng gói</label>
                                <input name="Name" [(ngModel)]="CompanyInfoVungTrongService.FormData.NguyenNhanKhongDatVT" disabled
                                    placeholder="Nguyên nhân cơ sở đóng gói" type="text" class="form-control">
                                
                                <label class="form-label">Văn bản</label>
                                <div class="col-lg-12 col-sm-12 col-12" style="display: flex;">
                                    <div *ngIf="GiamSatFile1?.FileName === null">
                                        <a class="btn btn-secondary me-1" target="_blank" title="Tải về văn bản">
                                            <i class="bi bi-cloud-download"></i>
                                        </a>
                                        <a class="btn btn-secondary me-1" target="_blank" title="Xem văn bản">
                                            <i class="bi bi-eye"></i>
                                        </a>
                                    </div>
                                    
                                    <div *ngIf="GiamSatFile1?.FileName !== null">
                                        <a class="btn btn-success me-1" 
                                            title="Tải về văn bản"
                                            target="_blank"
                                            [href]="GiamSatFile1.FileName">
                                            <i class="bi bi-cloud-download"></i>
                                        </a>

                                        <a *ngIf="isPDFFile(GiamSatFile1.FileName) || isWordOrExcelFile(GiamSatFile1.FileName) || isHtmlFile(GiamSatFile1.FileName)"
                                            class="btn btn-success me-1"
                                            title="Xem văn bản"
                                            target="_blank"
                                            [href]="getViewerUrl(GiamSatFile1.FileName)">
                                            <i class="bi bi-eye"></i>
                                        </a>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>                    
                    <div class="row gx-4">
                        <div class="col-lg-12 col-sm-12 col-12">
                            <h4 class="text-center">Thông tin địa chỉ cấp mã số cơ sở đóng gói</h4>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Địa chỉ cơ sở đóng gói</label>
                                <input name="DiaChi" [(ngModel)]="CompanyInfoVungTrongService.FormData.DiaChi"
                                    placeholder="Địa chỉ" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Kinh độ</label>
                                <input name="KinhDo" [(ngModel)]="CompanyInfoVungTrongService.FormData.KinhDo"
                                    placeholder="Kinh độ" type="number" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Vĩ độ</label>
                                <input name="ViDo" [(ngModel)]="CompanyInfoVungTrongService.FormData.ViDo"
                                    placeholder="Vĩ độ" type="number" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Tỉnh</label>
                                <input name="ProvinceDataName" [(ngModel)]="CompanyInfoVungTrongService.FormData.ProvinceDataName" [disabled]="true"
                                    type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Huyện</label>
                                <select class="form-select" name="DistrictDataID"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.DistrictDataID"
                                    (change)="WardDataSearch()">
                                    <option [value]="0"></option>
                                    <option *ngFor="let item of DistrictDataService.List;" [value]="item.ID">
                                        {{item.Name}}</option>
                                </select>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Xã</label>
                                <select class="form-select" name="WardDataID"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.WardDataID">
                                    <option [value]="0"></option>
                                    <option *ngFor="let item of WardDataService.List;" [value]="item.ID">
                                        {{item.Name}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Thôn ấp</label>
                                <input name="ThonAp" [(ngModel)]="CompanyInfoVungTrongService.FormData.ThonAp"
                                    placeholder="Thôn ấp" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-12 col-sm-12 col-12">
                            <h4 class="text-center">Thông tin sản xuất đóng gói</h4>
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Sản phẩm chủ lực</label>
                                <input name="SanPham" [(ngModel)]="CompanyInfoVungTrongService.FormData.SanPham"
                                    placeholder="Sản phẩm" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Thị trường chủ lực</label>
                                <input name="ThiTruong" [(ngModel)]="CompanyInfoVungTrongService.FormData.ThiTruong"
                                    placeholder="Thị trường" type="text" class="form-control">
                            </div>
                            <!-- <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Chọn Thị trường</label>
                                <select class="form-select" name="Display"
                                    [(ngModel)]="CompanyInfoVungTrongService.FormData.Display"
                                    (change)="DanhMucThiTruongChange()">
                                    <option *ngFor="let item of DanhMucThiTruongService.List;" [value]="item.Name">
                                        {{item.Name}}</option>
                                </select>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Thị trường</label>
                                <input name="ThiTruong" [(ngModel)]="CompanyInfoVungTrongService.FormData.ThiTruong"
                                    placeholder="Thị trường" type="text" class="form-control">
                            </div> -->
                        </div>
                        <div class="col-lg-6 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Công suất đóng gói (tấn(trái đối với dừa)/ngày)</label>
                                <input name="NongHo" [(ngModel)]="CompanyInfoVungTrongService.FormData.CongXuatDongGoi"
                                    placeholder="Công suất đóng gói" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Diện tích (m2)</label>
                                <input name="DienTich" [(ngModel)]="CompanyInfoVungTrongService.FormData.DienTich"
                                    placeholder="Diện tích (m2)" type="number" class="form-control">
                            </div>                   
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <a style="width: 100%;" title="Hoàn thành" (click)="CompanyInfoVungTrongSave()"
                        class="btn btn-info"><i class="bi bi-sd-card"></i>
                        Lưu bước 01</a>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-warning">
                        <div class="card-header">
                            <h5 class="card-title text-white">Bước 02: Thêm thông tin nhân viên
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoVungTrongNongHoSort="matSort"
                                        [dataSource]="CompanyInfoVungTrongNongHoService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="Active">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                CCCD Trùng
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        name="Active{{ element.ID }}" [(ngModel)]="element.Active" />
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="ThanhVienID">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nhân viên
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Nhân viên"
                                                    name="Name{{ element.ID }}" [(ngModel)]="element.Name" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="CCCD">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>CCCD
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="CCCD"
                                                    name="CCCD{{ element.ID }}" [(ngModel)]="element.CCCD" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="DienThoai">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Điện thoại"
                                                    name="DienThoai{{ element.ID }}" [(ngModel)]="element.DienThoai" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Email">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Email"
                                                    name="Email{{ element.ID }}" [(ngModel)]="element.Email" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Note">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Ghi chú"
                                                    name="Note{{ element.ID }}" [(ngModel)]="element.Note" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info"
                                                    (click)="CompanyInfoVungTrongNongHoSave(element)"><i
                                                        class="bi bi-sd-card"></i>
                                                </a>&nbsp;&nbsp;&nbsp;
                                                <a class="btn btn-danger"
                                                    (click)="CompanyInfoVungTrongNongHoDelete(element)"
                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoVungTrongNongHoService.DisplayColumns003">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoVungTrongNongHoService.DisplayColumns003;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #CompanyInfoVungTrongNongHoPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoVungTrongNongHoSort="matSort"
                                        [dataSource]="CompanyInfoVungTrongNongHoService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox"
                                                                name="Active{{ element.ID }}"
                                                                [(ngModel)]="element.Active" />
                                                            <label class="form-label" for="Active">CCCD Trùng</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Nông hộ: </label>
                                                        <input class="form-control" type="text" placeholder="Nông hộ"
                                                            name="Name{{element.ID}}" [(ngModel)]="element.Name" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>CCCD: </label>
                                                        <input class="form-control" type="text" placeholder="CCCD"
                                                            name="CCCD{{element.ID}}" [(ngModel)]="element.CCCD" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Điện thoại: </label>
                                                        <input class="form-control" type="text" placeholder="Điện thoại"
                                                            name="DienThoai{{element.ID}}"
                                                            [(ngModel)]="element.DienThoai" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Email: </label>
                                                        <input class="form-control" type="text" placeholder="Email"
                                                            name="Email{{element.ID}}" [(ngModel)]="element.Email"
                                                            [disabled]="!IsStaff" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Năm sinh: </label>
                                                        <input class="form-control" type="number" placeholder="NamSinh"
                                                            name="NamSinh{{element.ID}}"
                                                            [(ngModel)]="element.NamSinh" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>DiaChi: </label>
                                                        <input class="form-control" type="text" placeholder="DiaChi"
                                                            name="DiaChi{{element.ID}}" [(ngModel)]="element.DiaChi" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Kinh độ: </label>
                                                        <input class="form-control" type="number" placeholder="KinhDo"
                                                            name="KinhDo{{element.ID}}" [(ngModel)]="element.KinhDo" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Vĩ độ: </label>
                                                        <input class="form-control" type="number" placeholder="ViDo"
                                                            name="ViDo{{element.ID}}" [(ngModel)]="element.ViDo" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Giống: </label>
                                                        <input class="form-control" type="text" placeholder="Giong"
                                                            name="Giong{{element.ID}}" [(ngModel)]="element.Giong" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Năm trồng: </label>
                                                        <input class="form-control" type="number" placeholder="NamTrong"
                                                            name="NamTrong{{element.ID}}"
                                                            [(ngModel)]="element.NamTrong" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Chứng nhận VietGap: </label>
                                                        <input class="form-control" type="text"
                                                            placeholder="Chứng nhận VietGap"
                                                            name="ChungNhanVietGap{{element.ID}}"
                                                            [(ngModel)]="element.ChungNhanVietGap" />
                                                    </div>
                                                    <div class="row gx-4">
                                                        <div class="col-lg-6 col-sm-6 col-6">
                                                            <a class="btn btn-info" style="width: 100%"
                                                                (click)="CompanyInfoVungTrongNongHoSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-6 col-6">
                                                            <a class="btn btn-danger" style="width: 100%;"
                                                                (click)="CompanyInfoVungTrongNongHoDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </div>
                                                        <br />
                                                        <br />
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoVungTrongNongHoService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoVungTrongNongHoService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #CompanyInfoVungTrongNongHoPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Bản đồ</label>
                        <div>
                            <a style="width: 100%;" class="btn btn-success" title="Bản đồ" (click)="MapLoad()"><i
                                class="bi bi-pin-map"></i> Bản đồ</a>
                        </div>
                        <div class="row gx-4" style="position: relative;padding: 10px">
                            <div class="map" id="map" #map style="height: 0px; width: 100%;padding: 0;">
                                <div style="z-index: 1000; position: absolute; top: 0;">
                                    <img src="{{domainURL}}assets/image/vungtrong.png" />
                                </div>
                                <div
                                    style="z-index: 1000; position: absolute; bottom: 0; width: 100%; height: 20px; background-color: #ffffff;">
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <a style="width: 100%;" title="Tải tất cả tập tin đính kèm" (click)="CompanyInfoVungTrongDocumentsDownload()"
                        class="btn btn-primary"><i class="bi bi-cloud-download"></i>
                        Tải tất cả tập tin đính kèm</a>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-primary">
                        <div class="card-header">
                            <h5 class="card-title text-white bg-primary">Bước 03: Tải lên biểu mẫu và tập tin đính kèm
                            </h5>
                        </div>
                        <div class="card-body bg-primary">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoVungTrongDocumentsSort="matSort"
                                        [dataSource]="CompanyInfoDonViDongGoiDocumentsService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="Name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Hồ sơ
                                            </th>
                                            <td mat-cell *matCellDef="let element" style="width: 90%;">
                                                <input *ngIf="element.ID==0" class="form-control" type="text" placeholder="Hồ sơ"
                                                    name="Name{{ element.ID }}" [(ngModel)]="element.Name" />
                                                <input *ngIf="element.ID>0" class="form-control" type="text" placeholder="Hồ sơ"  disabled
                                                    name="Name{{ element.ID }}" [(ngModel)]="element.Name" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">

                                                <div style="display: flex;">
                                                    <a *ngIf="!CheckTemplateOfStaff(element)"
                                                        class="btn btn-secondary me-1" title="Đẩy lên">
                                                        <i class="bi bi-cloud-upload"></i>
                                                    </a>
                                                    <a  *ngIf="CheckTemplateOfStaff(element)"
                                                        (click)="openFileInput2(element)"
                                                        class="btn btn-success me-1" title="Đẩy lên">
                                                        <i class="bi bi-cloud-upload"></i>
                                                    </a>
                                                    
                                                    <input #fileInput type="file" (change)="ChangeFileName2(element, $event.target.files)" style="display: none">
                                                    
                                                    <div *ngIf="element?.FileName === null">
                                                        <a class="btn btn-secondary me-1" target="_blank" title="Tải về">
                                                            <i class="bi bi-cloud-download"></i>
                                                        </a>
                                                        <a class="btn btn-secondary me-1" target="_blank" title="Xem">
                                                            <i class="bi bi-eye"></i>
                                                        </a>
                                                    </div>
                                                    
                                                    <div  *ngIf="element?.FileName !== null">
                                                        <a class="btn btn-success me-1" 
                                                            title="Tải về"
                                                            target="_blank"
                                                            [href]="element.FileName">
                                                            <i class="bi bi-cloud-download"></i>
                                                        </a>
                                
                                                        <a *ngIf="isPDFFile(element.FileName) || isWordOrExcelFile(element.FileName) || isHtmlFile(element.FileName)"
                                                            class="btn btn-success me-1"
                                                            title="Xem"
                                                            target="_blank"
                                                            [href]="getViewerUrl(element.FileName)">
                                                            <i class="bi bi-eye"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoDonViDongGoiDocumentsService.DisplayColumns001">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoDonViDongGoiDocumentsService.DisplayColumns001;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #CompanyInfoVungTrongDocumentsPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoVungTrongDocumentsSort="matSort"
                                        [dataSource]="CompanyInfoDonViDongGoiDocumentsService.DataSource"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Hồ sơ: </label>
                                                        <input class="form-control" type="text" placeholder="Name"
                                                            name="Name{{ element.ID }}" [(ngModel)]="element.Name" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Tải lên: </label>
                                                        <br />
                                                        <input type="file" style="width: 100px;"
                                                            (change)="ChangeFileName(element, $event.target.files)">
                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                        <a class="btn btn-info" style="width: 100%"
                                                            (click)="CompanyInfoVungTrongDocumentsSave(element)"><i
                                                                class="bi bi-sd-card"></i> </a>
                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                        <a class="btn btn-danger" style="width: 100%"
                                                            (click)="CompanyInfoVungTrongDocumentsDelete(element)"
                                                            *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0 && element.TypeName"
                                                            style="width: 100%" class="btn btn-success" title="Tải về"
                                                            (click)="CompanyInfoVungTrongDocumentsAdd(element)"
                                                            target="_blank">Xem biểu mẫu trực tuyến
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0 && element.TypeName"
                                                            style="width: 100%" class="btn btn-success" title="Tải về"
                                                            href="{{element.TypeName}}" target="_blank">Tải biểu mẫu
                                                            CQQL cung cấp
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0" style="width: 100%"
                                                            class="btn btn-success" title="Tải về"
                                                            href="{{element.FileName}}" target="_blank">Tải tài
                                                            liệu người dân cung cấp (nếu có)
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoDonViDongGoiDocumentsService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoDonViDongGoiDocumentsService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #CompanyInfoVungTrongDocumentsPaginator="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div>
                    <div *ngIf="!IsStaff" class="col-lg-12 col-sm-12 col-12">
                        <a style="width: 100%;" title="Gửi cơ quan chức năng" (click)="CompanyInfoVungTrongSave(true,false)"
                            class="btn btn-info"><i class="bi bi-sd-card"></i>
                            Gửi cơ quan chức năng</a>
                    </div>
                </div>
                <div *ngIf="IsStaff">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tình trạng hồ sơ</label>
                        <select class="form-select" name="DanhMucATTPTinhTrangID"
                            (change)="ChangeLyDoHS()"
                            [(ngModel)]="CompanyInfoVungTrongService.FormData.DanhMucATTPTinhTrangID">
                            <option [value]="0"></option>
                            <option *ngFor="let item of DanhMucATTPTinhTrangService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    
                    <div *ngIf="IsShowLyDoVT || FindbyExist(CompanyInfoVungTrongService.FormData.DanhMucATTPTinhTrangID,2)" class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Nguyên nhân hồ sơ</label>
                        <input name="Name" [(ngModel)]="CompanyInfoVungTrongService.FormData.NguyenNhanKhongDatHS"
                            placeholder="Nguyên nhân hồ sơ" type="text" class="form-control">
                    </div>
                    
                    <div *ngIf="IsShowLyDoVT || FindbyExist(CompanyInfoVungTrongService.FormData.DanhMucATTPTinhTrangID,2)" class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đính kèm văn bản</label>
                        <div class="col-lg-12 col-sm-12 col-12" style="display: flex;">
                            <a class="btn btn-success me-1" title="Đẩy lên văn bản" (click)="UploadFileGiamSat()">
                                <i class="bi bi-cloud-upload"></i>
                            </a>
                            <input class="form-control" type="file" #uploadGiamSatDuLuong
                                (change)="ChangeNhuyenThe02ManhVo($event.target.files)" style="display: none">
                            <div *ngIf="GiamSatFile?.FileName === null">
                                <a class="btn btn-secondary me-1" target="_blank" title="Tải về văn bản">
                                    <i class="bi bi-cloud-download"></i>
                                </a>
                                <a class="btn btn-secondary me-1" target="_blank" title="Xem văn bản">
                                    <i class="bi bi-eye"></i>
                                </a>
                            </div>
                            
                            <div *ngIf="GiamSatFile?.FileName !== null">
                                <a class="btn btn-success me-1" 
                                    title="Tải về văn bản"
                                    target="_blank"
                                    [href]="GiamSatFile.FileName">
                                    <i class="bi bi-cloud-download"></i>
                                </a>

                                <a *ngIf="isPDFFile(GiamSatFile.FileName) || isWordOrExcelFile(GiamSatFile.FileName) || isHtmlFile(GiamSatFile.FileName)"
                                    class="btn btn-success me-1"
                                    title="Xem văn bản"
                                    target="_blank"
                                    [href]="getViewerUrl(GiamSatFile.FileName)">
                                    <i class="bi bi-eye"></i>
                                </a>
                            </div>
                        </div>  
                            
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Cấp mã số hồ sơ</label>
                        <input name="Name" [(ngModel)]="CompanyInfoVungTrongService.FormData.MaHoSo"
                            placeholder="Mã số hồ sơ" type="text" class="form-control">
                            
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tình trạng cơ sở đóng gói</label>
                        <select class="form-select" name="DanhMucATTPXepLoaiID"
                            (change)="ChangeLyDoCSDG()"
                            [(ngModel)]="CompanyInfoVungTrongService.FormData.DanhMucATTPXepLoaiID">
                            <option [value]="0"></option>
                            <option *ngFor="let item of DanhMucATTPXepLoaiService.List;" [value]="item.ID">
                                {{item.Name}}</option>
                        </select>
                    </div>
                    
                    <div *ngIf="IsShowLyDoCSDG || FindbyExist(CompanyInfoVungTrongService.FormData.DanhMucATTPXepLoaiID,3)" class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Nguyên nhân cơ sở đóng gói</label>
                        <input name="Name" [(ngModel)]="CompanyInfoVungTrongService.FormData.NguyenNhanKhongDatVT"
                            placeholder="Nguyên nhân cơ sở đóng gói" type="text" class="form-control">
                    </div>

                    <div *ngIf="IsShowLyDoCSDG || FindbyExist(CompanyInfoVungTrongService.FormData.DanhMucATTPXepLoaiID,3)" class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đính kèm văn bản</label>
                        <div class="col-lg-12 col-sm-12 col-12" style="display: flex;">
                            <a class="btn btn-success me-1" title="Đẩy lên văn bản" (click)="UploadFileGiamSat1()">
                                <i class="bi bi-cloud-upload"></i>
                            </a>
                            <input class="form-control" type="file" #uploadGiamSatDuLuong1
                                (change)="ChangeNhuyenThe02ManhVo1($event.target.files)" style="display: none">
                            <div *ngIf="GiamSatFile1?.FileName === null">
                                <a class="btn btn-secondary me-1" target="_blank" title="Tải về văn bản">
                                    <i class="bi bi-cloud-download"></i>
                                </a>
                                <a class="btn btn-secondary me-1" target="_blank" title="Xem văn bản">
                                    <i class="bi bi-eye"></i>
                                </a>
                            </div>
                            
                            <div *ngIf="GiamSatFile1?.FileName !== null">
                                <a class="btn btn-success me-1" 
                                    title="Tải về văn bản"
                                    target="_blank"
                                    [href]="GiamSatFile1.FileName">
                                    <i class="bi bi-cloud-download"></i>
                                </a>

                                <a *ngIf="isPDFFile(GiamSatFile1.FileName) || isWordOrExcelFile(GiamSatFile1.FileName) || isHtmlFile(GiamSatFile1.FileName)"
                                    class="btn btn-success me-1"
                                    title="Xem văn bản"
                                    target="_blank"
                                    [href]="getViewerUrl(GiamSatFile1.FileName)">
                                    <i class="bi bi-eye"></i>
                                </a>
                            </div>
                        </div>  
                            
                    </div>

                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Cấp mã số cơ sở đóng gói</label>
                        <input name="Name" [(ngModel)]="CompanyInfoVungTrongService.FormData.Name"
                            placeholder="Mã số cơ sở đóng gói" type="text" class="form-control">
                            
                    </div>

                    
                    <div *ngIf="CompanyInfoVungTrongService.FormData.DanhMucATTPXepLoaiID != 9" class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú nội dung cấp mã cơ sở đóng gói</label>
                        <input name="Name" [(ngModel)]="CompanyInfoVungTrongService.FormData.GhiChuNoiDungMSVC"
                            placeholder="Ghi chú nội dung cấp mã cơ sở đóng gói" type="text" class="form-control">
                    </div>
                    <div *ngIf="CompanyInfoVungTrongService.FormData.DanhMucATTPXepLoaiID != 9" class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đính kèm file xác nhận cơ sở đóng gói được cấp mã số xuất khẩu</label>
                        <div class="col-lg-12 col-sm-12 col-12" style="display: flex;">
                            <a class="btn btn-success me-1" title="Đính kèm file xác nhận cơ sở đóng gói được cấp mã số xuất khẩu" (click)="UploadFileGiamSat2()">
                                <i class="bi bi-cloud-upload"></i>
                            </a>
                            <input class="form-control" type="file" #uploadGiamSatDuLuong2
                                (change)="ChangeNhuyenThe02ManhVo2($event.target.files)" style="display: none">
                            <div *ngIf="GiamSatFile2?.FileName === null">
                                <a class="btn btn-secondary me-1" target="_blank" title="Tải về file xác nhận cơ sở đóng gói được cấp mã số xuất khẩu">
                                    <i class="bi bi-cloud-download"></i>
                                </a>
                                <a class="btn btn-secondary me-1" target="_blank" title="Xem file xác nhận cơ sở đóng gói được cấp mã số xuất khẩu">
                                    <i class="bi bi-eye"></i>
                                </a>
                            </div>
                            
                            <div *ngIf="GiamSatFile2?.FileName !== null">
                                <a class="btn btn-success me-1" 
                                    title="Tải về file xác nhận cơ sở đóng gói được cấp mã số xuất khẩu"
                                    target="_blank"
                                    [href]="GiamSatFile2.FileName">
                                    <i class="bi bi-cloud-download"></i>
                                </a>

                                <a *ngIf="isPDFFile(GiamSatFile2.FileName) || isWordOrExcelFile(GiamSatFile2.FileName) || isHtmlFile(GiamSatFile2.FileName)"
                                    class="btn btn-success me-1"
                                    title="Xem file xác nhận cơ sở đóng gói được cấp mã số xuất khẩu"
                                    target="_blank"
                                    [href]="getViewerUrl(GiamSatFile2.FileName)">
                                    <i class="bi bi-eye"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-sm-12 col-12">
                        <a style="width: 100%;" title="Cập nhật trạng thái" (click)="CompanyInfoVungTrongSave(flase,true)"
                            class="btn btn-info"><i class="bi bi-sd-card"></i>
                            Cập nhật trạng thái</a>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-danger">
                        <div class="card-header">
                            <h5 class="card-title text-white">Quản lý mã số xuất khẩu của cơ sở đóng gói
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoVungTrongDocumentsSort002="matSort"
                                        [dataSource]="MatHangXuatKhauService.List"
                                        class="table table-striped m-0">
                                        
                                        <ng-container matColumnDef="TenSanPham">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tên sản phẩm
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Tên sản phẩm"
                                                    name="TenSanPham{{ element.ID }}" [(ngModel)]="element.TenSanPham" />
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="DienTich">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện tích
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Diện tích"
                                                    name="DienTich{{ element.ID }}" [(ngModel)]="element.DienTich" />
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="KinhDo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Kinh độ
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Kinh độ"
                                                    name="KinhDo{{ element.ID }}" [(ngModel)]="element.KinhDo" />
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="ViDo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vĩ độ
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Vĩ độ"
                                                    name="ViDo{{ element.ID }}" [(ngModel)]="element.ViDo" />
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="TTXK">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Thị trường xuất khẩu
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Thị trường xuất khẩu"
                                                    name="TTXKID{{ element.ID }}" [(ngModel)]="element.TTXKID" />
                                            </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="MSXK">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã số xuất khẩu
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Mã số xuất khẩu"
                                                    name="MSXK{{ element.ID }}" [(ngModel)]="element.MSXK" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="GhiChu">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú mã số xuất khẩu
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Mã số xuất khẩu"
                                                    name="GhiChu{{ element.ID }}" [(ngModel)]="element.Note" />
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="TepDinhKem">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tệp đính kèm
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div style="display: flex;">
                                                    <a  (click)="openFileInput3(element)"
                                                        class="btn btn-success me-1" title="Đẩy lên">
                                                        <i class="bi bi-cloud-upload"></i>
                                                    </a>
                                                    
                                                    <input #fileInput3 type="file" (change)="ChangeFileName3(element, $event.target.files)" style="display: none">
                                                    
                                                    <div *ngIf="element?.FileName === null">
                                                        <a class="btn btn-secondary me-1" target="_blank" title="Tải về">
                                                            <i class="bi bi-cloud-download"></i>
                                                        </a>
                                                        <a class="btn btn-secondary me-1" target="_blank" title="Xem">
                                                            <i class="bi bi-eye"></i>
                                                        </a>
                                                    </div>
                                                    
                                                    <div  *ngIf="element?.FileName !== null">
                                                        <a class="btn btn-success me-1" 
                                                            title="Tải về"
                                                            target="_blank"
                                                            [href]="element.FileName">
                                                            <i class="bi bi-cloud-download"></i>
                                                        </a>
                                
                                                        <a *ngIf="isPDFFile(element.FileName) || isWordOrExcelFile(element.FileName) || isHtmlFile(element.FileName)"
                                                            class="btn btn-success me-1"
                                                            title="Xem"
                                                            target="_blank"
                                                            [href]="getViewerUrl(element.FileName)">
                                                            <i class="bi bi-eye"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <!-- <input class="form-control" type="text" placeholder="Tệp đính kèm"
                                                    name="TemDinhKem{{ element.ID }}" [(ngModel)]="element.FileName" /> -->
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="TT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Hiện trạng mã số xuất khẩu
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <select class="form-select" name="HienTrangID{{element.ID}}" [disabled]="!IsStaff"
                                                    [(ngModel)]="element.HienTrangID">
                                                    <option [value]="0"></option>
                                                    <option *ngFor="let item of DanhMucATTPTinhTrangService.List;" [value]="item.ID">
                                                        {{item.Name}}</option>
                                                </select>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="LyDo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Lý do
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" [disabled]="!IsStaff || (element.HienTrangID!=5&&element.HienTrangID!=6&&element.HienTrangID!=8)" [(ngModel)]="element.LyDoHuy" type="text" title="Lý do">
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="TemDinhKemLydo">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tệp đính kèm lý do
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div style="display: flex;">
                                                    <a *ngIf="element.HienTrangID!=5&&element.HienTrangID!=6&&element.HienTrangID!=8 || !IsStaff"
                                                        class="btn btn-secondary me-1" title="Đẩy lên">
                                                        <i class="bi bi-cloud-upload"></i>
                                                    </a>
                                                    
                                                    <a *ngIf="!(element.HienTrangID!=5&&element.HienTrangID!=6&&element.HienTrangID!=8) && IsStaff" (click)="openFileInput4(element)"
                                                        class="btn btn-success me-1" title="Đẩy lên">
                                                        <i class="bi bi-cloud-upload"></i>
                                                    </a>
                                                    <input #fileInputs4 type="file" (change)="ChangeFileName4(element, $event.target.files)" style="display: none">
                                                    
                                                    <div *ngIf="element?.FileDinhKem === null || (element.HienTrangID!=5&&element.HienTrangID!=6&&element.HienTrangID!=8)">
                                                        <a class="btn btn-secondary me-1" target="_blank" title="Tải về">
                                                            <i class="bi bi-cloud-download"></i>
                                                        </a>
                                                        <a class="btn btn-secondary me-1" target="_blank" title="Xem">
                                                            <i class="bi bi-eye"></i>
                                                        </a>
                                                    </div>
                                                    
                                                    <div  *ngIf="element?.FileDinhKem !== null && !(element.HienTrangID!=5&&element.HienTrangID!=6&&element.HienTrangID!=8)">
                                                        <a class="btn btn-success me-1" 
                                                            title="Tải về"
                                                            target="_blank"
                                                            [href]="element.FileDinhKem">
                                                            <i class="bi bi-cloud-download"></i>
                                                        </a>
                                
                                                        <a *ngIf="isPDFFile(element.FileDinhKem) || isWordOrExcelFile(element.FileDinhKem) || isHtmlFile(element.FileDinhKem)"
                                                            class="btn btn-success me-1"
                                                            title="Xem"
                                                            target="_blank"
                                                            [href]="getViewerUrl(element.FileDinhKem)">
                                                            <i class="bi bi-eye"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="ThoiGian">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Thời gian thay đổi
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <b>{{element.LastUpdatedDate | date:'dd/MM/yyyy HH:mm:ss'}}</b>
                                                <!-- <input class="form-control" [disabled]="true" [(ngModel)]="element.LastUpdatedDate" type="text" title="Thời gian thay đổi"> -->
                                            </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="Save">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a class="btn btn-info"
                                                    (click)="MatHangXuatKhauSave(element)"><i
                                                        class="bi bi-sd-card"></i> </a>&nbsp;&nbsp;&nbsp;
                                                <a class="btn btn-danger"
                                                    (click)="CompanyInfoVungTrongDocumentsDelete(element)"
                                                    *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                </a>&nbsp;&nbsp;&nbsp;
                                                <a *ngIf="element.ID > 0 && element.FileName" class="btn btn-success"
                                                    title="Tải về" href="{{element.FileName}}" target="_blank"><i
                                                        class="bi bi-cloud-download"></i>
                                                </a>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoDonViDongGoiDocumentsService.DisplayColumns004">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoDonViDongGoiDocumentsService.DisplayColumns004;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #CompanyInfoVungTrongDocumentsPaginator002="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoVungTrongDocumentsSort002="matSort"
                                        [dataSource]="CompanyInfoDonViDongGoiDocumentsService.DataSource002"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <!-- <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Hồ sơ: </label>
                                                        <input class="form-control" type="text" placeholder="Hồ sơ"
                                                            name="Name{{ element.ID }}" [(ngModel)]="element.Name" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Diễn Giải Sai Lỗi: </label>
                                                        <input class="form-control" type="text"
                                                            placeholder="Diễn Giải Sai Lỗi" name="Note{{ element.ID }}"
                                                            [(ngModel)]="element.Note" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Tải lên: </label>
                                                        <br />
                                                        <input type="file" style="width: 200px;"
                                                            (change)="ChangeFileName(element, $event.target.files)">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a class="btn btn-info" style="width: 100%;"
                                                            (click)="CompanyInfoVungTrongDocumentsSave002(element)"><i
                                                                class="bi bi-sd-card"></i> </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a class="btn btn-danger" style="width: 100%;"
                                                            (click)="CompanyInfoVungTrongDocumentsDelete(element)"
                                                            *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0 && element.FileName"
                                                            style="width: 100%;" class="btn btn-success" title="Tải về"
                                                            href="{{element.FileName}}" target="_blank"><i
                                                                class="bi bi-cloud-download"></i>
                                                        </a>
                                                    </div>
                                                </div> -->
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoDonViDongGoiDocumentsService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoDonViDongGoiDocumentsService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #CompanyInfoVungTrongDocumentsPaginator002="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="card mb-4 card-danger">
                        <div class="card-header">
                            <h5 class="card-title text-white">Lịch sử hồ sơ cơ sở đóng gói
                            </h5>
                        </div>
                        
                        <div class="card-body">
                            <div class="table-outer">
                                <div class="table-responsive">
                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                        #HistorySort002="matSort"
                                        [dataSource]="HistoryService.List"
                                        class="table table-striped m-0">
                                        
                                        <ng-container matColumnDef="TinhTrangHS">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tình trạng hồ sơ
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Tình trạng hồ sơ" disabled
                                                    name="TinhTrangHS{{ element.ID }}" [(ngModel)]="element.TinhTrangHS" />
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="LyDoHS">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Lý do hồ sơ
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Lý do hồ sơ" disabled
                                                    name="LyDoHS{{ element.ID }}" [(ngModel)]="element.LyDoHS" />
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="TepHS">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tệp đính kèm hồ sơ
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                
                                                <div style="display: flex;">
                                                    <div *ngIf="element?.TepHS === null || element?.TepHS == ''">
                                                        <a class="btn btn-secondary me-1" target="_blank" title="Tải về">
                                                            <i class="bi bi-cloud-download"></i>
                                                        </a>
                                                        <a class="btn btn-secondary me-1" target="_blank" title="Xem">
                                                            <i class="bi bi-eye"></i>
                                                        </a>
                                                    </div>
                                                    
                                                    <div  *ngIf="element?.TepHS !== null && element?.TepHS != ''">
                                                        <a class="btn btn-success me-1" 
                                                            title="Tải về"
                                                            target="_blank"
                                                            [href]="element.TepHS">
                                                            <i class="bi bi-cloud-download"></i>
                                                        </a>
                                
                                                        <a *ngIf="isPDFFile(element.TepHS) || isWordOrExcelFile(element.TepHS) || isHtmlFile(element.TepHS)"
                                                            class="btn btn-success me-1"
                                                            title="Xem"
                                                            target="_blank"
                                                            [href]="getViewerUrl(element.TepHS)">
                                                            <i class="bi bi-eye"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <!-- <input class="form-control" type="text" placeholder="Tệp đính kèm hồ sơ"
                                                    name="TepHS{{ element.ID }}" [(ngModel)]="element.TepHS" /> -->
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="TinhTrangVT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tình trạng cơ sở đóng gói
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Tình trạng cơ sở đóng gói" disabled
                                                    name="TinhTrangVT{{ element.ID }}" [(ngModel)]="element.TinhTrangVT" />
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="LyDoVT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Lý do cơ sở đóng gói
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <input class="form-control" type="text" placeholder="Lý do cơ sở đóng gói" disabled
                                                    name="LyDoVT{{ element.ID }}" [(ngModel)]="element.LyDoVT" />
                                            </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="TepVT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tệp đính kèm cơ sở đóng gói
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <div style="display: flex;">
                                                    <div *ngIf="element?.TepVT === null || element?.TepVT == ''">
                                                        <a class="btn btn-secondary me-1" target="_blank" title="Tải về">
                                                            <i class="bi bi-cloud-download"></i>
                                                        </a>
                                                        <a class="btn btn-secondary me-1" target="_blank" title="Xem">
                                                            <i class="bi bi-eye"></i>
                                                        </a>
                                                    </div>
                                                    
                                                    <div  *ngIf="element?.TepVT !== null && element?.TepVT != ''">
                                                        <a class="btn btn-success me-1" 
                                                            title="Tải về tệp đính kèm cơ sở đóng gói"
                                                            target="_blank"
                                                            [href]="element.TepVT">
                                                            <i class="bi bi-cloud-download"></i>
                                                        </a>
                                
                                                        <a *ngIf="isPDFFile(element.TepVT) || isWordOrExcelFile(element.TepVT) || isHtmlFile(element.TepVT)"
                                                            class="btn btn-success me-1"
                                                            title="Xem tệp đính kèm cơ sở đóng gói"
                                                            target="_blank"
                                                            [href]="getViewerUrl(element.TepVT)">
                                                            <i class="bi bi-eye"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                    
                                                <!-- <input class="form-control" type="text" placeholder="Tệp đính kèm cơ sở đóng gói"
                                                name="TepVT{{ element.ID }}" [(ngModel)]="element.TepVT" /> -->
                                                <!-- <ng-container matColumnDef="TepDinhKem">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Tệp đính kèm
                                                    </th>
                                                    <td mat-cell *matCellDef="let element">
                                                        <div style="display: flex;">
                                                            <div *ngIf="element?.TepVT === null">
                                                                <a class="btn btn-secondary me-1" target="_blank" title="Tải về">
                                                                    <i class="bi bi-cloud-download"></i>
                                                                </a>
                                                                <a class="btn btn-secondary me-1" target="_blank" title="Xem">
                                                                    <i class="bi bi-eye"></i>
                                                                </a>
                                                            </div>
                                                            
                                                            <div  *ngIf="element?.TepVT !== null">
                                                                <a class="btn btn-success me-1" 
                                                                    title="Tải về tệp đính kèm cơ sở đóng gói"
                                                                    target="_blank"
                                                                    [href]="element.TepVT">
                                                                    <i class="bi bi-cloud-download"></i>
                                                                </a>
                                        
                                                                <a *ngIf="isPDFFile(element.TepVT) || isWordOrExcelFile(element.TepVT) || isHtmlFile(element.TepVT)"
                                                                    class="btn btn-success me-1"
                                                                    title="Xem tệp đính kèm cơ sở đóng gói"
                                                                    target="_blank"
                                                                    [href]="getViewerUrl(element.TepVT)">
                                                                    <i class="bi bi-eye"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </ng-container> -->


                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="ThoiGian">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Thời gian
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <b>{{element.LastUpdatedDate | date:'dd/MM/yyyy HH:mm:ss'}}</b>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoDonViDongGoiDocumentsService.DisplayColumns005">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoDonViDongGoiDocumentsService.DisplayColumns005;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                        #HistoryPaginator002="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                        [showFirstLastButtons]></mat-paginator>

                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                        #CompanyInfoVungTrongDocumentsSort002="matSort"
                                        [dataSource]="CompanyInfoDonViDongGoiDocumentsService.DataSource002"
                                        class="table table-striped m-0">
                                        <ng-container matColumnDef="STT">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Danh sách
                                            </th>
                                            <td mat-cell *matCellDef="let element; index as i;">
                                                <!-- <div class="row gx-4">
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Hồ sơ: </label>
                                                        <input class="form-control" type="text" placeholder="Hồ sơ"
                                                            name="Name{{ element.ID }}" [(ngModel)]="element.Name" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Diễn Giải Sai Lỗi: </label>
                                                        <input class="form-control" type="text"
                                                            placeholder="Diễn Giải Sai Lỗi" name="Note{{ element.ID }}"
                                                            [(ngModel)]="element.Note" />
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <label>Tải lên: </label>
                                                        <br />
                                                        <input type="file" style="width: 200px;"
                                                            (change)="ChangeFileName(element, $event.target.files)">
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a class="btn btn-info" style="width: 100%;"
                                                            (click)="CompanyInfoVungTrongDocumentsSave002(element)"><i
                                                                class="bi bi-sd-card"></i> </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a class="btn btn-danger" style="width: 100%;"
                                                            (click)="CompanyInfoVungTrongDocumentsDelete(element)"
                                                            *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                        <a *ngIf="element.ID > 0 && element.FileName"
                                                            style="width: 100%;" class="btn btn-success" title="Tải về"
                                                            href="{{element.FileName}}" target="_blank"><i
                                                                class="bi bi-cloud-download"></i>
                                                        </a>
                                                    </div>
                                                </div> -->
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row
                                            *matHeaderRowDef="CompanyInfoDonViDongGoiDocumentsService.DisplayColumnsMobile">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: CompanyInfoDonViDongGoiDocumentsService.DisplayColumnsMobile;">
                                        </tr>
                                    </table>
                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                        #CompanyInfoVungTrongDocumentsPaginator002="matPaginator"
                                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                        [showFirstLastButtons]></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loading *ngIf="CompanyInfoDonViDongGoiService.IsShowLoading"></app-loading>