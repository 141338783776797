import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { NamThang } from 'src/app/shared/NamThang.model';
import { DownloadService } from 'src/app/shared/Download.service';
import { UploadService } from 'src/app/shared/Upload.service';
import { PlanThamDinhCompanyDocument } from 'src/app/shared/PlanThamDinhCompanyDocument.model';
import { PlanThamDinhCompanyDocumentService } from 'src/app/shared/PlanThamDinhCompanyDocument.service';

@Component({
  selector: 'app-upload-nhuyen-the02-manh-vo',
  templateUrl: './upload-nhuyen-the02-manh-vo.component.html',
  styleUrls: ['./upload-nhuyen-the02-manh-vo.component.css']
})
export class UploadNhuyenThe02ManhVoComponent implements OnInit {

  isNhuyenThe02ManhVo: boolean = false;
  excelNhuyenThe02ManhVoURL: string = environment.APIUploadRootURL + environment.Download + "/NhuyenThe02ManhVo.xlsx";
  @ViewChild('uploadGiamSatDuLuong') uploadGiamSatDuLuong!: ElementRef;
  @ViewChild('uploadNhuyenThe02ManhVo') uploadNhuyenThe02ManhVo!: ElementRef;

  constructor(
    public NotificationService: NotificationService,  
    
    public DownloadService: DownloadService,
    public PlanThamDinhCompanyDocumentService: PlanThamDinhCompanyDocumentService,

    public UploadService: UploadService,
  ) { }
  year :string
  ngOnInit(): void {    
    this.ComponentGetListNam();
    this.year = "";
  }
  onYearChange(event: Event): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.GetPlanThamDinhCompanyDocument(selectedValue);
  }
  
  isPDFFile(fileName: string): boolean {
    return /\.pdf$/i.test(fileName);
  }
  isWordOrExcelFile(fileName: string): boolean {
    return /\.(doc|docx|xlsx)$/i.test(fileName);
  }

  isHtmlFile(fileName: string): boolean {
    return /\.(html)$/i.test(fileName);
  }
  
  getViewerUrl(fileName: string): string {
    if (this.isPDFFile(fileName)) {
      return fileName;
    } else if (this.isWordOrExcelFile(fileName)) {
      return `https://docs.google.com/viewer?url=${encodeURIComponent(fileName)}&embedded=true`;
    }
    return fileName; // Mặc định
  }
  GetPlanThamDinhCompanyDocument(selectedValue:string){
    this.year = selectedValue
    this.UploadService.IsShowLoading = true;
    this.PlanThamDinhCompanyDocumentService.GetByYear(selectedValue,88888).subscribe(
      res => {
        this.GiamSatFile = (res as PlanThamDinhCompanyDocument);
        this.UploadService.IsShowLoading = false;
      },
      err => {
        this.UploadService.IsShowLoading = false;
      }
    );
  }
  
  GiamSatFile: PlanThamDinhCompanyDocument
  UploadFileGiamSat(){
    this.uploadGiamSatDuLuong.nativeElement.click();
  }
  ComponentGetListNam() {
    this.DownloadService.ComponentGetListNam();
  }

  ChangeNhuyenThe02ManhVo(files: FileList) {
    if (files) {
      this.isNhuyenThe02ManhVo = true;
    }
  }
  SubmitNhuyenThe02ManhVo() {
    this.UploadService.IsShowLoading = true;
    this.UploadService.File = this.uploadGiamSatDuLuong.nativeElement.files[0];
    this.UploadService.PostNhuyenThe02ManhVoByExcelFileAsync().subscribe(
      res => {
        this.UploadService.IsShowLoading = false;
        this.GetPlanThamDinhCompanyDocument(this.year)
        this.NotificationService.warn(environment.UploadSuccess);
      },
      err => {
        this.UploadService.IsShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }
}